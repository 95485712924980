const Baccarat = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="24" viewBox="0 0 31 24" fill="none">
        <path
            d="M1.10027 7.19995C2.15273 11.0674 3.51721 14.8431 5.18027 18.4899C5.52184 19.2055 6.10206 19.7796 6.82114 20.1136C7.54022 20.4475 8.3532 20.5205 9.12027 20.3199C11.0505 19.815 12.934 19.1459 14.7503 18.3199C14.7503 17.8899 14.6803 17.3999 14.6503 16.8799C12.7664 17.7573 10.8088 18.4668 8.80027 18.9999C8.32281 19.124 7.81716 19.0793 7.36889 18.8734C6.92061 18.6674 6.55724 18.313 6.34027 17.8699C4.71553 14.3159 3.37793 10.6375 2.34027 6.86995C2.22649 6.37791 2.27854 5.86207 2.48834 5.40269C2.69813 4.94331 3.0539 4.56616 3.50027 4.32995C5.84034 3.0898 8.31257 2.11701 10.8703 1.42995C11.3489 1.30667 11.8554 1.35286 12.3038 1.56066C12.7523 1.76847 13.1149 2.12509 13.3303 2.56995C13.7803 3.46995 14.3303 4.80995 14.9003 6.28995C15.0506 5.72685 15.3354 5.20867 15.7303 4.77995C15.2803 3.65995 14.8503 2.66995 14.4903 1.93995C14.1457 1.22701 13.5651 0.655241 12.8469 0.32168C12.1288 -0.0118809 11.3173 -0.08669 10.5503 0.109947C7.89499 0.817337 5.32857 1.82378 2.90027 3.10995C2.19187 3.49848 1.63149 4.10992 1.30603 4.84942C0.980582 5.58892 0.908253 6.41515 1.10027 7.19995Z"
            fill="#301600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.7603 8.53995C31.0803 12.5536 31.0803 16.5863 30.7603 20.5999C30.6792 21.3954 30.332 22.1403 29.7748 22.7138C29.2176 23.2873 28.4831 23.656 27.6903 23.7599C24.9763 24.0799 22.2342 24.0799 19.5203 23.7599C18.7268 23.6577 17.9913 23.2896 17.4338 22.7157C16.8763 22.1419 16.5296 21.3961 16.4503 20.5999C16.1303 16.5863 16.1303 12.5536 16.4503 8.53995C16.5296 7.74381 16.8763 6.99802 17.4338 6.42416C17.9913 5.8503 18.7268 5.48223 19.5203 5.37995C22.2342 5.05999 24.9763 5.05999 27.6903 5.37995C28.4831 5.48395 29.2176 5.85256 29.7748 6.42609C30.332 6.99961 30.6792 7.74445 30.7603 8.53995ZM17.7603 8.69995C17.4503 12.6071 17.4503 16.5328 17.7603 20.4399C17.8036 20.9409 18.0165 21.412 18.3638 21.7756C18.711 22.1392 19.1719 22.3736 19.6703 22.4399C22.2884 22.7399 24.9322 22.7399 27.5503 22.4399C28.0491 22.3722 28.5102 22.1377 28.8588 21.7746C29.2074 21.4115 29.4229 20.9411 29.4703 20.4399C29.7803 16.5328 29.7803 12.6071 29.4703 8.69995C29.4229 8.19881 29.2074 7.72843 28.8588 7.36531C28.5102 7.00219 28.0491 6.76769 27.5503 6.69995C24.9322 6.39995 22.2884 6.39995 19.6703 6.69995C19.1701 6.76422 18.7069 6.99763 18.3576 7.36143C18.0084 7.72523 17.7941 8.19754 17.7503 8.69995H17.7603Z"
            fill="#301600"
        />
        <path
            d="M9.51027 12.7599C9.88125 12.5742 10.1776 12.2672 10.3503 11.8899C10.405 12.492 10.405 13.0979 10.3503 13.6999L12.1603 12.8599C11.6609 12.5145 11.1925 12.1264 10.7603 11.6999C11.1707 11.8227 11.6119 11.7907 12.0003 11.6099C12.4517 11.3688 12.7935 10.9638 12.9553 10.4783C13.1172 9.99278 13.0867 9.4637 12.8703 8.99995C12.5451 8.35644 12.0084 7.84444 11.3503 7.54995C10.7324 7.28683 10.084 7.10206 9.42027 6.99995L8.35027 6.73995C8.29578 6.72714 8.23857 6.733 8.18781 6.75657C8.13704 6.78014 8.09565 6.82006 8.07027 6.86995L7.55027 7.86995C7.22078 8.44668 6.96224 9.06114 6.78027 9.69995C6.5743 10.3908 6.624 11.1327 6.92027 11.7899C7.02498 12.0251 7.17545 12.237 7.36289 12.4135C7.55034 12.5899 7.77102 12.7272 8.01207 12.8175C8.25313 12.9078 8.50974 12.9492 8.76696 12.9393C9.02417 12.9294 9.27685 12.8685 9.51027 12.7599Z"
            fill="#301600"
        />
        <path
            d="M23.6003 10.7399C23.6003 10.7399 22.2603 13.5699 20.3203 14.5699C22.2603 15.5699 23.6003 18.3999 23.6003 18.3999C23.6003 18.3999 24.9503 15.5799 26.8903 14.5699C25.0003 13.5699 23.6003 10.7399 23.6003 10.7399Z"
            fill="#301600"
        />
    </svg>
);

export default Baccarat;
