import { userWorkers, bankWorkers } from '../../services/http';
import {
    setGameOverlayActive,
    setGameOverlayMinimized,
    setGeneralErrorModal,
    setLoadingPercent,
    setOpenRealityCheckModal,
    setTokenTime
} from './global';
import { getBetRace, getFirstDepositRemainingTime, getWalletInformation, initBankInformation } from './bank';
import {
    getLoadingPercent,
    getCookie,
    consoleError,
    handleLoginError,
    dataLayerPush,
    getSeonBase64Session
} from '@tlf-e/brand-utils';
import { setDomainCookie, clearDomainCookie } from '../../utils/cookieConfig';
import { getContentForSlider, submittingSlider } from './slider';
import {
    COOKIE_NAME_AUTH_TYPE,
    COOKIE_NAME_SESSION,
    COOKIE_NAME_SUSPENDED_CHECKING,
    COOKIE_NAME_TOKEN,
    COOKIE_SHOW_PROMOTION_LOGIN_MODAL,
    CURRENT_DEVICE_MOBILE,
    HAS_USER_LOGGED_IN,
    LOCAL_STORAGE_REALITY_CHECK_DATE,
    LOCAL_STORAGE_REALITY_CHECK_SESSION_LENGTH,
    MENU_GENERAL,
    MENU_LOGIN,
    MODAL_DEPOSIT,
    MODAL_WITHDRAW,
    PUSH_NOTIFICATION_STATUS_READ,
    PUSH_NOTIFICATION_STATUS_UNREAD,
    PUSH_NOTIFICATION_TYPE_ANNOUNCEMENT
} from '../../variables';
import router from 'next/router';
import routes from '../../utils/routes.json';
import { getBonusData } from './bonus';
import { handleCloseMenu } from '../../utils/locationConfig';
import { dateIsBetween } from '../../utils/commonConfig';
import dayjs from 'dayjs';
import {
    changeGamesListReference,
    cleanGameUrl,
    getFavoriteList,
    setGameProps,
    setGameSlider,
    setGameUrl,
    updateGames
} from './games';
import { getPromotionContent } from './promotions';
import { isMobile } from 'react-device-detect';
import { getWof, initWheelState } from './wheel-of-fortune';
import { formatCardSuffix } from '@tlf-e/brand-utils';
import { getRouterLocale } from '../../utils/locationConfig';

const {
    csrfRequest,
    loginRequest,
    forgotPasswordRequest,
    userInformationRequest,
    logoutUserRequest,
    getTermsRequest,
    acceptNewTermsRequest,
    fetchUserDocuments,
    uploadDocumentById,
    renewTokenRequest,
    getRealityCheckRequest,
    newPasswordRequest,
    deleteBankCard,
    updateBankCard,
    markPushNotificationAsReadRequest,
    getPushNotificationsRequest,
    selfExclusionRequest,
    generateQRCodeImage,
    enableMFARequest,
    loginMFARequest,
    disableMFARequest,
    updatePlayerInformation
} = userWorkers;
const { userCardsRequest } = bankWorkers;

const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';
const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';
const SET_FORGOT_PASSWORD_FORM = 'SET_FORGOT_PASSWORD_FORM';
const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
const INIT_SUBMIT_LOGIN_USER = 'INIT_SUBMIT_LOGIN_USER';
const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
const FINISH_SESSION = 'FINISH_SESSION';
const GET_TERMS = 'GET_TERMS';
const ACCEPT_NEW_TERMS = 'ACCEPT_NEW_TERMS';
const ACCEPT_NEW_TERMS_SUCCESS = 'ACCEPT_NEW_TERMS_SUCCESS';
const ACCEPT_NEW_TERMS_FAILED = 'ACCEPT_NEW_TERMS_FAILED';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
const FETCH_USER_DOCS = 'FETCH_USER_DOCS';
const FETCH_USER_DOCS_SUCCESS = 'FETCH_USER_DOCS_SUCCESS';
const FETCH_USER_DOCS_FAIL = 'FETCH_USER_DOCS_FAIL';
const UPLOAD_DOC = 'UPLOAD_DOC';
const UPLOAD_DOC_SUCCESS = 'UPLOAD_DOC_SUCCESS';
const UPLOAD_DOC_FAIL = 'UPLOAD_DOC_FAIL';
const UPLOAD_DOC_RESET = 'UPLOAD_DOC_RESET';
const SET_SOCKET_DISCONNECT = 'SET_SOCKET_DISCONNECT';
const BALANCE_UPDATED = 'BALANCE_UPDATED';
const GET_REALITY_CHECK = 'GET_REALITY_CHECK';
const GET_REALITY_CHECK_SUCCESS = 'GET_REALITY_CHECK_SUCCESS';
const GET_REALITY_CHECK_FAILED = 'GET_REALITY_CHECK_FAILED';
const GET_PROFILE_BANK_CARDS = 'GET_PROFILE_BANK_CARDS';
const GET_PROFILE_BANK_CARDS_SUCCESS = 'GET_PROFILE_BANK_CARDS_SUCCESS';
const GET_PROFILE_BANK_CARDS_FAILED = 'GET_PROFILE_BANK_CARDS_FAILED';
const INIT_SUBMIT_NEW_PASSOWORD = 'INIT_SUBMIT_NEW_PASSOWORD';
const NEW_PASSWORD_LOADING = 'NEW_PASSWORD_LOADING';
const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_FAILED = 'NEW_PASSWORD_FAILED';
const REMOVE_BANK_CARD_LOADING = 'REMOVE_BANK_CARD_LOADING';
const REMOVE_BANK_CARD_SUCCESS = 'REMOVE_BANK_CARD_SUCCESS';
const REMOVE_BANK_CARD_FAIL = 'REMOVE_BANK_CARD_FAIL';
const UPDATE_BANK_CARD_LOADING = 'UPDATE_BANK_CARD_LOADING';
const UPDATE_BANK_CARD_SUCCESS = 'UPDATE_BANK_CARD_SUCCESS';
const UPDATE_BANK_CARD_FAIL = 'UPDATE_BANK_CARD_FAIL';
const INIT_UPDATE_BANK_CARD = 'INIT_UPDATE_BANK_CARD';
const GET_PUSH_NOTIFICATIONS = 'GET_PUSH_NOTIFICATIONS';
const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';
const GET_PUSH_NOTIFICATIONS_FAILED = 'GET_PUSH_NOTIFICATIONS_FAILED';
const SET_IS_PROMOTION_LOGIN_MODAL_OPENED = 'SET_IS_PROMOTION_LOGIN_MODAL_OPENED';
const SET_SELF_EXCLUSION_LOADING = 'SET_SELF_EXCLUSION_LOADING';
const SET_SELF_EXCLUSION_SUCCESS = 'SET_SELF_EXCLUSION_SUCCESS';
const SET_SELF_EXCLUSION_FAILED = 'SET_SELF_EXCLUSION_FAILED';
const SET_SHOW_ACTIVE_SELF_EXCLUDED_MODAL = 'SET_SHOW_ACTIVE_SELF_EXCLUDED_MODAL';
const SET_MFA_ENABLED = 'SET_MFA_ENABLED';
const SET_MFA_MODAL_OPENED = 'SET_MFA_MODAL_OPENED';
const SET_MFA_ACTIVE_STEP = 'SET_MFA_ACTIVE_STEP';
const GET_QR_CODE_IMAGE_INIT = 'GET_QR_CODE_IMAGE_INIT';
const GET_QR_CODE_IMAGE_LOADING = 'GET_QR_CODE_IMAGE_LOADING';
const GET_QR_CODE_IMAGE_SUCCESS = 'GET_QR_CODE_IMAGE_SUCCESS';
const GET_QR_CODE_IMAGE_FAILED = 'GET_QR_CODE_IMAGE_FAILED';
const MFA_STATUS_INIT = 'MFA_STATUS_INIT';
const MFA_STATUS_LOADING = 'ENABLE_MFA_LOADING';
const MFA_STATUS_SUCCESS = 'MFA_STATUS_SUCCESS';
const MFA_STATUS_FAILED = 'MFA_STATUS_FAILED';
const MFA_LOGIN = 'MFA_LOGIN';
const SET_MFA_TOKEN = 'SET_MFA_TOKEN';
const UPDATE_PLAYER_INFORMATION_SUCCESS = 'UPDATE_PLAYER_INFORMATION_SUCCESS';
const SET_IS_NICKNAME_MODAL_OPENED = 'SET_IS_NICKNAME_MODAL_OPENED';

const predefinedCommunicationChannels = ['emailChannel', 'smsChannel', 'callChannel', 'postChannel', 'notificationChannel'];

export const initialState = {
    isAuth: false,
    information: {},
    submitLogin: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        apiError: ''
    },
    submitResetPassword: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    submitNewPassword: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        errors: []
    },
    terms: {
        accepted: true,
        content: '',
        modalContent: '',
        version: null,
        isLoading: false
    },
    submitChangePassword: {
        isLoading: false,
        isSuccess: false,
        isFail: false,
        errors: [],
        success: ''
    },
    submitForgotPassword: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    disconnectSocket: () => null,
    documentsLoading: false,
    documents: [],
    documentsError: null,
    balanceUpdated: null,
    realityCheck: {
        realBetAmount: 0,
        realWinAmount: 0,
        realLosses: 0
    },
    realityCheckLoading: false,
    forgotPasswordForm: {
        email: ''
    },
    uploadStatus: {
        id: null,
        isUploading: false,
        isSuccess: false,
        isError: false,
        error: ''
    },
    profileBankCards: [],
    profileBankCardsLoading: false,
    removeBankCard: {
        isLoading: false,
        isSuccess: false,
        isError: false
    },
    updateBankCard: {
        loadingId: null,
        isSuccess: false,
        isError: false
    },
    isVip: false,
    isPromotionLoginModalOpened: false,
    pushNotifications: [],
    pushNotificationsLoading: false,
    selfExclusionLoading: false,
    showActiveSelfExcludedModal: false,
    mfaToken: '',
    mfaEnabled: false,
    mfa: {
        isModalOpened: false,
        activeStep: 1
    },
    qrCode: {
        image: '',
        secretKey: '',
        isLoading: false,
        error: ''
    },
    mfaEnableStatuses: {
        isLoading: false,
        isSuccess: false,
        isError: {
            status: false,
            message: ''
        }
    },
    isNicknameModalOpened: false,
    predefinedCommunicationChannels: predefinedCommunicationChannels
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case FORGOT_PASSWORD:
        return {
            ...state,
            submitForgotPassword: {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        };
    case FORGOT_PASSWORD_SUCCESS:
        return {
            ...state,
            submitForgotPassword: {
                isLoading: false,
                isSuccess: true,
                isError: false
            }
        };
    case FORGOT_PASSWORD_FAILED:
        return {
            ...state,
            submitForgotPassword: {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        };
    case FORGOT_PASSWORD_RESET:
        return {
            ...state,
            submitForgotPassword: {
                isLoading: false,
                isSuccess: false,
                isError: false
            }
        };
    case SET_FORGOT_PASSWORD_FORM:
        return {
            ...state,
            forgotPasswordForm: action.payload
        };
    case LOGIN_USER:
        return {
            ...state,
            submitLogin: {
                isLoading: true,
                isSuccess: false,
                isError: false,
                apiError: ''
            }
        };
    case LOGIN_USER_SUCCESS:
        return {
            ...state,
            isAuth: true,
            submitLogin: {
                isLoading: false,
                isSuccess: true,
                isError: false,
                apiError: ''
            }
        };
    case LOGIN_USER_FAILED:
        return {
            ...state,
            isAuth: false,
            submitLogin: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                apiError: action.payload
            }
        };
    case INIT_SUBMIT_LOGIN_USER:
        return {
            ...state,
            submitLogin: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                apiError: ''
            }
        };
    case GET_USER_INFORMATION:
        return {
            ...state,
            information: action.payload.data,
            isAuth: true,
            isVip: parseInt(action.payload.data.hvcLevel) !== 0,
            mfaEnabled: action.payload.data.mfaEnabled
        };
    case FINISH_SESSION:
        clearDomainCookie(COOKIE_NAME_SESSION);
        clearDomainCookie(COOKIE_NAME_TOKEN);
        localStorage.removeItem(LOCAL_STORAGE_REALITY_CHECK_DATE);
        localStorage.removeItem(LOCAL_STORAGE_REALITY_CHECK_SESSION_LENGTH);
        return {
            ...state,
            information: {},
            isAuth: false,
            isVip: false
        };
    case GET_TERMS:
        return {
            ...state,
            terms: {
                ...state.terms,
                accepted: action.payload.accepted,
                content: action.payload.content,
                modalContent: action.payload.modalContent,
                version: action.payload.version
            }
        };
    case ACCEPT_NEW_TERMS:
        return {
            ...state,
            terms: {
                ...state.terms,
                isLoading: true
            }
        };
    case ACCEPT_NEW_TERMS_SUCCESS:
        return {
            ...state,
            terms: {
                ...state.terms,
                accepted: true,
                isLoading: false
            }
        };
    case ACCEPT_NEW_TERMS_FAILED:
        return {
            ...state,
            terms: {
                ...state.terms,
                isLoading: false
            }
        };
    case CHANGE_PASSWORD:
        return {
            ...state,
            submitChangePassword: {
                isLoading: true,
                isSuccess: false,
                isFail: false,
                errors: [],
                success: ''
            }
        };
    case CHANGE_PASSWORD_SUCCESS:
        return {
            ...state,
            submitChangePassword: {
                isLoading: false,
                isSuccess: true,
                isFail: false,
                errors: [],
                success: action.payload.success
            }
        };
    case CHANGE_PASSWORD_FAIL:
        return {
            ...state,
            submitChangePassword: {
                isLoading: false,
                isSuccess: false,
                isFail: true,
                errors: action.payload.errors,
                success: ''
            }
        };
    case CHANGE_PASSWORD_RESET:
        return {
            ...state,
            submitChangePassword: {
                isLoading: false,
                isSuccess: false,
                isFail: false,
                errors: [],
                success: ''
            }
        };
    case FETCH_USER_DOCS:
        return {
            ...state,
            documentsLoading: true
        };
    case FETCH_USER_DOCS_SUCCESS:
        return {
            ...state,
            documents: action.payload.data.document,
            documentsLoading: false
        };
    case FETCH_USER_DOCS_FAIL:
        return {
            ...state,
            documentsError: action.payload,
            documentsLoading: false
        };
    case UPLOAD_DOC:
        return {
            ...state,
            uploadStatus: {
                id: action.payload,
                isUploading: true,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case UPLOAD_DOC_SUCCESS:
        return {
            ...state,
            uploadStatus: {
                id: action.id,
                isUploading: false,
                isSuccess: true,
                isError: false,
                error: ''
            }
        };
    case UPLOAD_DOC_FAIL:
        return {
            ...state,
            uploadStatus: {
                id: action.id,
                isUploading: false,
                isSuccess: false,
                isError: true,
                error: action.error
            }
        };
    case UPLOAD_DOC_RESET:
        return {
            ...state,
            uploadStatus: {
                ...state.uploadStatus,
                id: null,
                isUploading: false,
                isSuccess: false,
                isError: false
            }
        };
    case SET_SOCKET_DISCONNECT:
        return {
            ...state,
            disconnectSocket: action.payload
        };
    case BALANCE_UPDATED:
        return {
            ...state,
            balanceUpdated: action.payload
        };
    case GET_REALITY_CHECK:
        return {
            ...state,
            realityCheckLoading: true
        };
    case GET_REALITY_CHECK_SUCCESS:
        return {
            ...state,
            realityCheck: {
                realBetAmount: action.payload.data.realBetAmount,
                realLosses: action.payload.data.realLosses,
                realWinAmount: action.payload.data.realWinAmount
            },
            realityCheckLoading: false
        };
    case GET_REALITY_CHECK_FAILED:
        return {
            ...state,
            realityCheckLoading: false
        };
    case GET_PROFILE_BANK_CARDS:
        return {
            ...state,
            profileBankCardsLoading: true
        };
    case GET_PROFILE_BANK_CARDS_SUCCESS:
        return {
            ...state,
            profileBankCards: action.payload,
            profileBankCardsLoading: false
        };
    case GET_PROFILE_BANK_CARDS_FAILED:
        return {
            ...state,
            profileBankCardsLoading: false
        };
    case INIT_SUBMIT_NEW_PASSOWORD:
        return {
            ...state,
            submitNewPassword: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                errors: []
            }
        };
    case NEW_PASSWORD_LOADING:
        return {
            ...state,
            submitNewPassword: {
                isLoading: true,
                isSuccess: false,
                isError: false,
                errors: []
            }
        };
    case REMOVE_BANK_CARD_LOADING:
        return {
            ...state,
            removeBankCard: {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        };
    case NEW_PASSWORD_SUCCESS:
        return {
            ...state,
            submitNewPassword: {
                isLoading: false,
                isSuccess: true,
                isError: false,
                errors: []
            }
        };
    case REMOVE_BANK_CARD_SUCCESS:
        return {
            ...state,
            removeBankCard: {
                isLoading: false,
                isSuccess: true,
                isError: false
            }
        };
    case NEW_PASSWORD_FAILED:
        return {
            ...state,
            submitNewPassword: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                errors: action.payload.data.errors
            }
        };
    case REMOVE_BANK_CARD_FAIL:
        return {
            ...state,
            removeBankCard: {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        };
    case UPDATE_BANK_CARD_LOADING:
        return {
            ...state,
            updateBankCard: {
                loadingId: action.payload,
                isSuccess: false,
                isError: false
            }
        };
    case UPDATE_BANK_CARD_SUCCESS:
        return {
            ...state,
            updateBankCard: {
                loadingId: null,
                isSuccess: true,
                isError: false
            }
        };
    case UPDATE_BANK_CARD_FAIL:
        return {
            ...state,
            updateBankCard: {
                loadingId: null,
                isSuccess: false,
                isError: true
            }
        };
    case INIT_UPDATE_BANK_CARD:
        return {
            ...state,
            updateBankCard: {
                loadingId: null,
                isSuccess: false,
                isError: false
            }
        };
    case SET_IS_PROMOTION_LOGIN_MODAL_OPENED:
        return {
            ...state,
            isPromotionLoginModalOpened: action.payload
        };
    case GET_PUSH_NOTIFICATIONS:
        return {
            ...state,
            pushNotificationsLoading: true
        };
    case GET_PUSH_NOTIFICATIONS_SUCCESS:
        return {
            ...state,
            pushNotifications: action.payload,
            pushNotificationsLoading: false
        };
    case GET_PUSH_NOTIFICATIONS_FAILED:
        return {
            ...state,
            pushNotificationsLoading: false
        };
    case SET_SELF_EXCLUSION_LOADING:
        return {
            ...state,
            selfExclusionLoading: true
        };
    case SET_SELF_EXCLUSION_SUCCESS:
        return {
            ...state,
            selfExclusionLoading: false
        };
    case SET_SELF_EXCLUSION_FAILED:
        return {
            ...state,
            selfExclusionLoading: false
        };
    case SET_SHOW_ACTIVE_SELF_EXCLUDED_MODAL:
        return {
            ...state,
            showActiveSelfExcludedModal: action.payload
        };
    case SET_MFA_MODAL_OPENED:
        return {
            ...state,
            mfa: {
                ...state.mfa,
                isModalOpened: action.payload
            }
        };
    case SET_MFA_ACTIVE_STEP:
        return {
            ...state,
            mfa: {
                ...state.mfa,
                activeStep: action.payload
            }
        };
    case GET_QR_CODE_IMAGE_INIT:
        return {
            ...state,
            qrCode: {
                image: '',
                secretKey: '',
                isLoading: false,
                error: ''
            }
        };
    case GET_QR_CODE_IMAGE_LOADING:
        return {
            ...state,
            qrCode: {
                ...state.qrCode,
                isLoading: true,
                error: ''
            }
        };
    case GET_QR_CODE_IMAGE_SUCCESS:
        return {
            ...state,
            qrCode: {
                ...state.qrCode,
                image: action.payload.data.qrCode,
                secretKey: action.payload.data.setupKey,
                isLoading: false,
                error: ''
            }
        };
    case GET_QR_CODE_IMAGE_FAILED:
        return {
            ...state,
            qrCode: {
                ...state.qrCode,
                isLoading: false,
                error: action.payload
            }
        };
    case SET_MFA_ENABLED:
        return {
            ...state,
            mfaEnabled: action.payload
        };
    case MFA_STATUS_INIT:
        return {
            ...state,
            mfaEnableStatuses: {
                isLoading: false,
                isSuccess: false,
                isError: {
                    status: false,
                    message: ''
                }
            }
        };
    case MFA_STATUS_LOADING:
        return {
            ...state,
            mfaEnableStatuses: {
                isLoading: true,
                isSuccess: false,
                isError: {
                    status: false,
                    message: ''
                }
            }
        };
    case MFA_STATUS_SUCCESS:
        return {
            ...state,
            mfaEnabled: action.payload,
            mfaEnableStatuses: {
                isLoading: false,
                isSuccess: true,
                isError: {
                    status: false,
                    message: ''
                }
            }
        };
    case MFA_STATUS_FAILED:
        return {
            ...state,
            mfaEnableStatuses: {
                isLoading: false,
                isSuccess: false,
                isError: {
                    status: true,
                    message: action.payload
                }
            },
            submitLogin: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                apiError: action.payload
            }
        };
    case MFA_LOGIN:
        return {
            ...state,
            mfaEnabled: action.payload,
            isAuth: false,
            submitLogin: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                apiError: ''
            }
        };
    case SET_MFA_TOKEN:
        return {
            ...state,
            mfaToken: action.payload
        };
    case UPDATE_PLAYER_INFORMATION_SUCCESS:
        return {
            ...state,
            information: action.payload
        };
    case SET_IS_NICKNAME_MODAL_OPENED:
        return {
            ...state,
            isNicknameModalOpened: action.payload
        };
    default:
        return state;
    }
};

export const setShowActiveSelfExcludedModal = (payload) => ({
    type: SET_SHOW_ACTIVE_SELF_EXCLUDED_MODAL,
    payload
});

export const renewToken = () => {
    return (dispatch, getState) => {
        // this is used to generate jwt token on this case we should send email for email and password field
        const userEmail = getState().user.information.email;
        const csrfData = {
            email: userEmail,
            password: userEmail
        };
        csrfRequest(csrfData)
            .then((token) =>
                renewTokenRequest(
                    { csrf: token.data.data.token, ...csrfData },
                    {
                        renew_token: 1
                    }
                )
                    .then((res) => {
                        const token = res.data.authorization.token;
                        setDomainCookie(COOKIE_NAME_TOKEN, token);
                    })
                    .catch((err) => consoleError(err))
            )
            .catch((err) => consoleError(err));
    };
};

// Whenever the value that is passed is set to null is to clear the game overlay minimized.
// This is used in the case when the user logs in from the game page and the game overlay is minimized.
// When the minimized is false that means that the overlay is active and NOT minimized.
export const clearGameOverlays = (value) => {
    return (dispatch) => {
        dispatch(setGameOverlayActive(false));
        dispatch(setGameOverlayMinimized(value));
        dispatch(setGameUrl(''));
        !isMobile && dispatch(cleanGameUrl());
        dispatch(setGameProps({}));
    };
};

const handleLoginSuccess = (isGamePage, device, successCallback, token, success) => {
    return async (dispatch, getState) => {
        const tr = getState().global.data.translations;
        if (!getState().games.gameUrlAfterLogin) {
            if ([routes.register].includes(`${router.pathname}/`)) {
                router.push(
                    {
                        pathname: routes.home,
                        query: {
                            ...router.query,
                            menu: MENU_GENERAL
                        }
                    },
                    routes.home
                );
            } else {
                const [asPathRoot, asPathQuery = ''] = router.asPath.split('?');
                const params = new URLSearchParams(asPathQuery);
                params.delete('menu');
                let updatedParams = '';
                if ([...params].length) {
                    updatedParams = `?${params.toString()}`;
                }
                // By using this condition we made sure that when user logs in from game page on mobile device he will be redirected to the same game page
                // and not on the home page
                const query =
                    isGamePage && device === CURRENT_DEVICE_MOBILE ? router.query : { ...router.query, menu: MENU_GENERAL };
                router.push(
                    {
                        pathname: router.pathname,
                        query
                    },
                    `${asPathRoot}${updatedParams}`
                );
            }
            // Prevent calling successCallback if there is no gameUrlAfterLogin
            successCallback = null;
        }
        const { capitalizedLocale } = getRouterLocale(router.locale);
        sessionStorage.setItem(HAS_USER_LOGGED_IN, true);
        await setDomainCookie(COOKIE_NAME_TOKEN, token);
        dispatch(success());
        dispatch(getUserInformation(token, capitalizedLocale, 'login', successCallback));
        if (
            !getCookie(COOKIE_SHOW_PROMOTION_LOGIN_MODAL) &&
            dateIsBetween({
                dateFrom: tr['promotion_login_modal.date_from'],
                dateTo: tr['promotion_login_modal.date_to']
            })
        ) {
            dispatch(setIsPromotionLoginModalOpened(true));
        }
        isGamePage && dispatch(setGameSlider(true));
    };
};

export const loginUser = (data, device, successCallback, isGamePage) => {
    const loading = () => ({
        type: LOGIN_USER
    });
    const fail = (payload) => ({
        type: LOGIN_USER_FAILED,
        payload
    });
    const success = () => ({
        type: LOGIN_USER_SUCCESS
    });

    const reqLoginFunc = (session = {}) => {
        return (dispatch, getState) => {
            const tr = getState().global.data.translations;
            csrfRequest({ ...data })
                .then((token) => {
                    loginRequest({ ...data, ...session, csrf: token.data.data.token })
                        .then(async (res) => {
                            const mfaEnabled = res.data.mfaEnabled;
                            const token = res.data.authorization.token;
                            dispatch(minimizeAndClearGameOverlay());
                            if (!mfaEnabled) {
                                dispatch(handleLoginSuccess(isGamePage, device, successCallback, token, success));
                            } else {
                                dispatch(setMfaToken(token));
                                dispatch(setMfaLogin(true));
                            }
                        })
                        .catch((err) => {
                            if (err.data?.errors?.length && err.data.errors[0].title === 'player_excluded') {
                                return router.push(routes.home).then(() => {
                                    dispatch(setShowActiveSelfExcludedModal(true));
                                    dispatch(initSubmitLoginUser());
                                });
                            }

                            const tr = getState().global.data.translations;
                            const email = data.username;
                            const apiError = handleLoginError(err, tr, email);
                            dispatch(setForgotPasswordForm({ email }));
                            dispatch(fail(apiError));
                        });
                })
                .catch(() => dispatch(fail(tr['login_form.error.default_error'])));
        };
    };

    return (dispatch) => {
        dispatch(loading());
        getSeonBase64Session(MENU_LOGIN)
            .then(({ seonBase64Session }) => {
                dispatch(reqLoginFunc({ seonSession: seonBase64Session }));
            })
            .catch(() => {
                dataLayerPush({ event: 'getSeonBase64Session_error', error: 'on_login' });
                dispatch(reqLoginFunc());
            });
    };
};

export const changePassword = (email) => {
    const loading = () => ({
        type: CHANGE_PASSWORD
    });
    const success = (payload) => ({
        type: CHANGE_PASSWORD_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: CHANGE_PASSWORD_FAIL,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        logoutUserRequest()
            .then(() => {
                dispatch(logoutUserSuccess());
                forgotPasswordRequest(email)
                    .then((res) => {
                        dispatch(success(res.data));
                    })
                    .catch((err) => dispatch(fail(err)));
            })
            .catch((err) => console.error(JSON.stringify(err.data)));
    };
};

export const forgotPassword = (data) => {
    const loading = () => ({
        type: FORGOT_PASSWORD
    });
    const success = (payload) => ({
        type: FORGOT_PASSWORD_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: FORGOT_PASSWORD_FAILED,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        forgotPasswordRequest(data)
            .then((res) => {
                dispatch(success(res.data));
                dataLayerPush({ event: 'ForgotPassword_request', player_email: data.email });
                handleCloseMenu();
            })
            .catch((err) => dispatch(dispatch(fail(err))));
    };
};

export const forgotPasswordReset = () => ({
    type: FORGOT_PASSWORD_RESET
});

export const initSubmitLoginUser = () => ({
    type: INIT_SUBMIT_LOGIN_USER
});

export const finishSession = () => ({
    type: FINISH_SESSION
});

export const getUserInformation = (payload, locale, location, successCallback) => {
    const preventBonusesCall =
        [routes.home, routes.bonuses.slice(0, -1)].includes(router.pathname) ||
        [MODAL_DEPOSIT, MODAL_WITHDRAW].includes(router.query.modal);
    const success = (payload) => ({
        type: GET_USER_INFORMATION,
        payload
    });
    if (payload) {
        return (dispatch, getState) => {
            const isFirstLogin = location === 'firstLogin';
            const isMinimized = getState().global.isGameOverlayMinimized;
            // Add cookie for a one minute after success registration to be able to handle 401 suspended
            isFirstLogin &&
                setDomainCookie(COOKIE_NAME_SUSPENDED_CHECKING, 'true', {
                    'max-age': 60
                });
            dispatch(submittingSlider());
            userInformationRequest()
                .then((res) => {
                    dispatch(success(res.data));
                    !isMinimized && dispatch(setGameOverlayMinimized(null));
                    dispatch(getContentForSlider(locale));
                    dispatch(setLoadingPercent(getLoadingPercent(!!res.config.headers['Authorization'], 'userInformation')));
                    dispatch(getTerms(locale, isFirstLogin));
                    dispatch(getBetRace());
                    dispatch(getPushNotifications(locale));
                    dispatch(getFavoriteList('', 'GET'));
                    //Call WOF everywhere except the wheel page
                    `${router.pathname}/` !== routes.wheel_of_fortune && dispatch(getWof());
                    !preventBonusesCall && dispatch(getBonusData());
                    const isLogin = location === 'login';
                    const inconsistentLocationWithRegistration =
                        getState().user.information.country !== getState().global.clientIp.loc;
                    isLogin && successCallback && successCallback(inconsistentLocationWithRegistration);

                    dispatch(
                        getWalletInformation({
                            getFirstDepositRemainingTime: (onFinally) => dispatch(getFirstDepositRemainingTime(onFinally)),
                            updateGames: (successfulDepositCount) =>
                                dispatch(updateGames(isLogin, inconsistentLocationWithRegistration, successfulDepositCount))
                        })
                    );

                    // Changing games list reference relaunching game if the same location
                    if (!inconsistentLocationWithRegistration) {
                        dispatch(changeGamesListReference());
                    }
                    const { id, email, hvcLevel, affiliateId, vipLevel } = res.data.data;
                    const hasUserLoggedIn = sessionStorage.getItem(HAS_USER_LOGGED_IN);
                    const hasVip = vipLevel ?? {
                        id: 0,
                        rank: 0,
                        name: 'Zero'
                    };
                    if (location) {
                        hasUserLoggedIn &&
                            dataLayerPush({
                                event: 'Login_Successful',
                                first_time: isFirstLogin,
                                user_email: email,
                                HVC_level: hvcLevel,
                                Affiliate_ID: affiliateId,
                                id: id,
                                vip_level: hasVip
                            });
                        // Call new list of promotions
                        dispatch(getPromotionContent(locale));
                    }
                })
                .catch(() => {
                    //finish init loading in case if the token is invalid or other errors on user info request
                    dispatch(setLoadingPercent(getLoadingPercent(payload, 'userInformation')));
                    dispatch(setLoadingPercent(getLoadingPercent(payload, 'walletInformation')));
                    dispatch(finishSessionExtended());
                })
                .finally(() => clearDomainCookie(COOKIE_NAME_AUTH_TYPE));
        };
    }
    return (dispatch) => dispatch(finishSessionExtended());
};

const minimizeAndClearGameOverlay = () => {
    return (dispatch, getState) => {
        if (getState().global.isGameOverlayMinimized) {
            dispatch(clearGameOverlays(null));
        }
    };
};

export const logoutUserSuccess = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(minimizeAndClearGameOverlay());
        router.push(routes.home);
        dispatch(
            updateGames(
                true,
                state.user.information.country !== state.global.clientIp.loc,
                state.bank.information.successfulDepositCount
            )
        );
        sessionStorage.removeItem(HAS_USER_LOGGED_IN);
        dispatch(finishSessionExtended());
        dispatch(setMFAModal(false));
        dispatch(mfaStatusInit());
        dispatch(setTokenTime(null));
        setOpenRealityCheckModal(false);
        dispatch(initQrCodeImage());
        dispatch(setMfaLogin(false));
    };
};

export const finishSessionExtended = () => {
    const { capitalizedLocale } = getRouterLocale(router.locale);

    return (dispatch) => {
        dispatch(finishSession());
        dispatch(initBankInformation());
        dispatch(initWheelState());
        dispatch(getContentForSlider(capitalizedLocale));
        dispatch(getPromotionContent(capitalizedLocale));
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        logoutUserRequest()
            .then(() => dispatch(logoutUserSuccess()))
            .catch((err) => console.error(JSON.stringify(err.data)));
    };
};

export const getTerms = (payload, isFirstLogin = false) => {
    const success = (payload) => ({
        type: GET_TERMS,
        payload
    });
    return (dispatch) => {
        getTermsRequest(payload)
            .then((res) => {
                const version = res.data.version;
                if (isFirstLogin && version) {
                    acceptNewTermsRequest({ version })
                        .then(() =>
                            dispatch(
                                success({
                                    ...res.data,
                                    accepted: true
                                })
                            )
                        )
                        .catch(() => dispatch(success(res.data)));
                } else {
                    dispatch(success(res.data));
                }
            })
            .catch((err) => console.error(JSON.stringify(err.data)));
    };
};

export const acceptNewTerms = () => {
    const loading = () => ({
        type: ACCEPT_NEW_TERMS
    });
    const success = () => ({
        type: ACCEPT_NEW_TERMS_SUCCESS
    });
    const fail = () => ({
        type: ACCEPT_NEW_TERMS_FAILED
    });
    return (dispatch, getState) => {
        dispatch(loading());
        acceptNewTermsRequest({ version: getState().user.terms.version })
            .then(() => dispatch(success()))
            .catch(() => dispatch(fail()));
    };
};

export const resetChangePassword = () => ({
    type: CHANGE_PASSWORD_RESET
});

export const getUserDocuments = () => {
    const loading = () => ({
        type: FETCH_USER_DOCS
    });
    const success = (payload) => ({
        type: FETCH_USER_DOCS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: FETCH_USER_DOCS_FAIL,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        fetchUserDocuments()
            .then((res) => {
                const token = res.data.authorization.token;
                setDomainCookie(COOKIE_NAME_TOKEN, token);
                dispatch(success(res.data));
            })
            .catch((err) => dispatch(fail(err)));
    };
};

export const uploadDocumentFailed = (id, error) => ({
    type: UPLOAD_DOC_FAIL,
    id,
    error
});

export const uploadDocument = (id, formData) => {
    const loading = (payload) => ({
        type: UPLOAD_DOC,
        payload
    });
    const success = (payload, id) => ({
        type: UPLOAD_DOC_SUCCESS,
        payload,
        id
    });

    const data = new FormData();
    data.append('file', formData);

    return (dispatch, getState) => {
        dispatch(loading(id));
        uploadDocumentById(id, data)
            .then((res) => {
                const token = res.data.authorization.token;
                setDomainCookie(COOKIE_NAME_TOKEN, token);
                dispatch(success(res.data, id));
                dispatch(getUserDocuments());
            })
            .catch(() => {
                const tr = getState().global.data.translations;
                dispatch(uploadDocumentFailed(id, tr['document_upload_error_generic']));
            });
    };
};

export const uploadFile = (file, id, dispatch, tr) => {
    const reader = new FileReader();
    const maximumFileSize = 10485760;
    const minImageWidth = 255;
    const minImageHeight = 175;
    const divider = 1000000;
    const getSizeText = (size) => `${(size / divider).toFixed(3)} MB`;
    const acceptedFormat = ['png', 'jpg', 'jpeg', 'pdf'];

    const isFormatAccepted = acceptedFormat.some((element) => {
        return !!file?.type?.includes(element);
    });

    const errors = {
        getSizeError: (fileSize) =>
            `${tr['document_upload_error_filesize_1']} (${getSizeText(fileSize)}).
                ${tr['document_upload_error_filesize_2']} ${getSizeText(maximumFileSize)}.`,
        getDimensionsError: () => `${tr['document_upload_error_img_size']}: 255x175px`,
        getFileTypeError: () => tr['document_upload_error_invalid_type']
    };

    //prevent loading files with invalid size
    const validateFileSize = () => {
        file.size > maximumFileSize
            ? dispatch(uploadDocumentFailed(id, errors.getSizeError(file.size)))
            : dispatch(uploadDocument(id, file));
    };

    //prevent loading invalid types and images with invalid dimensions
    reader.onload = (e) => {
        if (isFormatAccepted) {
            if (file.type.includes('image')) {
                let img = new Image();
                img.src = e.target.result;

                img.onload = function () {
                    const isInvalidSize = this.width < minImageWidth || this.height < minImageHeight;
                    isInvalidSize ? dispatch(uploadDocumentFailed(id, errors.getDimensionsError())) : validateFileSize();
                };
            } else validateFileSize();
        } else {
            dispatch(uploadDocumentFailed(id, errors.getFileTypeError()));
        }
    };
    if (file) {
        reader.readAsDataURL(file);
    }
};

export const setSocketDisconnect = (payload) => ({
    type: SET_SOCKET_DISCONNECT,
    payload
});

export const balanceUpdated = (payload) => ({
    type: BALANCE_UPDATED,
    payload
});

export const getRealityCheck = (data) => {
    const loading = (payload) => ({
        type: GET_REALITY_CHECK,
        payload
    });

    const success = (payload) => ({
        type: GET_REALITY_CHECK_SUCCESS,
        payload
    });

    const failed = (payload) => ({
        type: GET_REALITY_CHECK_FAILED,
        payload
    });

    return (dispatch) => {
        dispatch(loading());
        getRealityCheckRequest(data)
            .then((res) => {
                dispatch(success(res.data));
            })
            .catch(() => {
                dispatch(failed());
            });
    };
};

export const setForgotPasswordForm = (payload) => ({
    type: SET_FORGOT_PASSWORD_FORM,
    payload
});

export const getProfileBankCardsSuccess = (payload) => ({
    type: GET_PROFILE_BANK_CARDS_SUCCESS,
    payload
});

export const getProfileBankCards = () => {
    const loading = () => ({
        type: GET_PROFILE_BANK_CARDS
    });
    const fail = () => ({
        type: GET_PROFILE_BANK_CARDS_FAILED
    });
    return (dispatch) => {
        dispatch(loading());
        userCardsRequest()
            .then((res) => {
                const cards = res.data.data
                    .map((card) => ({
                        ...card,
                        cardLabel: formatCardSuffix(card.cardNumberSuffix)
                    }))
                    .sort((a, b) => (a.default === b.default ? 0 : a.default ? -1 : 1));
                dispatch(getProfileBankCardsSuccess(cards));
            })
            .catch(() => dispatch(fail()));
    };
};

export const resetUploadFile = () => ({
    type: UPLOAD_DOC_RESET
});

export const initSubmitNewPassword = () => ({
    type: INIT_SUBMIT_NEW_PASSOWORD
});

export const newPassword = (data) => {
    const loading = () => ({
        type: NEW_PASSWORD_LOADING
    });

    const success = () => ({
        type: NEW_PASSWORD_SUCCESS
    });

    const fail = (payload) => ({
        type: NEW_PASSWORD_FAILED,
        payload
    });

    return (dispatch) => {
        dispatch(loading());
        const dataLayerEvent = (status) => {
            dataLayerPush({ event: `ForgtoPassword_${status}`, token: data.token });
        };
        dataLayerEvent('NewPassSubmission');
        newPasswordRequest(data)
            .then(() => {
                dispatch(success());
                dataLayerEvent('Success');
            })
            .catch((err) => {
                if (err.data.errors?.length && err.data.errors[0].detail.error === 'player_excluded') {
                    return dispatch(success());
                }

                dataLayerEvent('Failed');
                dispatch(fail(err));
            })
            .finally(() => handleCloseMenu());
    };
};

export const removeSelectedBankCard = (id, onSuccess) => {
    const loading = () => ({
        type: REMOVE_BANK_CARD_LOADING
    });
    const success = () => ({
        type: REMOVE_BANK_CARD_SUCCESS
    });
    const fail = () => ({
        type: REMOVE_BANK_CARD_FAIL
    });
    return (dispatch) => {
        dispatch(loading());
        deleteBankCard(id)
            .then(() => {
                dispatch(success());
                onSuccess();
            })
            .catch(() => dispatch(fail()));
    };
};

export const setDefaultBankCard = (card, onSuccess) => {
    const loading = (payload) => ({
        type: UPDATE_BANK_CARD_LOADING,
        payload
    });
    const success = () => ({
        type: UPDATE_BANK_CARD_SUCCESS
    });
    const fail = () => ({
        type: UPDATE_BANK_CARD_FAIL
    });
    return (dispatch) => {
        dispatch(loading(card.id));
        updateBankCard(card.id, {
            default: !card.default
        })
            .then(() => {
                dispatch(success());
                onSuccess();
            })
            .catch(() => dispatch(fail()));
    };
};

export const setIsPromotionLoginModalOpened = (payload) => ({
    type: SET_IS_PROMOTION_LOGIN_MODAL_OPENED,
    payload
});

export const getPushNotifications = (lang) => {
    const loading = () => ({
        type: GET_PUSH_NOTIFICATIONS
    });
    const success = (payload) => ({
        type: GET_PUSH_NOTIFICATIONS_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_PUSH_NOTIFICATIONS_FAILED
    });

    return (dispatch) => {
        dispatch(loading());
        getPushNotificationsRequest({
            lang,
            status: [PUSH_NOTIFICATION_STATUS_READ, PUSH_NOTIFICATION_STATUS_UNREAD],
            orderField: 'createdAt',
            orderType: 'desc',
            version: 'v1'
        })
            .then((res) => {
                const pushNotifications = res.data.data.filter(
                    (item) => item.notificationTypeName === PUSH_NOTIFICATION_TYPE_ANNOUNCEMENT
                );
                dispatch(success(pushNotifications));
            })
            .catch(() => dispatch(fail()));
    };
};

export const markPushNotificationAsRead = (ids = [], lang) => {
    return (dispatch) => {
        const messagesRequests = ids.map((id) =>
            markPushNotificationAsReadRequest(id, {
                status: PUSH_NOTIFICATION_STATUS_READ,
                dateRead: dayjs().format('YYYY-MM-DD HH:mm:ss')
            })
        );
        const promiseAllMessages = Promise.all(messagesRequests);
        promiseAllMessages.then(() => dispatch(getPushNotifications(lang))).catch((err) => consoleError(err));
    };
};

export const setSelfExclusion = (date) => {
    const loading = () => ({
        type: SET_SELF_EXCLUSION_LOADING
    });

    const success = () => ({
        type: SET_SELF_EXCLUSION_SUCCESS
    });

    const fail = () => ({
        type: SET_SELF_EXCLUSION_FAILED
    });

    return (dispatch) => {
        dispatch(loading());
        selfExclusionRequest({ exclusionEndAt: date })
            .then(() => {
                dispatch(success());
                dispatch(logoutUserSuccess());
            })
            .catch(() => {
                dispatch(fail());
                router.push(routes.home).then(() => dispatch(setGeneralErrorModal(true)));
            });
    };
};

export const setMFAEnabled = (payload) => ({
    type: SET_MFA_ENABLED,
    payload
});

export const setMFAModal = (payload) => ({
    type: SET_MFA_MODAL_OPENED,
    payload
});

export const setMFAActiveStep = (payload) => ({
    type: SET_MFA_ACTIVE_STEP,
    payload
});

export const getQrCodeImage = () => {
    const loading = () => ({
        type: GET_QR_CODE_IMAGE_LOADING
    });

    const success = (payload) => ({
        type: GET_QR_CODE_IMAGE_SUCCESS,
        payload
    });

    const fail = () => ({
        type: GET_QR_CODE_IMAGE_FAILED
    });

    return (dispatch) => {
        dispatch(loading());
        generateQRCodeImage()
            .then((res) => dispatch(success(res.data)))
            .catch(() => dispatch(fail()));
    };
};

export const initQrCodeImage = () => ({
    type: GET_QR_CODE_IMAGE_INIT
});

export const enableMfa = (data) => {
    const loading = () => ({
        type: MFA_STATUS_LOADING
    });
    const success = (payload) => ({
        type: MFA_STATUS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: MFA_STATUS_FAILED,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        enableMFARequest({ totp: data })
            .then(() => {
                dispatch(success(true));
            })
            .catch((err) => {
                const error = err.data?.errors?.length > 0 ? err.data.errors[0].detail : '';
                dispatch(fail(error));
            });
    };
};

export const disableMfa = (data) => {
    const loading = () => ({
        type: MFA_STATUS_LOADING
    });
    const success = (payload) => ({
        type: MFA_STATUS_SUCCESS,
        payload
    });
    const fail = (payload) => ({
        type: MFA_STATUS_FAILED,
        payload
    });
    return (dispatch) => {
        dispatch(loading());
        disableMFARequest({ totp: data })
            .then(() => {
                dispatch(success(false));
                dispatch(initQrCodeImage());
            })
            .catch((err) => {
                const error = err.data?.errors?.length > 0 ? err.data.errors[0].detail : '';
                dispatch(fail(error));
            });
    };
};

export const setMfaLogin = (payload) => ({
    type: MFA_LOGIN,
    payload
});

export const setMfaToken = (payload) => ({
    type: SET_MFA_TOKEN,
    payload
});

export const mfaLoginUser = (data, device, successCallback, isGamePage) => {
    const loading = () => ({
        type: LOGIN_USER
    });

    const success = () => ({
        type: LOGIN_USER_SUCCESS
    });

    const fail = (payload) => ({
        type: MFA_STATUS_FAILED,
        payload
    });

    return (dispatch, getState) => {
        dispatch(loading());
        loginMFARequest({ totp: data }, { headers: { Authorization: `Bearer ${getState().user.mfaToken}` } })
            .then(async (res) => {
                const token = res.data.authorization.token;
                dispatch(handleLoginSuccess(isGamePage, device, successCallback, token, success));
                dispatch(setMfaToken(''));
            })
            .catch((err) => {
                const error = err.data?.errors?.length > 0 ? err.data.errors[0].detail : '';
                dispatch(fail(error));
            });
    };
};

export const mfaStatusInit = () => ({
    type: MFA_STATUS_INIT
});

export const updatePlayerData = (data, onSuccess, onError) => {
    const success = (payload) => ({
        type: UPDATE_PLAYER_INFORMATION_SUCCESS,
        payload
    });

    return (dispatch) => {
        updatePlayerInformation(data)
            .then((res) => {
                const {
                    data: { data }
                } = res;
                onSuccess && onSuccess(data);
                dispatch(success(data));
            })
            .catch(() => onError && onError());
    };
};

export const setIsNicknameModalOpened = (payload) => ({
    type: SET_IS_NICKNAME_MODAL_OPENED,
    payload
});

export const initUpdateBankCard = () => ({
    type: INIT_UPDATE_BANK_CARD
});
