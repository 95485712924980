import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { processEmailVerification, verifyEmailField } from '../../store/reducers/register';
import debounce from 'lodash/debounce';

export const withRegisterStep1 = (PageComponent) => {
    const WithRegisterStep1 = ({ ...props }) => {
        const tr = useSelector((state) => state.global.data.translations);
        const dispatch = useDispatch();

        const validateEmail = (value) => {
            let error;
            if (!value) {
                error = tr['register.form.email.error.required'];
            }
            return error;
        };

        const checkEmail = useCallback(
            debounce((data) => {
                dispatch(verifyEmailField(data));
            }, 500),
            []
        );

        const onChangeEmail = (e, setFieldValue) => {
            const value = e.target.value.trim();
            const err = validateEmail(value);
            const data = {
                email: value
            };
            if (!err) {
                checkEmail(data);
            } else {
                dispatch(
                    processEmailVerification({
                        isLoading: false,
                        isSuccess: false,
                        error: '',
                        value: ''
                    })
                );
            }
            setFieldValue('email', data.email);
        };

        return <PageComponent {...props} onChangeEmail={onChangeEmail} validateEmail={validateEmail} />;
    };

    return WithRegisterStep1;
};
