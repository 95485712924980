import { default as MuiSelect } from '@mui/material/Select';

const Select = ({ children, inputProps, ...props }) => {
    return (
        <MuiSelect variant="standard" inputProps={inputProps} disableUnderline {...props}>
            {children}
        </MuiSelect>
    );
};

export default Select;
