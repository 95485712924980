import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import VltProgressBar from '../../../../../../common/vlt-progress-bar';

const FavoriteGameItem = ({ game, showVolatility = false }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const gamesData = useSelector((state) => state.games.data);
    const providersList = gamesData.softwares || [];
    const providerName = providersList.find((item) => item.id === game.softwareId)?.name ?? '';
    const { rtp, volatility } = game.metadata;
    const isEvolution = game.isEvolution;
    const message = volatility === 'default' ? tr['vlt_default'] : tr[`volatility_${volatility}`];

    return (
        <div className="favorite-game">
            <div className="favorite-game_thumbnail">
                <img src={`${isEvolution ? game.thumbnail : game.metadata.thumbnailBackground}`} alt={game.name} />
            </div>
            <div className="game-info">
                <div className="game-info--text-wrapper">
                    <h3>{game.name}</h3>
                    <p>{providerName}</p>
                </div>
                {showVolatility && (
                    <div className="volatility-info">
                        <span>
                            {tr['rtp']} <span className="rtp">{`${rtp}%`}</span>
                        </span>
                        <div className="separator" />
                        <Tooltip
                            title={message}
                            placement="right"
                            PopperProps={{
                                modifiers: [
                                    {
                                        name: 'flip',
                                        options: {
                                            fallbackPlacements: ['bottom']
                                        }
                                    }
                                ]
                            }}
                        >
                            <span>
                                <VltProgressBar vlt={volatility} />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FavoriteGameItem;
