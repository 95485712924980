import { withGameLogic } from '../../../../hoc/withGameLogic';
import EvolutionGame from '../../../../common/games/game/EvolutionGame';
import FormLoader from '../../../../common/FormLoader';
import ErrorGame from './fragment/ErrorGame';
import FavoritePanel from './favoritePanel';
import Actions from './Actions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanGameUrl, setGameProps } from '../../../../../store/reducers/games';
import { setGameOverlayActive, setGameOverlayMinimized } from '../../../../../store/reducers/global';

const EvolutionGameDesktop = ({
    game,
    iframeSrc,
    gameLaunchScripts,
    isErrorPage,
    gameLaunchError,
    isGameUrlLoading,
    containerRef,
    playRef,
    dimensions,
    offsetLeft,
    topHeight,
    addGameIndent,
    side,
    actionsIndent
}) => {
    const dispatch = useDispatch();
    const { desktopHeaderHeight, cookieAccepted } = useSelector((state) => state.global);
    const playRightPadding = `${side / 2}px`;
    const gameName = game?.name;

    useEffect(() => {
        return () => {
            dispatch(cleanGameUrl());
            dispatch(setGameOverlayActive(false));
            dispatch(setGameOverlayMinimized(null));
            dispatch(setGameProps({}));
        };
    }, []);

    if (isErrorPage || gameLaunchError) {
        return <ErrorGame id="evoErrorPlay" isErrorPage={isErrorPage} gameName={gameName} />;
    }

    return (
        <div
            id="evoPlay"
            ref={playRef}
            style={{
                padding: `0px ${playRightPadding}`,
                top: cookieAccepted ? '60px' : `${desktopHeaderHeight}px`,
                height: `calc(100% - ${desktopHeaderHeight + 30}px)`
            }}
        >
            <div className="game-page" style={dimensions} ref={containerRef}>
                <div className="game-page-bg" />
                {isGameUrlLoading ? (
                    <FormLoader />
                ) : (
                    iframeSrc &&
                    containerRef?.current?.clientWidth && (
                        <EvolutionGame
                            iframeSrc={iframeSrc}
                            gameLaunchScripts={gameLaunchScripts}
                            dimensions={dimensions}
                            topHeight={topHeight - addGameIndent}
                            offsetLeft={offsetLeft}
                        />
                    )
                )}
                {game && !isGameUrlLoading && <Actions rightIndent={`-${actionsIndent}px`} game={game} playRef={playRef} />}
                <FavoritePanel game={game} dimensions={dimensions} />
            </div>
        </div>
    );
};

export default withGameLogic(EvolutionGameDesktop);
