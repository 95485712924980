import { useSelector } from 'react-redux';
import Box from '@mui/system/Box';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';
import { EXTREME, GAMES_VOLATILITY_DEFAULT, HIGH } from '../../../variables';

const VltProgressBar = ({ vlt, isHovered = false }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const volatility = vlt === EXTREME ? HIGH : vlt;
    const isVltValid = [GAMES_VOLATILITY_DEFAULT, undefined].includes(volatility);
    const message = volatility === 'default' ? tr['vlt_default'] : tr[`volatility_${volatility}`];

    return (
        <Box>
            <div className="vlt">{tr['favorite.modal.vlt']}</div>
            {isVltValid ? (
                <span className="value">{tr['volatility_default']}</span>
            ) : (
                <div className="progress-bar--outer">
                    <div className={`progress-bar--inner ${volatility}`} />
                    {isHovered && <div className="progress-bar--tooltip">{volatility}</div>}
                </div>
            )}
            {!isMobile && (
                <div className={cx('progress-bar--tooltip', { visible: isHovered, default: isVltValid })}>{message}</div>
            )}
        </Box>
    );
};

export default VltProgressBar;
