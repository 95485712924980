import { setIsFavoriteGameModalOpened } from '../../../../../../../store/reducers/games';
import { useDispatch, useSelector } from 'react-redux';
import CloseFavoriteRounded from '../../../../../../svg-icons/game-controls/CloseFavoriteRounded';

const FavoritePanelHeader = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const dispatch = useDispatch();

    return (
        <div className="favorite-panel_header">
            <h3>{tr['lobby.games.favorites']}</h3>
            <div
                className="close-btn"
                data-testid="close_favorite_modal"
                onClick={() => dispatch(setIsFavoriteGameModalOpened(false))}
            >
                <CloseFavoriteRounded />
            </div>
        </div>
    );
};

export default FavoritePanelHeader;
