import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import WheelWidget from '../common/wheel-of-fortune/fragments/widget';

export const withWheelWidget = (PageComponent) => {
    const WithWheelWidget = ({ ...props }) => {
        const { loadingProgress } = useSelector((state) => state.global);
        const { id: userId } = useSelector((state) => state.user.information);
        const { isWofEnabled } = useSelector((state) => state.wheel_of_fortune);
        const hasWidget = loadingProgress === 100 && userId && isWofEnabled;

        return (
            <Fragment>
                <PageComponent {...props} />
                {hasWidget && <WheelWidget />}
            </Fragment>
        );
    };

    return WithWheelWidget;
};
