import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import cx from 'classnames';
import ReactCodeInput from 'react-verification-code-input-2';
import { verificationCodeHandlers } from '@tlf-e/brand-utils';
import { finishPhoneVerificationNew } from '../../../../store/reducers/register';
import FormErrorMessage from '../../../common/FormErrorMessage';
import { useEffect, useState } from 'react';

const CodeVerification = ({ size, onSubmit }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const tr = useSelector((state) => state.global.data.translations);
    const [validationIndex, setValidationIndex] = useState('');
    const { phoneVerification, submitStartPhoneVerification, submitFinishPhoneVerification } = useSelector(
        (state) => state.register
    );
    const dispatch = useDispatch();
    const isCodeVerified = submitFinishPhoneVerification.isSuccess;
    const isCodeLoading = submitFinishPhoneVerification.isLoading;
    const isPhoneVerificationPassed = submitStartPhoneVerification.isVerified || isCodeVerified;
    const isPhoneNumberCodeSent = phoneVerification.phoneNumber;
    const isCodeDisabled = !isPhoneNumberCodeSent || isPhoneVerificationPassed || isCodeLoading;

    const validateCodeField = () => {
        if (!isPhoneVerificationPassed) {
            return tr['register.form.field.verification_code.invalid'];
        }
        return false;
    };

    useEffect(() => {
        setFieldValue('code', '');
        setValidationIndex(validationIndex + 1);
    }, [submitStartPhoneVerification, submitFinishPhoneVerification.isError]);

    return (
        <div className="form-group">
            <div className="code-verification">
                <label className="form-group--title form-group-title">{tr['register.form.field.verification_code']}</label>
                <FormErrorMessage name="code" />
                {isCodeVerified && (
                    <label className="form-message form-message--success">
                        {tr['register.form.field.verification_code.valid']}
                    </label>
                )}
            </div>
            <div
                className={cx('verification-code', {
                    'form-group--cell-error-marked': touched.code && errors.code,
                    'form-group--cell-success-marked': isCodeVerified
                })}
            >
                <Field name="code" validate={validateCodeField}>
                    {({ form }) => (
                        <ReactCodeInput
                            key={validationIndex}
                            autoFocus={false}
                            className="verification-code--inputs"
                            fieldWidth={size}
                            fieldHeight={size}
                            onChange={(code) => setFieldValue('code', code)}
                            value={values.code}
                            fields={4}
                            disabled={isCodeDisabled}
                            onComplete={(code) => {
                                setFieldValue('code', code);
                                const handlers = verificationCodeHandlers('code', tr, form);
                                dispatch(
                                    finishPhoneVerificationNew(
                                        {
                                            ...phoneVerification,
                                            code
                                        },
                                        { ...handlers, onSuccess: onSubmit }
                                    )
                                );
                            }}
                        />
                    )}
                </Field>
            </div>
            <p className="info">{tr['register.form.field.verification_code.info']}</p>
        </div>
    );
};

export default CodeVerification;
