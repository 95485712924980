const Lobby = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8655 2.55058C10.823 1.54864 9.177 1.54864 8.13449 2.55058L2.59427 7.87518C2.45853 8.00564 2.36723 8.17558 2.33329 8.36092C1.66971 11.9854 1.62073 15.6959 2.1884 19.3367L2.32315 20.2009H5.88749V12.7509C5.88749 12.2541 6.2896 11.8514 6.78563 11.8514H13.2144C13.7104 11.8514 14.1125 12.2541 14.1125 12.7509V20.2009H17.6769L17.8116 19.3367C18.3793 15.6959 18.3303 11.9854 17.6667 8.36092C17.6328 8.17558 17.5415 8.00564 17.4057 7.87519L11.8655 2.55058ZM6.89082 1.25243C8.62834 -0.417476 11.3717 -0.417476 13.1092 1.25243L18.6494 6.57703C19.0572 6.969 19.3316 7.47958 19.4335 8.03642C20.1335 11.8597 20.1852 15.7738 19.5864 19.6143L19.3699 21.0026C19.2804 21.5767 18.7866 22 18.2065 22H13.2144C12.7183 22 12.3162 21.5973 12.3162 21.1004V13.6505H7.68376V21.1004C7.68376 21.5973 7.28165 22 6.78563 22H1.79353C1.21335 22 0.719634 21.5767 0.630113 21.0026L0.413648 19.6143C-0.185165 15.7738 -0.133496 11.8597 0.566486 8.03642C0.668433 7.47958 0.942764 6.969 1.3506 6.57703L6.89082 1.25243Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default Lobby;
