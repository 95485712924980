import { ErrorMessage } from 'formik';

const FormErrorMessage = ({ name }) => {
    return (
        <ErrorMessage name={name}>
            {(msg) => {
                return <span className="form-message form-message--error">{msg}</span>;
            }}
        </ErrorMessage>
    );
};

export default FormErrorMessage;
