const WheelOfFortune = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 0C11 0 10.94 0 10.92 0C4.9 0.04 0 4.98 0 11C0 13.94 1.14 16.7 3.22 18.78C5.3 20.86 8.06 22 11 22H11.09C17.15 21.95 22.05 16.98 22 10.92C21.95 4.88 17.03 0.01 11 0.01V0ZM11 20.71C5.64 20.71 1.29 16.35 1.29 10.99C1.29 8.4 2.3 5.96 4.14 4.12C5.97 2.29 8.41 1.28 11.01 1.28C12.28 1.28 13.52 1.53 14.7 2.01C17.1 3 18.97 4.87 19.97 7.26C20.97 9.66 20.97 12.3 19.98 14.7C18.47 18.35 14.95 20.7 11 20.7V20.71Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M12.33 2.54C12 2.18 11.52 1.95 10.99 1.95C10.46 1.95 9.99 2.18 9.65 2.54C5.47 3.21 2.44 6.76 2.44 10.99C2.44 13.28 3.33 15.43 4.94 17.04C6.55 18.65 8.71 19.55 10.99 19.55C15.22 19.55 18.78 16.5 19.44 12.32C19.8 10.06 19.25 7.8 17.91 5.95C16.57 4.1 14.58 2.89 12.33 2.53V2.54ZM17.51 7.8L11.84 10.15L14.19 4.46C15.64 5.17 16.8 6.34 17.5 7.8H17.51ZM18.15 9.82C18.34 11.02 18.23 12.22 17.84 13.33L12.2 11L17.86 8.65C17.99 9.03 18.08 9.42 18.15 9.82ZM3.86 12.09C3.67 10.9 3.79 9.73 4.15 8.66L9.81 11L4.18 13.33C4.04 12.93 3.93 12.52 3.86 12.09ZM9.93 5.54C10.28 6.08 10.62 6.51 10.64 6.53C10.73 6.64 10.86 6.7 11 6.7C11.14 6.7 11.27 6.64 11.36 6.53C11.37 6.51 11.72 6.08 12.07 5.54C12.62 4.7 12.77 4.24 12.81 3.95C13 4 13.18 4.05 13.36 4.11L11.01 9.8L8.66 4.12C8.84 4.06 9.02 4 9.2 3.95C9.24 4.24 9.4 4.7 9.94 5.54H9.93ZM11 2.86C11.5 2.86 11.91 3.27 11.91 3.77C11.91 4.06 11.49 4.8 11 5.48C10.52 4.8 10.09 4.06 10.09 3.77C10.09 3.27 10.5 2.86 11 2.86ZM7.8 4.47L10.16 10.15L4.5 7.81C5.19 6.38 6.35 5.19 7.8 4.47ZM4.53 14.18L10.16 11.84L7.84 17.45C6.42 16.76 5.25 15.62 4.53 14.18ZM8.68 17.8L11 12.19L13.33 17.8C12.95 17.93 12.55 18.03 12.14 18.1C10.94 18.29 9.76 18.17 8.68 17.8ZM16.86 15.2C16.15 16.18 15.23 16.94 14.18 17.46L11.85 11.84L17.48 14.17C17.3 14.53 17.1 14.87 16.86 15.2Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default WheelOfFortune;
