import { dataLayerPushField } from '@tlf-e/brand-utils';
import { Field, useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import FormErrorMessage from '../FormErrorMessage';
import EyeOpen from '../../svg-icons/EyeOpen';
import debounce from 'lodash/debounce';
import cx from 'classnames';

const PasswordField = ({ fieldName, title, testId, dataLayerEvent = '' }) => {
    const [showPassword, setShowPassword] = useState(true);
    const { touched, errors, setFieldValue, setFieldTouched, validateField } = useFormikContext();

    const debouncedValidate = useCallback(
        debounce((fieldName, validateField) => {
            validateField(fieldName);
        }, 300),
        []
    );

    const handlePasswordChange = (setFieldValue, setFieldTouched, validateField) => (event) => {
        setFieldValue(`${fieldName}`, event.target.value);
        setFieldTouched(`${fieldName}`, true, false);
        debouncedValidate(`${fieldName}`, validateField);
    };

    return (
        <div
            className={cx('form-group password', {
                'form-group--error-marked': touched[fieldName] && errors[fieldName]
            })}
        >
            <label className="section-title" htmlFor={`${fieldName}`}>
                {title}
            </label>
            <Field name={`${fieldName}`}>
                {({ field }) => (
                    <input
                        {...field}
                        type={!showPassword ? 'text' : 'password'}
                        className="text-field"
                        data-testid={testId}
                        autoComplete="off"
                        onChange={handlePasswordChange(setFieldValue, setFieldTouched, validateField)}
                        id={`${fieldName}`}
                        onBlur={(e) => {
                            !!dataLayerEvent &&
                                e.target.value &&
                                dataLayerPushField({
                                    event: dataLayerEvent,
                                    field: field,
                                    valid: !errors.password,
                                    error: errors.password
                                });
                        }}
                    />
                )}
            </Field>
            <FormErrorMessage name={`${fieldName}`} />
            <div
                className={cx('reveal-password', {
                    hide: showPassword
                })}
                data-testid="show_password"
                onClick={() => setShowPassword(!showPassword)}
            >
                <EyeOpen />
                <span />
            </div>
        </div>
    );
};

export default PasswordField;
