import { imageBaseUrl } from '../../../../../services/http';

const WinValueBonus = () => {
    return (
        <div className="prize prize--bonus">
            <img src={`${imageBaseUrl}/gift.png`} alt="gift" />
        </div>
    );
};

export default WinValueBonus;
