const ExitGameIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <circle cx="24" cy="24" r="23.5" stroke="white" />
        <path
            d="M23.8878 32.7654H16.3894C16.3894 32.7654 16.2637 32.7182 16.2637 32.6552V15.3449C16.2637 15.3449 16.3108 15.2348 16.3894 15.2348H23.8878C24.5166 15.2348 25.0197 14.7312 25.0197 14.1175C25.0197 13.5037 24.5166 13.0002 23.8878 13.0002H16.3894C15.069 12.9844 14 14.0388 14 15.3449V32.6395C14 33.9456 15.069 35 16.3894 35H23.8878C24.5166 35 25.0197 34.4964 25.0197 33.8827C25.0197 33.269 24.5166 32.7654 23.8878 32.7654Z"
            fill="white"
        />
        <path
            d="M35.5205 22.7884L35.3948 22.6625C33.8071 21.0101 31.9521 19.6725 29.8771 18.6968C29.3741 18.4608 28.761 18.4765 28.2737 18.7598C27.7864 19.043 27.4562 19.5151 27.4091 20.0817L27.3305 20.8685H21.7342C20.7596 20.8685 19.9736 21.6553 19.9736 22.6153V25.4006C19.9736 26.3606 20.7596 27.1474 21.7342 27.1474H27.3305L27.4091 27.9342C27.472 28.5008 27.7864 28.9729 28.2737 29.2561C28.5409 29.3978 28.8396 29.4764 29.1225 29.4764C29.3741 29.4764 29.6256 29.4135 29.8614 29.3033C31.9364 28.3119 33.7914 26.99 35.3791 25.3377L35.5048 25.2118C36.1651 24.5351 36.1651 23.465 35.5048 22.7884H35.5205ZM29.3426 22.5838C29.4369 22.4264 29.4998 22.2376 29.5155 22.0487C29.5313 21.7183 29.5627 21.3878 29.5941 21.0573C30.7731 21.6868 31.8892 22.4579 32.8796 23.3549C33.0997 23.5595 33.3355 23.764 33.5556 23.9844C32.3923 25.1489 31.0561 26.1403 29.5941 26.9114C29.5627 26.5809 29.5313 26.2504 29.5155 25.92C29.4841 25.322 28.9811 24.8656 28.3837 24.8656H22.2529V23.0874H28.3837C28.7767 23.0874 29.1383 22.8985 29.3426 22.5681V22.5838Z"
            fill="white"
        />
    </svg>
);

export default ExitGameIcon;
