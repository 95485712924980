const BetRace = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.3204 6.01896C11.5958 6.3024 11.5958 6.76195 11.3204 7.04539L8.02853 10.4324C7.75305 10.7159 7.30642 10.7159 7.03095 10.4324L5.1499 8.49698C4.87442 8.21354 4.87442 7.75399 5.1499 7.47055C5.42537 7.18711 5.872 7.18711 6.14748 7.47055L7.52974 8.89279L10.3228 6.01896C10.5983 5.73551 11.0449 5.73551 11.3204 6.01896Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.0284487 7.36137C0.361703 3.20148 3.74021 0 7.79688 0H8.20313C12.2598 0 15.6383 3.20148 15.9716 7.36137C16.1506 9.596 15.4797 11.8149 14.1003 13.5507L9.59229 19.2233C8.76933 20.2589 7.23068 20.2589 6.40771 19.2233L1.89972 13.5507C0.520289 11.8149 -0.15057 9.596 0.0284487 7.36137ZM7.79688 1.45159C4.47443 1.45159 1.70741 4.07363 1.43447 7.48062C1.28548 9.34044 1.84381 11.1872 2.99188 12.6318L7.49987 18.3045C7.75836 18.6297 8.24164 18.6297 8.50013 18.3045L13.0081 12.6318C14.1562 11.1872 14.7145 9.34044 14.5655 7.48062C14.2926 4.07363 11.5256 1.45159 8.20313 1.45159H7.79688Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default BetRace;
