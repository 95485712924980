const CloseFavoriteRounded = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            d="M8.27919 8.27901C8.61756 7.94077 9.147 7.91007 9.52004 8.18688L9.62692 8.27915L12.0001 10.6525L14.3712 8.28175C14.7434 7.90964 15.3468 7.90964 15.719 8.28175C16.0573 8.62002 16.0881 9.14937 15.8112 9.52239L15.719 9.62926L13.3477 12.0001L15.7209 14.3735C16.0931 14.7456 16.093 15.3489 15.7208 15.721C15.3824 16.0592 14.853 16.0899 14.48 15.8131L14.3731 15.7208L12 13.3476L9.63003 15.7172C9.25786 16.0893 8.65446 16.0893 8.2823 15.7172C7.94397 15.379 7.91321 14.8496 8.19003 14.4766L8.2823 14.3697L10.6524 12L8.27905 9.62652C7.90693 9.25438 7.90699 8.65108 8.27919 8.27901Z"
            fill="white"
        />
        <circle cx="12" cy="12" r="11.5" stroke="white" />
    </svg>
);

export default CloseFavoriteRounded;
