const OtherGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3296 0.312972C11.5339 -0.0998711 10.4557 -0.106241 9.65361 0.305538C6.5793 1.88391 3.79084 3.69364 1.34727 5.70088C0.969394 6.01127 0.701227 6.46233 0.705803 6.99324C0.71036 7.52198 0.983984 7.967 1.35956 8.27228C3.78283 10.242 6.57359 12.0534 9.59162 13.6192C10.3873 14.0321 11.4655 14.0384 12.2676 13.6267C15.3419 12.0483 18.1304 10.2386 20.5739 8.23133C20.9518 7.92093 21.22 7.46988 21.2154 6.93897C21.2108 6.41023 20.9372 5.96521 20.5616 5.65993C18.1384 3.6902 15.3476 1.87885 12.3296 0.312972ZM10.4697 1.87638C10.7538 1.7305 11.2206 1.73188 11.5067 1.88031C14.387 3.37473 17.041 5.09402 19.3402 6.95105C17.0272 8.83797 14.3812 10.5517 11.4515 12.0558C11.1674 12.2017 10.7006 12.2003 10.4145 12.0519C7.53418 10.5575 4.88024 8.83819 2.58103 6.98115C4.89397 5.09424 7.54002 3.3805 10.4697 1.87638Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M21.8649 11.3189C22.125 11.733 21.9982 12.2783 21.5816 12.5369L14.3406 17.0309C13.358 17.6407 12.1389 17.9227 10.9605 17.9228C9.78205 17.9228 8.56292 17.641 7.58019 17.0313L0.418548 12.5881C0.00192094 12.3296 -0.125038 11.7843 0.134978 11.3702C0.394993 10.956 0.94352 10.8298 1.36015 11.0883L8.52179 15.5315C9.16695 15.9317 10.0434 16.1548 10.9604 16.1548C11.8774 16.1547 12.7537 15.9315 13.3988 15.5312L20.6397 11.0372C21.0563 10.7786 21.6049 10.9047 21.8649 11.3189Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M21.8649 15.7443C22.125 16.1584 21.9982 16.7037 21.5817 16.9623L15.3109 20.8552C14.057 21.6336 12.4884 21.9999 10.9604 22C9.4325 22.0001 7.86384 21.634 6.60969 20.8557L0.418457 17.0135C0.00186143 16.755 -0.125032 16.2097 0.135034 15.7956C0.395099 15.3814 0.943642 15.2553 1.36024 15.5138L7.55147 19.356C8.46799 19.9248 9.69385 20.2321 10.9603 20.232C12.2268 20.232 13.4526 19.9245 14.3689 19.3556L20.6396 15.4627C21.0562 15.2041 21.6048 15.3302 21.8649 15.7443Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default OtherGames;
