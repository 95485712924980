import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLocalStorageAvailable, setRealityCheckData } from '../../utils/localStorageHelper';
import { LOCAL_STORAGE_REALITY_CHECK_DATE } from '../../variables';
import { setOpenRealityCheckModal } from '../../store/reducers/global';

export const withRealityCheck = (PageComponent) => {
    const WithRealityCheck = ({ ...props }) => {
        const { isAuth, information } = useSelector((state) => state.user);
        const userRealityCheckFrequency = information.realityCheck;
        const isOpenedRealityCheckModal = useSelector((state) => state.global.isOpenRealityCheckModal);
        const { time } = useSelector((state) => state.global.clientIp);
        const currentDate = dayjs(time).tz('Europe/Paris');
        const timerRef = useRef(null);
        const dispatch = useDispatch();

        useEffect(() => {
            if (isLocalStorageAvailable()) {
                if (isAuth) {
                    handleRealityCheck(localStorage.getItem(LOCAL_STORAGE_REALITY_CHECK_DATE));
                    window.addEventListener('storage', realityCheckListener);
                    return () => {
                        window.removeEventListener('storage', realityCheckListener);
                        clearTimeout(timerRef?.current);
                    };
                }
            }
        }, [isAuth, userRealityCheckFrequency, isOpenedRealityCheckModal]);

        const realityCheckListener = (e) => {
            if (e.key === LOCAL_STORAGE_REALITY_CHECK_DATE && e.newValue && e.oldValue !== e.newValue) {
                handleRealityCheck(e.newValue);
            }
        };

        const handleRealityCheck = (realityCheckDate) => {
            const hasRealityCheck = realityCheckDate || userRealityCheckFrequency;
            if (hasRealityCheck) {
                clearTimeout(timerRef.current);
                let timeout;

                if (realityCheckDate) {
                    timeout = Number(realityCheckDate) - Number(currentDate);
                } else {
                    timeout = Number(userRealityCheckFrequency) * 1000;
                    setRealityCheckData(currentDate + timeout, userRealityCheckFrequency);
                }

                timerRef.current = setTimeout(() => dispatch(setOpenRealityCheckModal(true)), timeout);
            }
        };

        return <PageComponent current={currentDate} {...props} />;
    };

    return WithRealityCheck;
};
