import { useSelector } from 'react-redux';
import Modal from '../../../../ui/Modal';
import WinValueBonus from './WinValueBonus';
import WinValuePoints from './WinValuePoints';
import { isMobile } from 'react-device-detect';
import { WOF_WIN_TYPE_BONUS, WOF_WIN_TYPE_POINTS } from '../../../../../variables';

const WinModal = ({ win, type, isOpen, onClose }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const textKey = type === WOF_WIN_TYPE_BONUS ? WOF_WIN_TYPE_BONUS : WOF_WIN_TYPE_POINTS;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className="modal--modern win-modal"
            disableScrollLock={false}
            transitionEffect={isMobile ? 'slide' : 'fade'}
        >
            <div className="modal-box">
                <div className="modal-box--content">
                    <div className="modal-body">
                        {type === WOF_WIN_TYPE_BONUS ? <WinValueBonus /> : <WinValuePoints value={win} />}
                        <h2>{tr['wof.win_modal.title']}</h2>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: tr[`wof.win_modal.${textKey}.paragraph_1`].replace('##WIN##', win)
                            }}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: tr['wof.win_modal.paragraph_2']
                            }}
                        />
                    </div>
                    <button className="btn btn-modern btn-submit" onClick={onClose} data-testid="win-modal-close">
                        {tr['btn.okay']}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default WinModal;
