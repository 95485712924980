import { useEffect, useState } from 'react';

const NotificationTimer = ({ children, autoHideCountdown, onClose }) => {
    const [timeToClose, setTimeToClose] = useState(autoHideCountdown);

    useEffect(() => {
        if (timeToClose > 0) {
            const intervalTime = 1000;
            const interval = setInterval(() => setTimeToClose(timeToClose - intervalTime), intervalTime);
            return () => clearInterval(interval);
        } else {
            onClose && onClose();
        }
    }, [timeToClose]);

    return children({ timer: timeToClose / 1000 });
};

export default NotificationTimer;
