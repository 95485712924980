import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import Modal from '../../../../ui/Modal';
import WheelWidgetIcon from './WheelWidgetIcon';
import AdjustedWheelDesktopModal from '../../../../desktop/wheel-of-fortune/fragments/AdjustedWheelDesktopModal';
import AdjustedWheelMobile from '../../../../mobile/wheel-of-fortune/fragments/AdjustedWheelMobile';
import Cross from '../../../../svg-icons/Cross';
import { isMobile } from 'react-device-detect';
import { getWof, setIsWofContentPageLoaded, setWheelOrigin } from '../../../../../store/reducers/wheel-of-fortune';
import routes from '../../../../../utils/routes.json';
import { WHEEL_LOCATION_WIDGET, WHEEL_ORIGIN_WIDGET } from '../../../../../variables';
import WheelOfFortuneMaintenance from '../WheelOfFortuneMaintenance';
import FormLoader from '../../../FormLoader';
import cx from 'classnames';

const WheelWidget = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { wof, isLoading: wofLoading } = useSelector((state) => state.wheel_of_fortune);
    const [isOpen, setOpen] = useState(false);
    const dispatch = useDispatch();

    const onOpen = () => setOpen(true);

    const onClose = () => setOpen(false);

    const handleClick = () => {
        wof && dispatch(setWheelOrigin(WHEEL_ORIGIN_WIDGET));
        onClose();
    };

    useEffect(() => {
        if (!isOpen) return;
        dispatch(getWof(false));

        return () => dispatch(setIsWofContentPageLoaded(false));
    }, [isOpen]);

    return (
        <Fragment>
            <WheelWidgetIcon isOpen={isOpen} onOpen={onOpen} isMobile={isMobile} />
            <Modal
                open={isOpen}
                onClose={onClose}
                className="modal--modern wheel-of-fortune-modal"
                disableScrollLock={false}
                transitionEffect={isMobile ? 'slide' : 'fade'}
            >
                <div className="modal-box">
                    <div className="modal-box--title">
                        <button className="modal-box--close" data-testid="close_modal" onClick={onClose}>
                            <Cross />
                        </button>
                    </div>
                    <div className={cx('modal-box--content', { loading: wofLoading })}>
                        {wofLoading ? (
                            <FormLoader extraClass="wheel-of-fortune-loader" />
                        ) : (
                            <Fragment>
                                <div className="modal-body">
                                    {wof ? (
                                        <Fragment>
                                            {isMobile ? (
                                                <AdjustedWheelMobile place={WHEEL_LOCATION_WIDGET} />
                                            ) : (
                                                <AdjustedWheelDesktopModal />
                                            )}
                                            <div className="modal-info">
                                                <div className="gradient-border">
                                                    <div className="gradient-border--text">
                                                        <div>{tr['wof.widget_modal.paragraph_1']}</div>
                                                        <div>{tr['wof.widget_modal.paragraph_2']}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    ) : (
                                        <WheelOfFortuneMaintenance />
                                    )}
                                </div>
                                <div className="container-btns">
                                    {wof ? (
                                        <Link href={routes.wheel_of_fortune} as={routes.wheel_of_fortune}>
                                            <a
                                                className="btn btn-modern btn-submit"
                                                data-testid="wheel_page_link"
                                                onClick={handleClick}
                                            >
                                                {tr['wof.widget_modal.btn']}
                                            </a>
                                        </Link>
                                    ) : (
                                        <button
                                            className="btn btn-modern btn-submit"
                                            data-testid="wheel_page_link"
                                            onClick={handleClick}
                                        >
                                            {tr['wof.widget_modal.btn.maintenance']}
                                        </button>
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default WheelWidget;
