const Slots = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4382 0.0239441C11.5741 0.00813949 11.7125 0 11.8529 0H15.5C16.458 0 17.3218 0.378795 17.9314 0.985752C18.5409 0.378795 19.4048 0 20.3627 0H24.0098C25.8562 0 27.3529 1.4071 27.3529 3.14286V4H29.1765C29.1765 3.52661 29.5847 3.14286 30.0882 3.14286C30.5918 3.14286 31 3.52661 31 4V11.8421C31.5978 12.1715 32 12.787 32 13.4921C32 14.544 31.1046 15.3968 30 15.3968C28.8954 15.3968 28 14.544 28 13.4921C28 12.7197 28.4827 12.0547 29.1765 11.7558V5.71429L27.3529 5.71429V16.8571C27.3529 18.5929 25.8562 20 24.0098 20H20.3627C19.4048 20 18.5409 19.6212 17.9314 19.0143C17.3218 19.6212 16.458 20 15.5 20H11.8529C11.1779 20 10.5497 19.8119 10.0242 19.4885C9.80387 19.3529 9.60162 19.1935 9.42157 19.0143C8.81204 19.6212 7.94817 20 6.9902 20H3.34314C1.49677 20 0 18.5929 0 16.8571V3.14286C0 1.4071 1.49677 0 3.34314 0H6.99019C7.94817 0 8.81204 0.378795 9.42157 0.985752C9.94175 0.467767 10.6472 0.115956 11.4382 0.0239441ZM8.5098 16.8571V3.14286C8.5098 2.35388 7.82945 1.71429 6.99019 1.71429L3.34314 1.71429C2.50388 1.71429 1.82353 2.35388 1.82353 3.14286L1.82353 16.8571C1.82353 17.6461 2.50388 18.2857 3.34314 18.2857H6.9902C7.82945 18.2857 8.5098 17.6461 8.5098 16.8571ZM17.0196 3.14286V16.8571C17.0196 17.6461 16.3393 18.2857 15.5 18.2857H11.8529C11.0137 18.2857 10.3333 17.6461 10.3333 16.8571L10.3333 3.14286C10.3333 2.35388 11.0137 1.71429 11.8529 1.71429L15.5 1.71429C16.3393 1.71429 17.0196 2.35388 17.0196 3.14286ZM20.3627 18.2857C19.5235 18.2857 18.8431 17.6461 18.8431 16.8571V3.14286C18.8431 2.35388 19.5235 1.71429 20.3627 1.71429L24.0098 1.71429C24.8491 1.71429 25.5294 2.35388 25.5294 3.14286V16.8571C25.5294 17.6461 24.8491 18.2857 24.0098 18.2857L20.3627 18.2857ZM30 14.4445C30.5523 14.4445 31 14.0181 31 13.4921C31 12.9661 30.5523 12.5397 30 12.5397C29.4477 12.5397 29 12.9661 29 13.4921C29 14.0181 29.4477 14.4445 30 14.4445Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default Slots;
