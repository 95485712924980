const FavoriteInactive = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.71091 0.789771C7.28528 -0.263259 8.71472 -0.263256 9.28909 0.789772L11.0481 4.0146C11.2102 4.31185 11.4904 4.5147 11.8098 4.56602L14.7307 5.03534C16.0039 5.23992 16.4505 6.95009 15.459 7.82402L13.3308 9.69976C13.0321 9.96303 12.8916 10.3783 12.9642 10.7837L13.5603 14.1142C13.7938 15.4188 12.5065 16.4324 11.4019 15.8137L8.49598 14.186C8.18553 14.0121 7.81447 14.0121 7.50402 14.186L4.59815 15.8137C3.49355 16.4324 2.20617 15.4188 2.43969 14.1142L3.03583 10.7837C3.10839 10.3783 2.96794 9.96303 2.66923 9.69976L0.541032 7.82402C-0.450533 6.95008 -0.00392353 5.23992 1.26929 5.03534L4.19016 4.56602C4.50958 4.5147 4.78982 4.31185 4.95195 4.0146L6.71091 0.789771ZM8.18415 1.47185C8.1021 1.32141 7.8979 1.32141 7.81584 1.47184L6.05688 4.69667C5.70019 5.35062 5.08367 5.79689 4.38094 5.9098L1.46008 6.37912C1.27819 6.40835 1.21439 6.65266 1.35604 6.7775L3.48423 8.65324C4.1414 9.23244 4.45039 10.1461 4.29076 11.0379L3.69462 14.3684C3.66126 14.5548 3.84517 14.6996 4.00297 14.6112L6.90883 12.9835C7.59183 12.6009 8.40817 12.6009 9.09116 12.9835L11.997 14.6112C12.1548 14.6996 12.3387 14.5548 12.3054 14.3684L11.7092 11.0379C11.5496 10.1461 11.8586 9.23245 12.5158 8.65324L14.644 6.77751C14.7856 6.65266 14.7218 6.40835 14.5399 6.37912L11.6191 5.9098C10.9163 5.79689 10.2998 5.35062 9.94312 4.69667L8.18415 1.47185Z"
            fill="black"
        />
    </svg>
);

export default FavoriteInactive;
