import { Fragment, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import cx from 'classnames';
import Lobby from '../../svg-icons/navigation-icons/Lobby';
import LiveCasino from '../../svg-icons/navigation-icons/LiveCasino';
import Promotions from '../../svg-icons/navigation-icons/Promotions';
import Bonuses from '../../svg-icons/navigation-icons/Bonuses';
import VipProgram from '../../svg-icons/navigation-icons/VipProgram';
import Casino from '../../svg-icons/navigation-icons/Casino';
import BetRace from '../../svg-icons/navigation-icons/BetRace';
import ReferAFriend from '../../svg-icons/navigation-icons/ReferAFriend';
import Profile from '../../svg-icons/navigation-icons/Profile';
import LogOut from '../../svg-icons/navigation-icons/LogOut';
import WheelOfFortune from '../../svg-icons/navigation-icons/WheelOfFortune';
import FormLoader from '../FormLoader';
import { logoutUser } from '../../../store/reducers/user';
import { formatCurrency } from '../../../utils/formatCurrency';
import { getMenuUrl, getModalUrl, isActiveCasinoMenuItem, isActiveLiveCasinoMenuItem } from '../../../utils/locationConfig';
import { formatSeconds } from '../../../utils/commonConfig';
import { imageBaseUrl } from '../../../services/http';
import { MENU_LOGIN, MENU_REGISTER, MODAL_WALLET_PREVIEW, MODAL_WITHDRAW, WHEEL_ORIGIN_HAMBURGER } from '../../../variables';
import routes from '../../../utils/routes.json';
import { isMobile } from 'react-device-detect';
import omit from 'lodash/omit';
import { setWheelOrigin } from '../../../store/reducers/wheel-of-fortune';

const NavMenu = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const paths = useSelector((state) => state.global.paths);
    const isAuth = useSelector((state) => state.user.isAuth);
    const { balance } = useSelector((state) => state.bank.information);
    const { currency, featuresAccess } = useSelector((state) => state.user.information);
    const { firstDepositRemainingTime } = useSelector((state) => state.bank);
    const { isWofEnabled } = useSelector((state) => state.wheel_of_fortune);
    const dispatch = useDispatch();
    const router = useRouter();
    const pathname = `${router.pathname}/`;
    const isDataLoaded = currency && balance.total >= 0;
    const isReferralAllowed = isAuth && featuresAccess?.raf;
    const amounts = useMemo(() => {
        const loadingAmount = tr['global.loading'];
        if (isDataLoaded) {
            return {
                cashAmount: formatCurrency(balance.total - balance.bonus, currency),
                bonusAmount: formatCurrency(balance.bonus, currency)
            };
        }
        return {
            cashAmount: loadingAmount,
            bonusAmount: loadingAmount
        };
    }, [balance, currency]);

    return (
        <div className="nav-menu">
            {isAuth ? (
                !isDataLoaded ? (
                    <div className="menu--section amount-data">
                        <FormLoader />
                    </div>
                ) : firstDepositRemainingTime ? (
                    <Fragment>
                        <div className="menu--section amount-data">
                            <div className="first-deposit-info">
                                <div className="gift-icon">
                                    <img src={`${imageBaseUrl}/gift.png`} alt="gift" />
                                </div>
                                <div
                                    className="gift-text"
                                    dangerouslySetInnerHTML={{
                                        __html: tr['deposit.flow.first_deposit.tooltip'].replace(
                                            '##TIME##',
                                            `<span class="timer">${formatSeconds(firstDepositRemainingTime)}</span>`
                                        )
                                    }}
                                />
                            </div>
                            <Link {...getModalUrl('deposit', router)}>
                                <a className="btn btn-submit btn-modern" id="deposit_button">
                                    {tr['header.deposit']}
                                </a>
                            </Link>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="menu--section amount-data">
                            <div className="amount">{amounts.cashAmount}</div>
                            <div>
                                +{amounts.bonusAmount} {tr['header.bonus_money']}
                            </div>
                            <Link {...(isMobile ? getModalUrl(MODAL_WALLET_PREVIEW, router) : { href: routes.wallet })}>
                                <a className="btn btn-purple btn-modern" id="wallet-history-button">
                                    {tr['header.my_wallet']}
                                </a>
                            </Link>
                        </div>
                        <div className="menu--section container-btns">
                            <Link {...getModalUrl(MODAL_WITHDRAW, router)}>
                                <a className="btn btn-submit btn-modern" id="withdrawal_button">
                                    {tr['transaction.type.withdraw']}
                                </a>
                            </Link>
                            <Link {...getModalUrl('deposit', router)}>
                                <a className="btn btn-submit btn-modern" id="deposit_button">
                                    {tr['header.deposit']}
                                </a>
                            </Link>
                        </div>
                    </Fragment>
                )
            ) : (
                <div className="menu--section container-btns">
                    <Link {...getMenuUrl(MENU_LOGIN, router)}>
                        <a className="btn btn-purple btn-modern">{tr['header.login_form.login']}</a>
                    </Link>
                    <Link {...getMenuUrl(MENU_REGISTER, router)}>
                        <a className="btn btn-submit btn-modern">{tr['header.register']}</a>
                    </Link>
                </div>
            )}
            <ul
                className={cx('navigation-list', {
                    'navigation-list--auth': isAuth,
                    'navigation-list--referral': isReferralAllowed,
                    'navigation-list--wof': isWofEnabled
                })}
            >
                <li className="navigation-list-item">
                    <Link href={routes.home} as={paths[routes.home]}>
                        <a className={cx({ active: router.pathname === routes.home })}>
                            <Lobby />
                            <span>{tr['nav.lobby']}</span>
                        </a>
                    </Link>
                </li>
                <li className="navigation-list-item">
                    <Link href={routes.games_lobby} as={paths[routes.games_lobby]}>
                        <a className={cx({ active: isActiveCasinoMenuItem(pathname) })}>
                            <Casino />
                            <span>{tr['nav.casino']}</span>
                        </a>
                    </Link>
                </li>
                <li className="navigation-list-item">
                    <Link href={routes.live_casino} as={paths[routes.live_casino]}>
                        <a className={cx({ active: isActiveLiveCasinoMenuItem(pathname) })}>
                            <LiveCasino />
                            <span>{tr['nav.live_dealer']}</span>
                        </a>
                    </Link>
                </li>
                <li className="navigation-list-item">
                    <Link href={routes.promotions} as={paths[routes.promotions]}>
                        <a className={cx({ active: pathname === routes.promotions })}>
                            <Promotions />
                            <span>{tr['nav.promotions']}</span>
                        </a>
                    </Link>
                </li>
                {isAuth && (
                    <Fragment>
                        <li className="navigation-list-item">
                            <Link href={routes.bonuses} as={routes.bonuses}>
                                <a className={cx({ active: pathname === routes.bonuses })}>
                                    <Bonuses />
                                    <span>{tr['lobby.bonuses.title']}</span>
                                </a>
                            </Link>
                        </li>
                        {isWofEnabled && (
                            <li className="navigation-list-item">
                                <Link href={routes.wheel_of_fortune} as={routes.wheel_of_fortune}>
                                    <a
                                        className={cx({ active: pathname === routes.wheel_of_fortune })}
                                        onClick={() => dispatch(setWheelOrigin(WHEEL_ORIGIN_HAMBURGER))}
                                    >
                                        <WheelOfFortune />
                                        <span>{tr['nav.wild_fortunes']}</span>
                                    </a>
                                </Link>
                            </li>
                        )}
                    </Fragment>
                )}
                <li className="navigation-list-item">
                    <Link href={routes.bet_race} as={paths[routes.bet_race]}>
                        <a className={cx({ active: pathname === routes.bet_race })}>
                            <BetRace />
                            <span>{tr['nav.bet-race']}</span>
                        </a>
                    </Link>
                </li>
                <li className="navigation-list-item">
                    <Link href={routes.vip} as={paths[routes.vip]}>
                        <a className={cx({ active: pathname === routes.vip })}>
                            <VipProgram />
                            <span>{tr['nav.vip_program']}</span>
                        </a>
                    </Link>
                </li>
                {isReferralAllowed && (
                    <li className="navigation-list-item">
                        <Link href={routes.refer_a_friend} as={routes.refer_a_friend}>
                            <a className={cx({ active: pathname === routes.refer_a_friend })}>
                                <ReferAFriend />
                                <span>{tr['nav.refer_a_friend']}</span>
                            </a>
                        </Link>
                    </li>
                )}
                {isAuth && (
                    <li className="navigation-list-item">
                        <Link href={routes.account_profile} as={routes.account_profile}>
                            <a className={cx({ active: pathname === routes.account_profile })}>
                                <Profile />
                                <span>{tr['account.nav_profile']}</span>
                            </a>
                        </Link>
                    </li>
                )}
                {isAuth && (
                    <li className="navigation-list-item">
                        <a
                            onClick={() => {
                                router
                                    .replace({
                                        query: { ...omit(router.query, ['menu']) }
                                    })
                                    .then(() => dispatch(logoutUser()));
                            }}
                            data-testid="player_logout"
                            id="logout_btn"
                        >
                            <LogOut />
                            <span>{tr['header.logout']}</span>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default NavMenu;
