import { getCategoryName, getVendorName } from '@tlf-e/brand-utils';
import { getGamePath } from './locationConfig';

export const handleGamePlayEvent = (displayedGames, gamesData, router, categories, softwares, eventName, gameMode) => {
    return displayedGames?.map((game) => ({
        gameId: game?.id,
        name: game?.name,
        tableId: game?.tableHash ?? null,
        demoAllowed: !game?.isDemoRestricted,
        category: getCategoryName(categories, game),
        vendor: getVendorName(softwares, game),
        image: game?.metadata?.thumbnailBackground,
        realDemo: gameMode,
        launchUrl: `${window.location.origin}${getGamePath(game, gamesData, router.locale)}`
    }));
};
