import { useEffect, useState } from 'react';

const maxWheelSize = 350;

export const useWheelSize = (minWheelSize, minWindowWidth) => {
    const [wheelSize, setWheelSize] = useState(minWheelSize);

    useEffect(() => {
        setSize();
    }, []);

    const setSize = () => {
        const calculatedSize = (window.innerWidth * minWheelSize) / minWindowWidth;
        const newSize = calculatedSize > maxWheelSize ? maxWheelSize : calculatedSize;
        setWheelSize(newSize);
    };

    return wheelSize;
};
