const CloseGameIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
            d="M8.07025 1.54839C8.50158 1.54839 8.85124 1.20177 8.85124 0.774194C8.85124 0.346619 8.50158 -1.07697e-06 8.07025 -1.03926e-06L1.82231 -4.93051e-07C0.815878 -4.05065e-07 4.04444e-07 0.808777 4.91664e-07 1.80645L1.74942e-06 16.1935C1.83664e-06 17.1912 0.815882 18 1.82232 18L8.07025 18C8.50158 18 8.85124 17.6534 8.85124 17.2258C8.85124 16.7982 8.50158 16.4516 8.07025 16.4516L1.82232 16.4516C1.67854 16.4516 1.56199 16.3361 1.56199 16.1935L1.56199 1.80645C1.56199 1.66392 1.67854 1.54839 1.82231 1.54839L8.07025 1.54839Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.28192 6.55848C5.56303 6.55848 4.98027 7.13618 4.98027 7.84881L4.98027 10.1616C4.98027 10.8742 5.56304 11.4519 6.28192 11.4519L11.0851 11.4519C11.0919 11.5277 11.099 11.6035 11.1065 11.6792L11.1627 12.2531C11.2485 13.1285 12.1864 13.6528 12.9865 13.2727C14.6883 12.4642 16.2289 11.3579 17.5342 10.0069L17.6375 9.90001C18.1208 9.39971 18.1208 8.61071 17.6375 8.1104L17.5342 8.00349C16.2289 6.65251 14.6883 5.54619 12.9865 4.73774C12.1864 4.3576 11.2485 4.88189 11.1627 5.75736L11.1065 6.33117C11.099 6.40692 11.0919 6.48269 11.0851 6.55848L6.28192 6.55848ZM11.8092 8.10687C12.0866 8.10687 12.3318 7.96309 12.4705 7.7448C12.538 7.63849 12.5803 7.51451 12.5887 7.38087C12.6076 7.08061 12.6317 6.7806 12.6611 6.48096L12.6774 6.31441C13.7563 6.86556 14.7582 7.55192 15.6587 8.35596C15.8923 8.56456 16.1191 8.78108 16.3387 9.00521C15.2729 10.0933 14.0362 11.0019 12.6774 11.696L12.6611 11.5295C12.6317 11.2298 12.6076 10.9298 12.5887 10.6295C12.563 10.2215 12.2217 9.90353 11.8092 9.90353L6.54225 9.90353L6.54225 8.10687L11.8092 8.10687Z"
            fill="white"
        />
    </svg>
);

export default CloseGameIcon;
