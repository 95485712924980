import { default as MuiModal } from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';

const Modal = ({ children, className = '', transitionEffect = 'fade', ...props }) => {
    const timeout = 300;

    return (
        <MuiModal
            open={props.open}
            className={`modal modal_${transitionEffect} ${className}`}
            disableScrollLock
            slotProps={{
                backdrop: { className: 'modal-backdrop' }
            }}
            {...props}
        >
            {transitionEffect === 'slide' ? (
                <Slide in={props.open} direction="up" timeout={{ enter: timeout, exit: timeout }}>
                    {children}
                </Slide>
            ) : (
                <Fade in={props.open} timeout={{ enter: timeout, exit: timeout }}>
                    {children}
                </Fade>
            )}
        </MuiModal>
    );
};

export default Modal;
