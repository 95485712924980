import { useSelector } from 'react-redux';
import StatusModal from '../../StatusModal';
import { MODAL_STATUS_ERROR } from '../../../../variables';

const WheelErrorModal = ({ isOpen, setErrorModalOpened, spinTheWheelHandler }) => {
    const tr = useSelector((state) => state.global.data.translations);

    const onClose = () => setErrorModalOpened(false);

    const onRetry = () => {
        spinTheWheelHandler();
        onClose();
    };

    return (
        <StatusModal
            status={MODAL_STATUS_ERROR}
            isOpen={isOpen}
            onClose={onClose}
            title={tr['wof.wheel_error_modal.title']}
            modalClass="wheel-error-modal"
            content={<p dangerouslySetInnerHTML={{ __html: tr['wof.wheel_error_modal.content'] }} />}
        >
            <div className="container-btns">
                <button
                    type="button"
                    className="btn btn-cancel btn-large btn-modern"
                    data-testid="close_modal_btn"
                    onClick={onClose}
                >
                    {tr['wof.wheel_error_modal.do_it_later']}
                </button>
                <button
                    type="button"
                    className="btn btn-submit btn-large btn-modern"
                    data-testid="retry_spin_btn"
                    onClick={onRetry}
                >
                    {tr['wof.wheel_error_modal.retry']}
                </button>
            </div>
        </StatusModal>
    );
};

export default WheelErrorModal;
