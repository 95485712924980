import { useEffect, useState } from 'react';
import NotificationTimer from '../../NotificationTimer';

const NotificationContent = ({ children, autoHideCountdown = 0, onClose }) => {
    const [paymentInfo, setPaymentInfo] = useState({});

    useEffect(() => {
        const paymentInformation = JSON.parse(localStorage.getItem('paymentInformation')) || {};
        setPaymentInfo(paymentInformation);
        localStorage.removeItem('paymentInformation');
    }, []);

    return (
        <NotificationTimer autoHideCountdown={autoHideCountdown} onClose={onClose}>
            {({ timer }) => children({ timer, paymentInfo })}
        </NotificationTimer>
    );
};

export default NotificationContent;
