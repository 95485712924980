import { useSelector } from 'react-redux';
import MfaForm from './Fragments/MfaForm';
import LoginForm from './Fragments/LoginForm';

const Login = () => {
    const { mfaEnabled } = useSelector((state) => state.user);

    return mfaEnabled ? <MfaForm /> : <LoginForm />;
};

export default Login;
