const LogOut = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
        <path
            d="M8.07025 1.54839C8.50158 1.54839 8.85124 1.20177 8.85124 0.774194C8.85124 0.346618 8.50158 -1.07697e-06 8.07025 -1.03926e-06L1.82231 -4.93051e-07C0.815878 -4.05065e-07 5.7929e-07 0.808777 6.66509e-07 1.80645L1.74942e-06 14.1935C1.83664e-06 15.1912 0.81588 16 1.82232 16L8.07025 16C8.50158 16 8.85124 15.6534 8.85124 15.2258C8.85124 14.7982 8.50158 14.4516 8.07025 14.4516L1.82232 14.4516C1.67854 14.4516 1.56199 14.3361 1.56199 14.1935L1.56199 1.80645C1.56199 1.66393 1.67854 1.54839 1.82231 1.54839L8.07025 1.54839Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.28192 5.55848C5.56304 5.55848 4.98027 6.13618 4.98027 6.84881L4.98027 9.1616C4.98027 9.87422 5.56304 10.4519 6.28192 10.4519L11.0851 10.4519C11.0919 10.5277 11.099 10.6035 11.1065 10.6792L11.1627 11.2531C11.2485 12.1285 12.1864 12.6528 12.9865 12.2727C14.6883 11.4642 16.2289 10.3579 17.5342 9.00693L17.6375 8.90001C18.1208 8.39971 18.1208 7.61071 17.6375 7.1104L17.5342 7.00349C16.2289 5.65251 14.6883 4.54619 12.9865 3.73774C12.1864 3.3576 11.2485 3.88189 11.1627 4.75736L11.1065 5.33117C11.099 5.40692 11.0919 5.48269 11.0851 5.55848L6.28192 5.55848ZM11.8092 7.10687C12.0866 7.10687 12.3318 6.96309 12.4705 6.7448C12.538 6.63849 12.5803 6.51451 12.5887 6.38087C12.6076 6.08061 12.6317 5.7806 12.6611 5.48096L12.6774 5.31441C13.7563 5.86556 14.7582 6.55192 15.6587 7.35596C15.8923 7.56456 16.1191 7.78108 16.3387 8.00521C15.2729 9.09326 14.0362 10.0019 12.6774 10.696L12.6611 10.5295C12.6317 10.2298 12.6076 9.9298 12.5887 9.62953C12.563 9.22146 12.2217 8.90353 11.8092 8.90353L6.54225 8.90353L6.54225 7.10687L11.8092 7.10687Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default LogOut;
