import { getFavoriteList } from '../../store/reducers/games';
import { useFavoriteGamesList } from '../../hooks/useFavoriteGamesList';
import { useDispatch, useSelector } from 'react-redux';
import { DELETE, POST } from '../../variables';
import { useRouter } from 'next/router';
import routes from '../../utils/routes.json';
import { clearGameOverlays } from '../../store/reducers/user';
import { setMinimizedGameExpanded } from '../../store/reducers/global';

export const withGameActions = (PageComponent) => {
    const WithGameActions = ({ game, ...props }) => {
        const { gameRouterHistory, data: gamesData } = useSelector((state) => state.games);
        const { favoritesIds, liveFavoritesIds } = useFavoriteGamesList();
        const { softwares } = gamesData;
        const isFavorite = game?.uniqueTableId
            ? liveFavoritesIds.includes(game.uniqueTableId)
            : favoritesIds?.includes(game.id);
        const dispatch = useDispatch();
        const router = useRouter();

        // Detect if back url redirects to another game page so push user to home
        const handleClose = () => {
            if (gameRouterHistory) {
                const { pathname, query, asPath } = gameRouterHistory;
                const [softwareName] = asPath.split('/').slice(2, 3);
                const isGameBack = softwares.some((software) => software.slug === softwareName);
                isGameBack ? router.push(routes.home) : router.push({ pathname, query }, asPath);
            } else {
                router.push(routes.home);
            }
            dispatch(clearGameOverlays(null));
            dispatch(setMinimizedGameExpanded(false));
        };

        const handleFavorite = () => {
            const method = isFavorite ? DELETE : POST;
            dispatch(getFavoriteList(game.uniqueTableId || game.id, method));
        };

        return (
            <PageComponent
                {...props}
                handleClose={handleClose}
                handleFavorite={handleFavorite}
                isFavorite={isFavorite}
                game={game}
            />
        );
    };

    return WithGameActions;
};
