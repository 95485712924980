import { useSelector } from 'react-redux';

const RegistrationFromTitle = ({ currentStep, totalSteps }) => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <div className="form--title">
            <span>{tr['register.form.title']}</span>
            <span className="info">
                {tr['register.form.steps'].replace('##CURRENT_STEP##', currentStep).replace('##TOTAL_STEPS##', totalSteps)}
            </span>
        </div>
    );
};

export default RegistrationFromTitle;
