const TableGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
        <path
            d="M28.0964 3.11728C25.681 1.83327 23.0747 0.809361 20.4473 0.112189C18.8931 -0.30021 17.2467 0.449862 16.507 1.94289C16.1476 2.6684 15.7188 3.6621 15.2736 4.77768C15.6563 5.19832 15.9431 5.7146 16.0958 6.28989C16.6618 4.81374 17.2242 3.46928 17.6702 2.56905C18.1261 1.6489 19.1516 1.17478 20.1255 1.43319C22.6587 2.10536 25.1736 3.09341 27.5025 4.33137C28.3978 4.8073 28.8685 5.86923 28.6289 6.87434C27.9853 9.57432 25.8827 15.3951 24.6582 17.8668C24.2023 18.787 23.1768 19.2611 22.2029 19.0027C20.2129 18.4747 18.2342 17.7517 16.348 16.8658C16.322 17.3904 16.2902 17.8779 16.2528 18.3122C18.0744 19.1382 19.9722 19.8172 21.8811 20.3237C23.4354 20.7361 25.0817 19.986 25.8214 18.493C27.0925 15.9272 29.231 10.0069 29.8991 7.20416C30.2878 5.57327 29.5252 3.87681 28.0964 3.11728Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M18.5781 7.65169C18.9558 6.60598 20.0796 6.12807 21.0574 6.5122C21.5614 6.7102 21.9109 7.14471 22.1075 7.60651C22.5474 7.39478 23.0849 7.30874 23.5889 7.50675C24.5667 7.89088 25.1088 9.0233 24.7311 10.069C24.4705 10.7904 23.9444 11.2772 23.3562 11.6206C22.77 11.9629 22.0953 12.1784 21.4956 12.345L20.4608 12.6324C20.3475 12.6639 20.2304 12.6179 20.1652 12.5163L19.5695 11.5883C19.2242 11.0504 18.8536 10.4242 18.6296 9.76372C18.4048 9.10092 18.3175 8.37307 18.5781 7.65169Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.3101 5.37802C6.00452 5.06447 8.78957 5.06447 11.484 5.37802C13.0778 5.56349 14.3649 6.87068 14.5524 8.53979C14.8746 11.4083 14.8746 17.7346 14.5524 20.6031C14.3649 22.2722 13.0778 23.5794 11.484 23.7648C8.78956 24.0784 6.00452 24.0784 3.31009 23.7648C1.71626 23.5794 0.429157 22.2722 0.241663 20.6031C-0.0805545 17.7346 -0.0805544 11.4083 0.241663 8.53979C0.429157 6.87068 1.71626 5.56349 3.3101 5.37802ZM11.3386 6.73252C8.7408 6.43022 6.05329 6.43022 3.45549 6.73252C2.45678 6.84874 1.65736 7.66946 1.54181 8.69812C1.23141 11.4614 1.23141 17.6815 1.54181 20.4447C1.65736 21.4734 2.45678 22.2941 3.45549 22.4103C6.05329 22.7126 8.7408 22.7126 11.3386 22.4103C12.3373 22.2941 13.1367 21.4734 13.2523 20.4447C13.5627 17.6815 13.5627 11.4614 13.2523 8.69812C13.1367 7.66946 12.3373 6.84874 11.3386 6.73252Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M6.04444 11.1429C4.99961 11.1429 4.10947 12.0033 4.10947 13.1207C4.10947 13.8916 4.43723 14.5406 4.87162 15.0782C5.30452 15.614 5.86302 16.0637 6.36793 16.4401L7.23908 17.0895C7.33449 17.1606 7.4596 17.1606 7.555 17.0895L8.42616 16.4401C8.93106 16.0637 9.48957 15.614 9.92247 15.0782C10.3569 14.5406 10.6846 13.8916 10.6846 13.1207C10.6846 12.0033 9.79448 11.1429 8.74964 11.1429C8.21106 11.1429 7.73713 11.421 7.39704 11.7809C7.05695 11.421 6.58303 11.1429 6.04444 11.1429Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default TableGames;
