import CircularProgress from '@mui/material/CircularProgress';

const props = {
    thickness: 4,
    variant: 'determinate'
};

const WheelWidgetCountdown = ({ time, countdownPercentage = 0, size = 70 }) => {
    return (
        <div className="wheel-widget--countdown">
            <CircularProgress {...props} size={size} className="bottom" value={100} />
            <CircularProgress {...props} size={size} className="top" value={countdownPercentage} />
            <div className="timer">{time}</div>
        </div>
    );
};

export default WheelWidgetCountdown;
