import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';

const ActionsTooltip = ({ children, title, isOpen, animationCompleted }) => {
    return (
        <Tooltip
            TransitionComponent={animationCompleted ? Fade : Slide}
            TransitionProps={{ direction: 'right' }}
            placement="left"
            title={title}
            classes={{ tooltip: 'custom-tooltip' }}
            open={isOpen}
        >
            {children}
        </Tooltip>
    );
};

export default ActionsTooltip;
