import Spinner from '../../../Spinner';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { langToTTSLocale } from '../../../../utils/locationConfig';
import { useEffect, useState } from 'react';
import {
    combineRegistrationForm,
    initFinishPhoneVerification,
    initPhoneVerification,
    setOtpRetryTimeout,
    startPhoneVerificationNew
} from '../../../../store/reducers/register';
import dayjs from 'dayjs';

const PhoneNumberButtonComponent = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { values } = useFormikContext();
    const { submitStartPhoneVerification, phoneNumberFieldVerification, submitFinishPhoneVerification, otpRetryTimeout } =
        useSelector((state) => state.register);
    const isDisabled = !phoneNumberFieldVerification.isSuccess;
    const { isLoading, isSuccess } = submitStartPhoneVerification;
    const [animation, setAnimation] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const initialOtpCooldown = 60;
    const dispatch = useDispatch();
    const router = useRouter();
    const isCodeVerified = submitFinishPhoneVerification.isSuccess;
    const isPhoneVerificationPassed = submitStartPhoneVerification.isVerified || isCodeVerified;

    const onSendCode = () => {
        dispatch(combineRegistrationForm({ ...values }));
        dispatch(
            startPhoneVerificationNew({
                prefix: `${values.countryPhoneCodePrefix}`,
                number: `${values.phone}`,
                locale: langToTTSLocale(router.locale)
            })
        );
        dispatch(initFinishPhoneVerification());
        dispatch(setOtpRetryTimeout(dayjs().format('YYYY-MM-DD HH:mm:ss')));
    };

    useEffect(() => {
        if (otpRetryTimeout) {
            const diff = dayjs().diff(otpRetryTimeout, 'second');
            if (diff > initialOtpCooldown) {
                dispatch(setOtpRetryTimeout(''));
            } else {
                setCountdown(initialOtpCooldown - diff);
            }
        }
    }, [otpRetryTimeout]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else {
            clearInterval(timer);
        }

        return () => {
            if (timer) clearInterval(timer);
        };
    }, [countdown]);

    useEffect(() => {
        if (isLoading) {
            setAnimation(true);
            setTimeout(() => {
                setAnimation(false);
                dispatch(initPhoneVerification());
            }, 2000);
        }

        return () => {
            clearTimeout();
        };
    }, [isLoading]);

    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    return (
        <button
            type="button"
            className="btn btn-submit btn-modern btn-send-code"
            data-testid="register_send_code"
            disabled={isLoading || isDisabled || animation || isPhoneVerificationPassed || countdown > 0}
            onClick={onSendCode}
        >
            {isLoading ? (
                <div className="spinner-container show">
                    <Spinner />
                </div>
            ) : isSuccess ? (
                <div className="done-text show">{tr['code_send_btn']}</div>
            ) : countdown > 0 ? (
                <div className="text move-up">{formatCountdown(countdown)}</div>
            ) : (
                <div className="text move-up">{tr['register_send_code.btn']}</div>
            )}
        </button>
    );
};

export default PhoneNumberButtonComponent;
