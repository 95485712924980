const WinValuePoints = ({ value }) => {
    /**
     * THE MOST SUPPORTABLE SOLUTION
     * background-clip: text is not supported by some iOS
     */

    return (
        <svg className="prize" width="140" height="140">
            {/*Circle*/}
            <circle cx="70" cy="70" r="68" fill="#ffffff" stroke="#8338EC" strokeWidth="1" />

            {/*SVG Content (Text with Gradient)*/}
            <svg x="0" y="40" width="140" height="60">
                {/*Define the linear gradient*/}
                <defs>
                    <linearGradient id="gradient" gradientTransform="rotate(99)">
                        <stop offset="0%" stopColor="#FF006E" />
                        <stop offset="100%" stopColor="#3A86FF" />
                    </linearGradient>
                </defs>
                {/*Apply the gradient to the text*/}
                <text
                    x="50%"
                    y="50%"
                    fontSize="48"
                    fontFamily="Arial"
                    fontWeight="bold"
                    fill="url(#gradient)"
                    textAnchor="middle"
                    dy=".35em"
                >
                    {value}
                </text>
            </svg>
        </svg>
    );
};

export default WinValuePoints;
