import React, { Fragment } from 'react';
import { GLOBAL_ERROR_MESSAGE_KEY } from '../../../../../../variables';
import { useSelector } from 'react-redux';

const ErrorGame = ({ id = '', isErrorPage, gameName }) => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <div id={id} className="page-container error-page-play">
            <div className="content-box">
                {isErrorPage ? (
                    <Fragment>
                        <h1>{tr['game.not_found.title']}</h1>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: tr['game.not_found.message'].replace('%game_name%', `<strong>${gameName}</strong>`)
                            }}
                        />
                    </Fragment>
                ) : (
                    <p>{localStorage.getItem(GLOBAL_ERROR_MESSAGE_KEY)}</p>
                )}
            </div>
        </div>
    );
};

export default ErrorGame;
