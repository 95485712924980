import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGameRouterHistory } from '../../../store/reducers/games';
import MinimizedGameComponent from './fragments/MinimizedGameComponent';
import cx from 'classnames';

const OverlayComponent = ({ children, game = {} }) => {
    const { isGameOverlayMinimized, desktopHeaderHeight, isMinimizedGameExpanded } = useSelector((state) => state.global);
    const dispatch = useDispatch();
    const [dimensions, setDimensions] = useState({ height: '400px', width: '335px' });

    useEffect(() => {
        const updateDimensions = () => {
            const widthPercentage = isMinimizedGameExpanded ? 0.4 : 0.3;
            const heightPercentage = 0.7;
            const width = window.innerWidth * widthPercentage;
            const height = width * heightPercentage;
            setDimensions({ height: `${height}px`, width: `${width}px` });
        };

        updateDimensions(); // Set dimensions initially
        window.addEventListener('resize', updateDimensions); // Update dimensions on window resize

        return () => {
            window.removeEventListener('resize', updateDimensions);
            dispatch(setGameRouterHistory(null));
        };
    }, [isMinimizedGameExpanded]);

    return (
        <div
            className={cx('overlay', { 'overlay-minimized': isGameOverlayMinimized })}
            style={{
                height: isGameOverlayMinimized ? dimensions.height : `calc(100% - ${desktopHeaderHeight}px)`,
                width: isGameOverlayMinimized && dimensions.width
            }}
        >
            <div className="overlay-component">
                {isGameOverlayMinimized && <MinimizedGameComponent game={game} />}
                {children}
            </div>
        </div>
    );
};

export default OverlayComponent;
