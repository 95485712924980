const Expand = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15.5" stroke="white" />
        <path
            d="M14.7788 18.2468L11.5837 21.4979H14.0577C14.4961 21.4979 14.8406 21.8283 14.8406 22.2489C14.8406 22.6695 14.4961 23 14.0577 23H9.76727C9.76727 23 9.68897 22.985 9.642 22.97C9.57937 22.97 9.53239 22.97 9.48541 22.9399C9.42278 22.9099 9.36015 22.8798 9.31317 22.8348C9.28185 22.8197 9.25054 22.8047 9.23488 22.7897C9.1879 22.7446 9.14093 22.6695 9.10961 22.6094C9.10961 22.5944 9.07829 22.5644 9.06263 22.5494C9.06263 22.5193 9.06263 22.4742 9.03132 22.4442C9.01566 22.3841 9 22.324 9 22.2639L9 18.1481C9 17.7275 9.34449 17.397 9.78293 17.397C10.2214 17.397 10.5659 17.7275 10.5659 18.1481L10.5659 20.3863L13.667 17.2403C13.8236 17.0901 14.0272 17 14.2307 17C14.4186 17 14.6222 17.0751 14.7631 17.2103C15.0763 17.4957 15.0763 17.9464 14.7788 18.2468Z"
            fill="white"
        />
        <path
            d="M17.2212 13.7532L20.4163 10.5021H17.9423C17.5039 10.5021 17.1594 10.1717 17.1594 9.75107C17.1594 9.33047 17.5039 9 17.9423 9H22.2327C22.2327 9 22.311 9.01502 22.358 9.03004C22.4206 9.03004 22.4676 9.03004 22.5146 9.06009C22.5772 9.09013 22.6399 9.12017 22.6868 9.16524C22.7181 9.18026 22.7495 9.19528 22.7651 9.2103C22.8121 9.25536 22.8591 9.33047 22.8904 9.39056C22.8904 9.40558 22.9217 9.43562 22.9374 9.45064C22.9374 9.48069 22.9374 9.52575 22.9687 9.55579C22.9843 9.61588 23 9.67597 23 9.73605V13.8519C23 14.2725 22.6555 14.603 22.2171 14.603C21.7786 14.603 21.4341 14.2725 21.4341 13.8519V11.6137L18.333 14.7597C18.1764 14.9099 17.9728 15 17.7693 15C17.5814 15 17.3778 14.9249 17.2369 14.7897C16.9237 14.5043 16.9237 14.0536 17.2212 13.7532Z"
            fill="white"
        />
    </svg>
);

export default Expand;
