import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import cx from 'classnames';
import { forgotPassword, forgotPasswordReset } from '../../../store/reducers/user';
import FormErrorMessage from '../FormErrorMessage';
import { getMenuUrl } from '../../../utils/locationConfig';
import { MENU_LOGIN, MINUTE_IN_SECONDS, ONE_SECOND } from '../../../variables';
import { dataLayerPush, getCookie } from '@tlf-e/brand-utils';
import { setDomainCookie, clearDomainCookie } from '../../../utils/cookieConfig';

const ForgotPassword = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { submitForgotPassword, forgotPasswordForm } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const router = useRouter();
    const ref = useRef();
    const timerRef = useRef();
    const [isDisabled, setIsDisabled] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null);

    const validationSchema = yup.object({
        email: yup.string().required(tr['login_form.error.fill_error']).email(tr['login_form.error.incorrect_error'])
    });

    useEffect(() => {
        const disabledBtn = getCookie('disabledBtn');
        if (disabledBtn) {
            startCountdown(disabledBtn);
        } else {
            dataLayerPush({ event: 'Login_LostMyPassword' });
        }
        dispatch(forgotPasswordReset());

        return () => clearInterval(timerRef.current);
    }, []);

    useEffect(() => {
        ref.current.reset();
    }, [submitForgotPassword.isSuccess]);

    const startCountdown = (disabledBtn) => {
        clearInterval(timerRef.current);
        const updateCountdown = () => {
            const now = new Date().getTime();
            const timeLeft = new Date(disabledBtn).getTime() - now;
            if (timeLeft > 0) {
                setIsDisabled(true);
                setTimeLeft(timeLeft);
            } else {
                clearDomainCookie('disabledBtn');
                clearInterval(timerRef.current);
                setIsDisabled(false);
                setTimeLeft(null);
            }
        };

        updateCountdown();
        timerRef.current = setInterval(updateCountdown, ONE_SECOND);
    };

    const handleSubmit = (values) => {
        const disabledUntil = new Date(new Date().getTime() + 5 * MINUTE_IN_SECONDS);
        setDomainCookie('disabledBtn', disabledUntil, { expires: 1 / 288 });
        startCountdown(disabledUntil);
        dispatch(forgotPassword(values));
    };

    const formatTimeLeft = () => {
        const minutes = Math.floor(timeLeft / MINUTE_IN_SECONDS);
        const seconds = Math.floor((timeLeft % MINUTE_IN_SECONDS) / ONE_SECOND);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <Formik
            initialValues={forgotPasswordForm}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({ touched, errors }) => (
                <div className="form-container">
                    <div className="form--title">{tr['header.login_form.forgot_password']}</div>
                    <div className="form--subtitle" dangerouslySetInnerHTML={{ __html: tr['forgot_password.info'] }}></div>
                    <Form ref={ref} id="login_form">
                        {submitForgotPassword.isError && (
                            <div className="page-message page-message--error">{tr[`forgot_password.from.error`]}</div>
                        )}
                        <div
                            className={cx('form-group', {
                                'form-group--error-marked': touched.email && errors.email
                            })}
                        >
                            <label className="section-title" htmlFor="email">
                                {tr['header.login_form.email']}
                            </label>
                            <Field name="email" autocomplete="off">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="email"
                                        id="email"
                                        className="text-field"
                                        data-testid="user_email"
                                    />
                                )}
                            </Field>
                            <FormErrorMessage name="email" />
                        </div>
                        <div className="form--btns">
                            <Link {...getMenuUrl(MENU_LOGIN, router)}>
                                <a className="btn btn-cancel btn-modern">{tr['header.login']}</a>
                            </Link>
                            <button
                                type="submit"
                                data-testid="submit_forgot_password"
                                disabled={submitForgotPassword.isLoading || isDisabled}
                                className={cx('btn btn-submit btn-modern')}
                            >
                                {submitForgotPassword.isLoading
                                    ? tr['global.loading']
                                    : isDisabled
                                        ? formatTimeLeft()
                                        : tr['forgot_password.btn']}
                            </button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default ForgotPassword;
