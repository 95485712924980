import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

// 100% of progressbar in milliseconds
const fullTime = 60 * 60 * 1000;
const updateCountdown = (time) => ({
    countdown: time,
    countdownPercentage: Math.ceil((time / fullTime) * 100)
});

export const useWidgetTimer = (timeToSpinTimestamp = 0) => {
    const [state, setState] = useState(updateCountdown(0));

    useEffect(() => {
        if (timeToSpinTimestamp > 0) {
            setState(updateCountdown(timeToSpinTimestamp));
            const interval = setInterval(
                () =>
                    setState((state) => {
                        const time = state.countdown - 1000;
                        if (time === 0) {
                            clearInterval(interval);
                        }
                        return updateCountdown(time);
                    }),
                1000
            );
            return () => clearInterval(interval);
        }
    }, [timeToSpinTimestamp]);

    const getWheelTimer = (milliseconds) => {
        const durationObj = dayjs.duration(milliseconds);
        const minutes = String(durationObj.minutes()).padStart(2, '0');
        const seconds = String(durationObj.seconds()).padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return {
        time: getWheelTimer(state.countdown),
        countdownPercentage: state.countdownPercentage
    };
};
