import { useDispatch, useSelector } from 'react-redux';
import StatusModal from '../../StatusModal';
import { MODAL_STATUS_SUCCESS } from '../../../../variables';
import { forgotPasswordReset } from '../../../../store/reducers/user';

const ForgotPasswordResetSuccessModal = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { submitForgotPassword } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(forgotPasswordReset());
    };

    return (
        <StatusModal
            isOpen={submitForgotPassword.isSuccess}
            status={MODAL_STATUS_SUCCESS}
            title={tr['modal.well_done']}
            onClose={onClose}
            content={
                <div className="wdr-success-content">
                    <div>
                        <div>{tr['forgot_password.reset.success.text_1']}</div>
                        <div>{tr['forgot_password.reset.success.text_2']}</div>
                    </div>
                </div>
            }
        >
            <div className="container-btns">
                <button className="btn btn-submit btn-large btn-modern" data-testid="continue_playing" onClick={onClose}>
                    {tr['btn.got_it']}
                </button>
            </div>
        </StatusModal>
    );
};

export default ForgotPasswordResetSuccessModal;
