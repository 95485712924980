const FavoriteGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0664 1.18466C10.9279 -0.394888 13.0721 -0.394884 13.9336 1.18466L16.5721 6.0219C16.8153 6.46778 17.2356 6.77205 17.7148 6.84903L22.0961 7.55302C24.0059 7.85988 24.6758 10.4251 23.1884 11.736L19.9962 14.5496C19.5481 14.9445 19.3374 15.5675 19.4463 16.1756L20.3405 21.1713C20.6907 23.1282 18.7597 24.6487 17.1028 23.7206L12.744 21.279C12.2783 21.0181 11.7217 21.0181 11.256 21.279L6.89722 23.7205C5.24032 24.6487 3.30925 23.1282 3.65953 21.1713L4.55374 16.1756C4.66259 15.5675 4.45191 14.9445 4.00384 14.5496L0.811548 11.736C-0.675799 10.4251 -0.00588529 7.85988 1.90394 7.55301L6.28524 6.84903C6.76437 6.77205 7.18472 6.46778 7.42792 6.0219L10.0664 1.18466ZM12.2762 2.20777C12.1532 1.98212 11.8468 1.98212 11.7238 2.20777L9.08532 7.04501C8.55029 8.02593 7.62551 8.69533 6.57141 8.8647L2.19011 9.56868C1.91728 9.61252 1.82158 9.97899 2.03406 10.1663L5.22635 12.9799C6.21209 13.8487 6.67559 15.2191 6.43614 16.5569L5.54192 21.5526C5.49189 21.8322 5.76775 22.0494 6.00445 21.9168L10.3633 19.4752C11.3877 18.9013 12.6123 18.9013 13.6367 19.4752L17.9955 21.9168C18.2323 22.0494 18.5081 21.8322 18.4581 21.5526L17.5639 16.5569C17.3244 15.2191 17.7879 13.8487 18.7736 12.9799L21.9659 10.1663C22.1784 9.97898 22.0827 9.61252 21.8099 9.56868L17.4286 8.8647C16.3745 8.69533 15.4497 8.02593 14.9147 7.04501L12.2762 2.20777Z"
            fill="black"
        />
    </svg>
);

export default FavoriteGames;
