import { useDispatch, useSelector } from 'react-redux';
import { useFavoriteGamesList } from '../../../../../../../hooks/useFavoriteGamesList';
import { getCategoryGames } from '../../../../../../../utils/games';
import { withGameActions } from '../../../../../../hoc/withGameActions';
import { dataLayerPush } from '@tlf-e/brand-utils';
import { getGamePath } from '../../../../../../../utils/locationConfig';
import { useRouter } from 'next/router';
import {
    setFavoriteGameMethod,
    setGameSlider,
    setIsFavoriteGameModalOpened,
    setNewFavoriteGame
} from '../../../../../../../store/reducers/games';
import {
    GAME_INTERFACE_FAVORITE_ADD,
    GAME_INTERFACE_FAVORITE_REMOVE,
    GAME_INTERFACE_FAVOTIRE_CLICK_GAME,
    GAME_ROUTER_PATHNAME,
    DELETE,
    POST,
    MODAL_STATUS_ERROR
} from '../../../../../../../variables';
import FavoriteGameItem from './FavoriteGameItem';
import Link from 'next/link';
import AnimatedStatus from '../../../../../../common/AnimatedStatus';
import { isMobile } from 'react-device-detect';

const FavoritePanelBody = ({ game, handleFavorite, isFavorite }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const router = useRouter();
    const dispatch = useDispatch();
    const gamesData = useSelector((state) => state.games.data);
    const favoriteGamesLoading = useSelector((state) => state.games.favoriteGamesLoading);
    const { liveFavorites, favorites } = useFavoriteGamesList();
    const defaultGames = getCategoryGames(favorites);
    const defaultLiveGames = getCategoryGames(liveFavorites);
    const filteredLiveFavoriteGames = defaultLiveGames.filter(
        (favoriteGame) => favoriteGame.uniqueTableId !== game?.uniqueTableId
    );
    const allFilteredFavoriteGames = [...defaultGames, ...filteredLiveFavoriteGames];
    const hasOtherFavorite = allFilteredFavoriteGames.length > 0;
    const url = GAME_ROUTER_PATHNAME;

    const gameImpressions = (event, game, extraFields = {}) => {
        dataLayerPush({
            event,
            game,
            ...extraFields
        });
    };

    const handleFavoritePanel = (item) => {
        gameImpressions(GAME_INTERFACE_FAVOTIRE_CLICK_GAME, game.name, { game_clicked: item.name });
        dispatch(setIsFavoriteGameModalOpened(false));
        isMobile && dispatch(setGameSlider(false));
    };

    const handleFavoriteClick = () => {
        handleFavorite();
        const method = isFavorite ? DELETE : POST;
        const event = isFavorite ? GAME_INTERFACE_FAVORITE_REMOVE : GAME_INTERFACE_FAVORITE_ADD;
        dispatch(setFavoriteGameMethod(method));
        dispatch(setIsFavoriteGameModalOpened(false));
        dispatch(setNewFavoriteGame(game));
        gameImpressions(event, game.name);
    };

    return (
        <div className="favorite-panel_body">
            <FavoriteGameItem game={game} showVolatility />
            <div className="add-to-favorites">
                <button
                    className="btn btn-submit btn-modern"
                    data-testid="add_to_favorites"
                    disabled={favoriteGamesLoading}
                    onClick={handleFavoriteClick}
                >
                    <span>{isFavorite ? tr['favorite.modal.remove.favorite'] : tr['favorite.modal.add.favorite']}</span>
                </button>
            </div>
            <div className="favorite-games-list">
                <div className="favorite-games-list_header">
                    <h4>{tr['favorite.modal.your.favorite.games']}</h4>
                </div>
                {hasOtherFavorite ? (
                    <div className="favorite-games-list_body">
                        {allFilteredFavoriteGames.map((game, index) => {
                            const gameAsPath = getGamePath(game, gamesData, router.locale);
                            return (
                                <div
                                    className="favorite-game-item"
                                    key={index}
                                    data-testid={`favorite_game_item_${index}`}
                                    onClick={() => handleFavoritePanel(game)}
                                >
                                    <Link href={url} as={gameAsPath}>
                                        <a className="game-list-item-favorite--link">
                                            <FavoriteGameItem game={game} />
                                        </a>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="favorite-games-list_no-games">
                        <AnimatedStatus status={MODAL_STATUS_ERROR} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default withGameActions(FavoritePanelBody);
