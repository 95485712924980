const Bonuses = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
        <path
            fillOpacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.42857 3.71009C3.42857 1.66106 5.09151 0 7.14286 0C8.29168 0 9.31869 0.520973 10 1.3393C10.6813 0.520973 11.7083 0 12.8571 0C14.9085 0 16.5714 1.66106 16.5714 3.71009C16.5714 4.44562 16.3571 5.13116 15.9875 5.70782H16.8571C18.5929 5.70782 20 7.11334 20 8.84713V11.1303C20 12.0483 19.3801 12.8216 18.5357 13.0555C18.7003 15.1407 18.6049 17.2395 18.2508 19.3035C18.0394 20.536 17.0373 21.4802 15.7931 21.6191L14.748 21.7358C11.5925 22.0881 8.4075 22.0881 5.25198 21.7358L4.20687 21.6191C2.96273 21.4802 1.9606 20.536 1.74916 19.3035C1.39507 17.2395 1.29971 15.1407 1.46435 13.0555C0.619884 12.8216 0 12.0483 0 11.1303V8.84713C0 7.11334 1.40711 5.70782 3.14286 5.70782H4.01247C3.64286 5.13116 3.42857 4.44562 3.42857 3.71009ZM9.14286 3.71009C9.14286 2.60677 8.24743 1.71235 7.14286 1.71235C6.03829 1.71235 5.14286 2.60677 5.14286 3.71009C5.14286 4.81341 6.03829 5.70782 7.14286 5.70782C8.24743 5.70782 9.14286 4.81341 9.14286 3.71009ZM12.8571 5.70782C13.9617 5.70782 14.8571 4.81341 14.8571 3.71009C14.8571 2.60677 13.9617 1.71235 12.8571 1.71235C11.7526 1.71235 10.8571 2.60677 10.8571 3.71009C10.8571 4.81341 11.7526 5.70782 12.8571 5.70782ZM1.71429 8.84713C1.71429 8.05904 2.35388 7.42017 3.14286 7.42017H9.14286V11.4156H2C1.8422 11.4156 1.71429 11.2879 1.71429 11.1303V8.84713ZM10.8571 13.128H16.8217C16.982 15.0922 16.8948 17.0698 16.5612 19.0143C16.4787 19.495 16.0879 19.8632 15.6027 19.9174L14.5576 20.034C13.3275 20.1714 12.0928 20.2529 10.8571 20.2787V13.128ZM10.8571 11.4156H18C18.1578 11.4156 18.2857 11.2879 18.2857 11.1303V8.84713C18.2857 8.05904 17.6461 7.42017 16.8571 7.42017H10.8571V11.4156ZM9.14286 13.128V20.2787C7.90723 20.2529 6.67248 20.1714 5.44239 20.034L4.39728 19.9174C3.91209 19.8632 3.52128 19.495 3.43882 19.0143C3.10523 17.0698 3.01798 15.0922 3.17832 13.128H9.14286Z"
            fill="white"
        />
    </svg>
);

export default Bonuses;
