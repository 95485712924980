import { useEffect, useRef, useState } from 'react';
import Script from 'next/script';

const EvolutionGame = ({ iframeSrc, gameLaunchScripts, isMobile, dimensions, topHeight, offsetLeft }) => {
    const containerIframeRef = useRef();
    const [iframeId] = useState(`evolution-script-${Math.floor(Math.random() * 1000)}`);

    useEffect(() => {
        resizeGeneratedIframe();
    });

    useEffect(() => {
        resizeGeneratedIframe();
    }, [offsetLeft]);

    useEffect(() => {
        if (isMobile) {
            document.body.style.overflow = 'visible';
            document.body.style.background = '#000';
        }
        return () => {
            const evoIframe = document.getElementById('iframe');
            const evoScript = document.getElementById(iframeId);
            evoIframe && evoIframe.remove();
            evoScript && evoScript.remove();
            if (isMobile) {
                document.body.style.overflow = '';
                document.body.style.background = '';
                document.body.style.height = '';
            }
        };
    }, []);

    const resizeGeneratedIframe = () => {
        const evoIframe = document.getElementById('iframe');
        if (evoIframe) {
            evoIframe.style.position = 'absolute';
            evoIframe.style.border = 'none';
            if (!isMobile) {
                evoIframe.style.maxWidth = `${dimensions.width}px`;
                evoIframe.style.maxHeight = `${dimensions.height}px`;
                evoIframe.style.top = `${topHeight}px`;
                evoIframe.style.left = `${offsetLeft}px`;
                document.body.style.height = '';
            }
        }
    };

    return (
        <div id="game" ref={containerIframeRef}>
            <Script
                id={iframeId}
                src={iframeSrc}
                onLoad={async () => {
                    await Function(gameLaunchScripts[1]?.replace('<script>', '').replace('</script>', ''))();
                    resizeGeneratedIframe();
                }}
            />
        </div>
    );
};

export default EvolutionGame;
