import { useEffect, useState } from 'react';

export const useTomHornGame = (containerIframeRef, initializeGameLaunchContent, dimensions, isMobile) => {
    const [gameContainerId] = useState(`softgaming-container-${Math.floor(Math.random() * 1000)}`);

    useEffect(() => {
        resizeGeneratedIframe();
        const observer = new MutationObserver(resizeGeneratedIframe);
        observer.observe(containerIframeRef.current, { childList: true, subtree: true });
        return () => {
            observer.disconnect();
        };
    }, [dimensions]);

    useEffect(() => {
        return () => {
            initializeGameLaunchContent();
            const iframe = document.getElementById('egamings_container');
            const script = document.getElementById(gameContainerId);
            iframe && iframe.remove();
            script && script.remove();
        };
    }, []);

    const resizeGeneratedIframe = () => {
        const egamingIframe = document.getElementById('egamings_container');
        if (egamingIframe) {
            egamingIframe.style.position = 'absolute';
            egamingIframe.style.border = 'none';
            if (!isMobile) {
                egamingIframe.style.maxWidth = `${dimensions.width}px`;
                egamingIframe.style.maxHeight = `${dimensions.height}px`;
                egamingIframe.style.top = '0';
                egamingIframe.style.left = '0';
                egamingIframe.style.width = '100%';
                egamingIframe.style.height = '100%';
                document.body.style.height = '';
            }
        }
    };

    return [gameContainerId];
};
