import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { CURRENT_DEVICE_MOBILE } from '../../../../variables';
import { initStartPhoneVerification } from '../../../../store/reducers/register';
import cx from 'classnames';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import FormErrorMessage from '../../FormErrorMessage';
import { withRegisterHelper } from '../../../hoc/withRegisterHelper';
import PhoneNumberButtonComponent from './PhoneNumberButtonComponent';
import { dataLayerPushField, allowStartPlusOnlyOnPhoneNumber, cleanNonDigitsFromString } from '@tlf-e/brand-utils';

const Option = ({ children, ...props }) => <option {...props}>{children}</option>;

const PhoneNumber = ({ onChangePhoneNumber, phoneCodeOptions, validatePhoneNumber, isFrance }) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const { phoneNumberFieldVerification } = useSelector((state) => state.register);
    const device = useSelector((state) => state.global.currentDevice);
    const tr = useSelector((state) => state.global.data.translations);
    const [blurredValue, setBlurredValue] = useState('');
    const isMobile = device === CURRENT_DEVICE_MOBILE;
    const SelectOption = isMobile ? Option : MenuItem;
    const dispatch = useDispatch();

    useEffect(() => {
        if (blurredValue && blurredValue === phoneNumberFieldVerification.value) {
            dataLayerPushField({
                event: 'signup_filled_field',
                field: 'phonenumber',
                input: phoneNumberFieldVerification.value,
                valid: !phoneNumberFieldVerification.error,
                error: phoneNumberFieldVerification.error
            });
        }
    }, [blurredValue, phoneNumberFieldVerification.value]);

    return (
        <div className="form-group">
            <div
                className={cx('form-group', {
                    'form-group--error-marked': (touched.phone && errors.phone) || phoneNumberFieldVerification.error,
                    'form-group--loading': phoneNumberFieldVerification.isLoading
                })}
            >
                <label className="section-title" htmlFor="register_phone_number">
                    {tr['register.phone_number']}
                </label>
                {phoneNumberFieldVerification.isLoading && <div className="loader" />}
                <div className={cx('d-flex', { register_mobile: isMobile })}>
                    <div className="d-flex phone-number">
                        {!isFrance && (
                            <Field name="countryPhoneCodePrefix">
                                {({ field }) => (
                                    <div className="select-wrapper phone-code-selector">
                                        <Select
                                            {...field}
                                            native={isMobile}
                                            inputProps={{
                                                'data-testid': 'register_phone_number_prefix'
                                            }}
                                            onChange={(e) => {
                                                const prefix = e.target.value;
                                                dispatch(initStartPhoneVerification());
                                                setBlurredValue(`+${prefix}${values.phone}`);
                                                onChangePhoneNumber(values.phone, prefix, () =>
                                                    setFieldValue('countryPhoneCodePrefix', prefix)
                                                );
                                            }}
                                        >
                                            {phoneCodeOptions
                                                .concat()
                                                .sort((a, b) => parseInt(a.dial_code) - parseInt(b.dial_code))
                                                .map((option) => (
                                                    <SelectOption key={option.code} value={option.dial_code}>
                                                        {`+${option.dial_code}`}
                                                    </SelectOption>
                                                ))}
                                        </Select>
                                    </div>
                                )}
                            </Field>
                        )}
                        <Field name="phone" validate={validatePhoneNumber}>
                            {({ field, form }) => (
                                <input
                                    {...field}
                                    type="tel"
                                    id="register_phone_number"
                                    data-dd-privacy="allow"
                                    data-testid="register_phone_number_value"
                                    autoComplete="off"
                                    autoCapitalize="off"
                                    className="text-field"
                                    placeholder={isFrance ? tr['register.form.phone_number.france.placeholder'] : ''}
                                    onBlur={(e) => {
                                        form.setFieldTouched('phone');
                                        setBlurredValue(`+${values.countryPhoneCodePrefix}${e.target.value}`);
                                    }}
                                    onChange={(e) => {
                                        const cleanedValue = isFrance
                                            ? allowStartPlusOnlyOnPhoneNumber(e.target.value)
                                            : cleanNonDigitsFromString(e.target.value);

                                        onChangePhoneNumber(cleanedValue, values.countryPhoneCodePrefix, () =>
                                            setFieldValue('phone', cleanedValue)
                                        );
                                    }}
                                    onKeyDown={(e) => {
                                        // allow + only on france because we hide the country code dropdown
                                        // to avoid confusion of users
                                        if (e.key === '+' && !isFrance) {
                                            e.preventDefault();
                                        }

                                        if (e.key === '-' || e.key === 'e') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            )}
                        </Field>
                    </div>
                    <PhoneNumberButtonComponent />
                </div>
                <FormErrorMessage name="phone" />
                {phoneNumberFieldVerification.error && !errors.phone && (
                    <span className="form-message form-message--error">{phoneNumberFieldVerification.error}</span>
                )}
                <p className="info">{tr['register.form.field.phone_number.info']}</p>
            </div>
        </div>
    );
};

export default withRegisterHelper(PhoneNumber);
