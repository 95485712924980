import Root from '../components/Root';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/fi';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import { datadogInit } from '../utils/datadogInit';
import { customScrollbarStyles } from '../utils/customScrollbarStyles';
import { SSRContext, ssrContextDefault } from '../hooks/useSSRContext';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material';
import Overlay from '../components/Overlay';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Localize dayjs
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

if (isMobile) {
    require('../scss/mobile-style.scss');
} else {
    require('../scss/style.scss');
}

const theme = createTheme();

const MyApp = ({ Component, pageProps = {} }) => {
    const props = {
        ...ssrContextDefault,
        ...pageProps
    };

    useEffect(() => {
        datadogInit(props.device?.getUA);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <style jsx="true">{customScrollbarStyles}</style>
                <SSRContext.Provider value={props}>
                    <Root {...props}>
                        {props.device?.isDesktop ? (
                            <Overlay {...props}>
                                <Component {...props} />
                            </Overlay>
                        ) : (
                            <Component {...props} />
                        )}
                    </Root>
                </SSRContext.Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default MyApp;
