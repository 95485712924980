import Wheel from '../../../common/wheel-of-fortune/fragments/Wheel';
import { useWheelSize } from '../../../../hooks/useWheelSize';

const AdjustedWheelMobile = ({ place }) => {
    const wheelSize = useWheelSize(120, 320);

    return <Wheel size={wheelSize} place={place} />;
};

export default AdjustedWheelMobile;
