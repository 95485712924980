const Maximized = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <circle cx="24" cy="24" r="23.5" stroke="white" />
        <path
            d="M27.9257 12H16.6457C14.0743 12 12 14.0743 12 16.6457V27.9257C12 30.4971 14.0743 32.5714 16.6457 32.5714H19.5771C20.4343 32.5714 21.12 31.8857 21.12 31.0286C21.12 30.1714 20.4343 29.4857 19.5771 29.4857H16.6457C15.7886 29.4857 15.1029 28.8 15.1029 27.9429V16.6457C15.1029 15.7886 15.7886 15.1029 16.6457 15.1029H27.9257C28.7829 15.1029 29.4686 15.7886 29.4686 16.6457V21.5143C29.4686 22.3714 30.1543 23.0571 31.0114 23.0571C31.8686 23.0571 32.5543 22.3714 32.5543 21.5143V16.6457C32.5543 14.0743 30.48 12 27.9086 12H27.9257Z"
            fill="white"
        />
        <path
            d="M36 28.6457C36 26.5029 34.2686 24.7714 32.1257 24.7714H26.7086C24.5657 24.7714 22.8343 26.5029 22.8343 28.6457V32.1257C22.8343 34.2686 24.5657 36 26.7086 36H32.1257C34.2686 36 36 34.2686 36 32.1257V28.6457ZM32.8971 32.1257C32.8971 32.5543 32.5543 32.8971 32.1257 32.8971H26.7086C26.28 32.8971 25.9371 32.5543 25.9371 32.1257V28.6457C25.9371 28.2171 26.28 27.8743 26.7086 27.8743H32.1257C32.5543 27.8743 32.8971 28.2171 32.8971 28.6457V32.1257Z"
            fill="white"
        />
        <path
            d="M24.0514 23.4171L19.4571 18.5657H22.1657C22.6457 18.5657 23.0229 18.1886 23.0229 17.7086C23.0229 17.2286 22.6457 16.8514 22.1657 16.8514H17.4686C17.4686 16.8514 17.3829 16.8686 17.3314 16.8857C17.2629 16.8857 17.2114 16.8857 17.16 16.92C17.0914 16.9543 17.0229 16.9886 16.9714 17.04C16.9371 17.0571 16.9029 17.0743 16.8857 17.0914C16.8343 17.1429 16.7829 17.2286 16.7486 17.2971C16.7486 17.3143 16.7143 17.3486 16.6971 17.3657C16.6971 17.4 16.6971 17.4514 16.6629 17.4857C16.6457 17.5543 16.6286 17.6229 16.6286 17.6914V22.3886C16.6286 22.8686 17.0057 23.2457 17.4857 23.2457C17.9657 23.2457 18.3429 22.8686 18.3429 22.3886V19.8343L22.8343 24.5657C23.0057 24.7371 23.2286 24.84 23.4514 24.84C23.6571 24.84 23.88 24.7543 24.0343 24.6C24.3771 24.2743 24.3943 23.7257 24.0686 23.3829L24.0514 23.4171Z"
            fill="white"
        />
    </svg>
);

export default Maximized;
