const FavoriteActive = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.71091 0.789771C7.28528 -0.263259 8.71472 -0.263256 9.28909 0.789772L11.0481 4.0146C11.2102 4.31185 11.4904 4.5147 11.8098 4.56602L14.7307 5.03534C16.0039 5.23992 16.4505 6.95009 15.459 7.82402L13.3308 9.69976C13.0321 9.96303 12.8916 10.3783 12.9642 10.7837L13.5603 14.1142C13.7938 15.4188 12.5065 16.4324 11.4019 15.8137L8.49598 14.186C8.18553 14.0121 7.81447 14.0121 7.50402 14.186L4.59815 15.8137C3.49355 16.4324 2.20617 15.4188 2.43969 14.1142L3.03583 10.7837C3.10839 10.3783 2.96794 9.96303 2.66923 9.69976L0.541032 7.82402C-0.450533 6.95008 -0.00392354 5.23992 1.26929 5.03534L4.19016 4.56602C4.50958 4.5147 4.78982 4.31185 4.95195 4.0146L6.71091 0.789771Z"
            fill="#FFBE0B"
        />
    </svg>
);

export default FavoriteActive;
