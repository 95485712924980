import { useSelector } from 'react-redux';
import { imageBaseUrl } from '../../../../services/http';

const WheelOfFortuneMaintenance = () => {
    const tr = useSelector((state) => state.global.data.translations);

    return (
        <div className="wheel-of-fortune-maintenance">
            <div className="wild-fortunes">
                <img src={`${imageBaseUrl}/wild-fortunes-maintenance.png`} alt="wild fortunes" />
            </div>
            <div className="maintenance-wrapper">
                <div className="maintenance-container">
                    <div
                        className="maintenance-container--inner"
                        dangerouslySetInnerHTML={{ __html: tr['wof.no_records'] }}
                    />
                </div>
            </div>
        </div>
    );
};

export default WheelOfFortuneMaintenance;
