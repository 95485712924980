import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Fragment, useRef } from 'react';
import ReactCodeInput from 'react-verification-code-input-2';
import cx from 'classnames';

const MfaVerificationCode = ({ title, mfaCodeWidth = 50, mfaCodeHeight = 50, verifiedInputFields = 6 }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { values, setFieldValue } = useFormikContext();
    const { isError, isLoading } = useSelector((state) => state.user.mfaEnableStatuses);
    const inputRef = useRef(null);

    const handleVerifiedInputField = () => {
        if (isMobile && inputRef.current) {
            const inputs = inputRef.current.getElementsByTagName('input');
            inputs[verifiedInputFields - 1].blur();
        }
    };

    return (
        <Fragment>
            <h5>{title}</h5>
            <div ref={inputRef} className="mfa-modal--content-code">
                <ReactCodeInput
                    autoFocus={false}
                    className={cx('mfa-modal--content-code--inputs', {
                        'mfa-modal--content-code--inputs-error': isError.status
                    })}
                    fieldWidth={mfaCodeWidth}
                    fieldHeight={mfaCodeHeight}
                    onChange={(code) => setFieldValue('mfaCode', code)}
                    value={values.mfaCode}
                    fields={verifiedInputFields}
                    disabled={isLoading}
                    onComplete={handleVerifiedInputField}
                />
                {isError.status && <div className="mfa-error">{tr['mfa.authenticator.error']}</div>}
            </div>
        </Fragment>
    );
};

export default MfaVerificationCode;
