const Casino = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.84082 1.75844C5.40567 1.60293 3.92007 1.60293 2.48493 1.75844C2.1362 1.79623 1.86119 2.06394 1.82197 2.38908C1.65059 3.80972 1.65059 5.2449 1.82197 6.66554C1.86119 6.99068 2.1362 7.2584 2.48493 7.29618C3.92007 7.45169 5.40567 7.45169 6.84082 7.29618C7.18954 7.2584 7.46455 6.99068 7.50378 6.66554C7.67516 5.2449 7.67516 3.80972 7.50378 2.38908C7.46455 2.06394 7.18954 1.79623 6.84082 1.75844ZM2.29683 0.126791C3.85699 -0.0422636 5.46876 -0.0422636 7.02891 0.126791C8.14753 0.248001 9.05345 1.10173 9.18574 2.19835C9.37241 3.74572 9.37241 5.3089 9.18574 6.85627C9.05345 7.95289 8.14753 8.80662 7.02891 8.92783C5.46876 9.09688 3.85699 9.09688 2.29683 8.92783C1.17822 8.80662 0.272295 7.95289 0.140002 6.85627C-0.0466672 5.3089 -0.0466672 3.74572 0.140002 2.19836C0.272295 1.10173 1.17822 0.248001 2.29683 0.126791Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.84082 12.7038C5.40567 12.5483 3.92007 12.5483 2.48493 12.7038C2.1362 12.7416 1.86119 13.0093 1.82197 13.3345C1.65059 14.7551 1.65059 16.1903 1.82197 17.6109C1.86119 17.9361 2.1362 18.2038 2.48493 18.2416C3.92007 18.3971 5.40567 18.3971 6.84082 18.2416C7.18954 18.2038 7.46455 17.9361 7.50378 17.6109C7.67516 16.1903 7.67516 14.7551 7.50378 13.3345C7.46455 13.0093 7.18954 12.7416 6.84082 12.7038ZM2.29683 11.0722C3.85699 10.9031 5.46876 10.9031 7.02891 11.0722C8.14753 11.1934 9.05345 12.0471 9.18574 13.1437C9.37241 14.6911 9.37241 16.2543 9.18574 17.8016C9.05345 18.8983 8.14753 19.752 7.02891 19.8732C5.46876 20.0423 3.85699 20.0423 2.29683 19.8732C1.17822 19.752 0.272295 18.8983 0.140002 17.8016C-0.0466672 16.2543 -0.0466672 14.6911 0.140002 13.1437C0.272295 12.0471 1.17822 11.1934 2.29683 11.0722Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1304 1.75844C16.6952 1.60293 15.2096 1.60293 13.7745 1.75844C13.4257 1.79623 13.1507 2.06394 13.1115 2.38908C12.9401 3.80972 12.9401 5.2449 13.1115 6.66554C13.1507 6.99068 13.4257 7.2584 13.7745 7.29618C15.2096 7.45169 16.6952 7.45169 18.1304 7.29618C18.4791 7.2584 18.7541 6.99068 18.7933 6.66554C18.9647 5.2449 18.9647 3.80972 18.7933 2.38908C18.7541 2.06394 18.4791 1.79623 18.1304 1.75844ZM13.5864 0.126791C15.1465 -0.0422636 16.7583 -0.0422636 18.3185 0.126791C19.4371 0.248001 20.343 1.10173 20.4753 2.19835C20.662 3.74572 20.662 5.3089 20.4753 6.85627C20.343 7.95289 19.4371 8.80662 18.3185 8.92783C16.7583 9.09688 15.1465 9.09688 13.5864 8.92783C12.4678 8.80662 11.5618 7.95289 11.4295 6.85627C11.2429 5.3089 11.2429 3.74572 11.4295 2.19836C11.5618 1.10173 12.4678 0.248001 13.5864 0.126791Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1304 12.7038C16.6952 12.5483 15.2096 12.5483 13.7745 12.7038C13.4257 12.7416 13.1507 13.0093 13.1115 13.3345C12.9401 14.7551 12.9401 16.1903 13.1115 17.6109C13.1507 17.9361 13.4257 18.2038 13.7745 18.2416C15.2096 18.3971 16.6952 18.3971 18.1304 18.2416C18.4791 18.2038 18.7541 17.9361 18.7933 17.6109C18.9647 16.1903 18.9647 14.7551 18.7933 13.3345C18.7541 13.0093 18.4791 12.7416 18.1304 12.7038ZM13.5864 11.0722C15.1465 10.9031 16.7583 10.9031 18.3185 11.0722C19.4371 11.1934 20.343 12.0471 20.4753 13.1437C20.662 14.6911 20.662 16.2543 20.4753 17.8016C20.343 18.8983 19.4371 19.752 18.3185 19.8732C16.7583 20.0423 15.1465 20.0423 13.5864 19.8732C12.4678 19.752 11.5618 18.8983 11.4295 17.8016C11.2429 16.2543 11.2429 14.6911 11.4295 13.1437C11.5618 12.0471 12.4678 11.1934 13.5864 11.0722Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.5151 1.75844C28.0799 1.60293 26.5943 1.60293 25.1592 1.75844C24.8105 1.79623 24.5354 2.06394 24.4962 2.38908C24.3248 3.80972 24.3248 5.2449 24.4962 6.66554C24.5354 6.99068 24.8105 7.2584 25.1592 7.29618C26.5943 7.45169 28.0799 7.45169 29.5151 7.29618C29.8638 7.2584 30.1388 6.99068 30.178 6.66554C30.3494 5.2449 30.3494 3.80972 30.178 2.38908C30.1388 2.06394 29.8638 1.79623 29.5151 1.75844ZM24.9711 0.126791C26.5312 -0.0422636 28.143 -0.0422636 29.7032 0.126791C30.8218 0.248001 31.7277 1.10173 31.86 2.19835C32.0467 3.74572 32.0467 5.3089 31.86 6.85627C31.7277 7.95289 30.8218 8.80662 29.7032 8.92783C28.143 9.09688 26.5312 9.09688 24.9711 8.92783C23.8525 8.80662 22.9465 7.95289 22.8143 6.85627C22.6276 5.3089 22.6276 3.74572 22.8143 2.19836C22.9465 1.10173 23.8525 0.248001 24.9711 0.126791Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.5151 12.7038C28.0799 12.5483 26.5943 12.5483 25.1592 12.7038C24.8105 12.7416 24.5354 13.0093 24.4962 13.3345C24.3248 14.7551 24.3248 16.1903 24.4962 17.6109C24.5354 17.9361 24.8105 18.2038 25.1592 18.2416C26.5943 18.3971 28.0799 18.3971 29.5151 18.2416C29.8638 18.2038 30.1388 17.9361 30.178 17.6109C30.3494 16.1903 30.3494 14.7551 30.178 13.3345C30.1388 13.0093 29.8638 12.7416 29.5151 12.7038ZM24.9711 11.0722C26.5312 10.9031 28.143 10.9031 29.7032 11.0722C30.8218 11.1934 31.7277 12.0471 31.86 13.1437C32.0467 14.6911 32.0467 16.2543 31.86 17.8016C31.7277 18.8983 30.8218 19.752 29.7032 19.8732C28.143 20.0423 26.5312 20.0423 24.9711 19.8732C23.8525 19.752 22.9465 18.8983 22.8143 17.8016C22.6276 16.2543 22.6276 14.6911 22.8143 13.1437C22.9465 12.0471 23.8525 11.1934 24.9711 11.0722Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default Casino;
