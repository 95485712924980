import { GAME_MODE_DEMO, GAME_MODE_REAL } from '../variables';
import { dataLayerPush } from '@tlf-e/brand-utils';
import { handleGamePlayEvent } from './handleGamePlayEvent';

export const gameImpressions = ({ eventName, displayedGames, gamesData, categories, softwares, isAuth, router }) => {
    const gameMode = isAuth ? GAME_MODE_REAL : GAME_MODE_DEMO;

    const products = handleGamePlayEvent(displayedGames, gamesData, router, categories, softwares, eventName, gameMode);

    if (products.length > 0) {
        dataLayerPush({ event: eventName, products });
    }
};
