import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { allowSpecialRegex } from '../../../../utils/commonConfig';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { withRegisterHelper } from '../../../hoc/withRegisterHelper';
import { cleanupSubmitRegistration, combineRegistrationForm, setActiveStep } from '../../../../store/reducers/register';
import RegistrationFromTitle from '../../../common/register-new/fragments/RegistrationFromTitle';
import FormErrorMessage from '../../../common/FormErrorMessage';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';
import * as yup from 'yup';
import { dataLayerPush, dayMaskBuilder, monthMaskBuilder, yearMaskBuilder, onInputNumber } from '@tlf-e/brand-utils';

const RegisterStep2New = () => {
    const dispatch = useDispatch();
    const dateField = useRef();
    const tr = useSelector((state) => state.global.data.translations);
    const { submitRegistration, form, totalSteps } = useSelector((state) => state.register);
    const { isLoading, isError, error } = submitRegistration;

    useEffect(() => {
        dataLayerPush({ event: 'signup_step2' });
        return () => dispatch(cleanupSubmitRegistration());
    }, []);

    const validationSchema = yup.object({
        firstname: yup
            .string()
            .matches(allowSpecialRegex, tr['register.form.firstname.error.wrong'])
            .required(tr['register.form.firstname.error.required'])
            .min(2, tr['register.form.firstname.error.wrong']),
        lastname: yup
            .string()
            .matches(allowSpecialRegex, tr['register.form.lastname.error.wrong'])
            .required(tr['register.form.lastname.error.required'])
            .min(2, tr['register.form.lastname.error.wrong']),
        birthDay: yup
            .number()
            .moreThan(0, tr['register.form.birth_day.error.wrong'])
            .lessThan(32, tr['register.form.birth_day.error.wrong'])
            .required(tr['register.form.birth_day.error.required']),
        birthMonth: yup
            .number()
            .moreThan(0, tr['register.form.birth_month.error.wrong'])
            .lessThan(13, tr['register.form.birth_month.error.wrong'])
            .required(tr['register.form.birth_month.error.required']),
        birthYear: yup
            .number()
            .moreThan(new Date().getFullYear() - 121, tr['register.form.birth_year.error.wrong'])
            .lessThan(new Date().getFullYear() - 17, tr['register.form.birth_year.error.wrong'])
            .required(tr['register.form.birth_year.error.required']),
        gender: yup.string().required(tr['register.form.gender.error.required'])
    });

    const focusChange = (item) => {
        if (dateField.current.childNodes[item].querySelector('input').value.length >= 2) {
            dateField.current.childNodes[item + 1].querySelector('input').focus();
        }
    };

    const onSubmit = (values) => {
        dispatch(combineRegistrationForm({ ...values }));
        dispatch(setActiveStep(3));
    };

    return (
        <Formik
            initialValues={{
                firstname: form.firstname,
                lastname: form.lastname,
                birthDay: form.birthDay,
                birthMonth: form.birthMonth,
                birthYear: form.birthYear,
                gender: form.gender
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({ values, touched, errors }) => (
                <Fragment>
                    <RegistrationFromTitle currentStep={2} totalSteps={totalSteps} />
                    <Form id="register_form_new">
                        {isError && <div className="page-message page-message--error">{error}</div>}
                        <div className="form-group">
                            <div className="form-group--row">
                                <div className="form-group--cell">
                                    <label className="form-group--title form-group-title">
                                        {tr['register.form.firstname']}
                                    </label>
                                    <div
                                        className={cx({
                                            'form-group--cell-error-marked': touched.firstname && errors.firstname
                                        })}
                                    >
                                        <Field name="firstname">
                                            {({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    data-testid="register_firstname"
                                                    id="register_firstname"
                                                    autoComplete="off"
                                                    autoCapitalize="off"
                                                    className="text-field"
                                                    onBlur={(e) => {
                                                        e.target.value &&
                                                            dataLayerPush({
                                                                event: 'signup_filled_field',
                                                                field: 'firstname'
                                                            });
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormErrorMessage name="firstname" />
                                    </div>
                                </div>
                                <div className="form-group--cell">
                                    <label className="form-group--title form-group-title">
                                        {tr['register.form.lastname']}
                                    </label>
                                    <div
                                        className={cx({
                                            'form-group--cell-error-marked': touched.lastname && errors.lastname
                                        })}
                                    >
                                        <Field name="lastname">
                                            {({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    data-testid="register_lastname"
                                                    id="register_lastname"
                                                    autoComplete="off"
                                                    autoCapitalize="off"
                                                    className="text-field"
                                                    onBlur={(e) => {
                                                        e.target.value &&
                                                            dataLayerPush({
                                                                event: 'signup_filled_field',
                                                                field: 'lastname'
                                                            });
                                                    }}
                                                />
                                            )}
                                        </Field>
                                        <FormErrorMessage name="lastname" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-group--title form-group-title">{tr['register.birthdate']}</label>
                            <div className="form-group--row" ref={dateField}>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthDay && errors.birthDay
                                    })}
                                >
                                    <Field name="birthDay">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={dayMaskBuilder(field.value)}
                                                guide={false}
                                                type="text"
                                                placeholder="DD"
                                                maxLength="2"
                                                inputMode="numeric"
                                                data-testid="register_birthDay"
                                                id="register_birthDay"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onKeyUp={() => focusChange(0)}
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_day'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthDay" />
                                </div>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthMonth && errors.birthMonth
                                    })}
                                >
                                    <Field name="birthMonth">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={monthMaskBuilder(field.value)}
                                                guide={false}
                                                maxLength="2"
                                                type="text"
                                                placeholder="MM"
                                                inputMode="numeric"
                                                data-testid="register_birthMonth"
                                                id="register_birthMonth"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onKeyUp={() => focusChange(1)}
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_month'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthMonth" />
                                </div>
                                <div
                                    className={cx('form-group--cell', {
                                        'form-group--cell-error-marked': touched.birthYear && errors.birthYear
                                    })}
                                >
                                    <Field name="birthYear">
                                        {({ field }) => (
                                            <MaskedInput
                                                {...field}
                                                mask={yearMaskBuilder(field.value)}
                                                guide={false}
                                                type="text"
                                                placeholder="YYYY"
                                                inputMode="numeric"
                                                maxLength="4"
                                                data-testid="register_birthYear"
                                                id="register_birthYear"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onInput={onInputNumber}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'birthdate_year'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="birthYear" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-group--title form-group-title">{tr['register.gender']}</label>
                            <Field name="gender">
                                {({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        row
                                        className="radio-group"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            dataLayerPush({
                                                event: 'signup_filled_field',
                                                field: 'gender'
                                            });
                                        }}
                                    >
                                        {['male', 'female'].map((value) => {
                                            const label = tr[`register.gender.${value}`];
                                            return (
                                                <FormControlLabel
                                                    value={value}
                                                    key={value}
                                                    control={<Radio data-testid={`register_gender_${value}`} />}
                                                    label={label}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                )}
                            </Field>
                            <FormErrorMessage name="gender" />
                        </div>
                        <div className="form--btns">
                            <button
                                type="button"
                                className="btn btn-cancel btn-modern"
                                data-testid="register_step_back"
                                onClick={() => {
                                    dispatch(setActiveStep(1));
                                    dispatch(combineRegistrationForm({ ...values }));
                                }}
                            >
                                {tr['go.back']}
                            </button>
                            <button
                                type="submit"
                                data-testid="submit_form"
                                className="btn btn-submit btn-modern"
                                disabled={isLoading}
                            >
                                {tr['register.continue']}
                            </button>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};

export default withRegisterHelper(RegisterStep2New);
