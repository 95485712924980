const SicBo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2ZM8 18C8 19.1046 7.10457 20 6 20C4.89543 20 4 19.1046 4 18C4 16.8954 4.89543 16 6 16C7.10457 16 8 16.8954 8 18ZM10 4C11.1046 4 12 3.10457 12 2C12 0.895431 11.1046 0 10 0C8.89543 0 8 0.895431 8 2C8 3.10457 8.89543 4 10 4ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10ZM14 20C15.1046 20 16 19.1046 16 18C16 16.8954 15.1046 16 14 16C12.8954 16 12 16.8954 12 18C12 19.1046 12.8954 20 14 20ZM20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2C16 0.895431 16.8954 0 18 0C19.1046 0 20 0.895431 20 2ZM18 12C19.1046 12 20 11.1046 20 10C20 8.89543 19.1046 8 18 8C16.8954 8 16 8.89543 16 10C16 11.1046 16.8954 12 18 12ZM24 18C24 19.1046 23.1046 20 22 20C20.8954 20 20 19.1046 20 18C20 16.8954 20.8954 16 22 16C23.1046 16 24 16.8954 24 18ZM26 4C27.1046 4 28 3.10457 28 2C28 0.895431 27.1046 0 26 0C24.8954 0 24 0.895431 24 2C24 3.10457 24.8954 4 26 4Z"
            fill="#301600"
        />
    </svg>
);

export default SicBo;
