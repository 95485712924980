import React from 'react';
import { useSelector } from 'react-redux';

const PageLoader = () => {
    const tr = useSelector((state) => state.global.data.translations);
    return (
        <div className="form-loader">
            <div className="top">
                <div className="animation"></div>
                <div className="message" dangerouslySetInnerHTML={{ __html: tr['global.loading'] }} />
            </div>
        </div>
    );
};

export default PageLoader;
