import { setGameOverlayMinimized, setMinimizedGameExpanded } from '../../../../store/reducers/global';
import { withGameActions } from '../../../hoc/withGameActions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import ExitGameIcon from '../../../svg-icons/ExitGameIcon';
import Maximized from '../../../svg-icons/Maximized';
import Expand from '../../../svg-icons/Expand';
import Shrink from '../../../svg-icons/Shrink';
import Tooltip from '@mui/material/Tooltip';
import { dataLayerPush } from '@tlf-e/brand-utils';
import { GAME_INTERFACE_PIP_CLOSE, GAME_INTERFACE_PIP_MAX } from '../../../../variables';

const MinimizedGameComponent = ({ game, dataId = '', handleClose }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { isMinimizedGameExpanded } = useSelector((state) => state.global);
    const gamePageUrl = useSelector((state) => state.games.gamePageUrl);
    const dispatch = useDispatch();
    const router = useRouter();

    const handleMaximizeClick = () => {
        dispatch(setGameOverlayMinimized(false));
        dispatch(setMinimizedGameExpanded(false));
        router.replace(`${gamePageUrl}`, undefined, { shallow: true });
        dataLayerPush({ event: GAME_INTERFACE_PIP_MAX, game: game.name });
    };

    const handleGameClose = () => {
        handleClose();
        dataLayerPush({ event: GAME_INTERFACE_PIP_CLOSE, game: game.name });
    };

    const hanldeExpandClick = () => {
        dispatch(setMinimizedGameExpanded(!isMinimizedGameExpanded));
    };

    return (
        <div className="minimized-game--component" data-id={dataId}>
            <div className="minimized-game--actions-wrapper" data-testid={`game_${game.id}`}>
                <div className="minimized-game--actions-title">{game.name}</div>
                <div className="minimized-game--actions-component">
                    <Tooltip
                        title={isMinimizedGameExpanded ? tr['minimized.game.shrink.btn'] : tr['minimized.game.expand.btn']}
                        placement="top"
                        arrow
                    >
                        <div onClick={hanldeExpandClick} data-testid="expand-game-btn">
                            {isMinimizedGameExpanded ? <Shrink /> : <Expand />}
                        </div>
                    </Tooltip>
                    <Tooltip title={tr['minimized.game.maximize.btn']} placement="top" arrow>
                        <div data-testid="maximize" onClick={handleMaximizeClick}>
                            <Maximized />
                        </div>
                    </Tooltip>
                    <Tooltip title={tr['minimized.game.close.btn']} placement="top" arrow>
                        <div onClick={handleGameClose}>
                            <ExitGameIcon />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default withGameActions(MinimizedGameComponent);
