import { setGameOverlayActive } from '../store/reducers/global';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef } from 'react';
import EvolutionGameDesktop from './desktop/casino-games/fragments/game/EvolutionGameDesktop';
import GameDesktop from './desktop/casino-games/fragments/game';
import OverlayComponent from './desktop/overlay-games';
import { clearGameOverlays } from '../store/reducers/user';

const evoAddGameIntent = -45;
const evoAddGameIntentCookie = 30;
const slotsAddGameIndent = 15;
const slotsAddGameIndentCookie = 5;

const Overlay = ({ children, isGamePage }) => {
    const dispatch = useDispatch();
    const containerRef = useRef();
    const playRef = useRef();
    const { gameProps } = useSelector((state) => state.games);
    const { isGameOverlayActive, isGameOverlayMinimized, desktopHeaderHeight, cookieAccepted } = useSelector(
        (state) => state.global
    );
    const hasGameProps = Object.keys(gameProps).length > 0;
    const {
        game,
        provider,
        iframeSrc,
        gameLaunchScripts,
        gameLaunchContent,
        isErrorPage,
        gameLaunchError,
        isGameUrlLoading
    } = gameProps;
    const isOverlayVisible = (isGameOverlayActive && hasGameProps) || isGamePage;
    const isEvolution = game?.isEvolution;

    useEffect(() => {
        if (isGamePage && !isGameOverlayMinimized) {
            dispatch(setGameOverlayActive(false));
        }
    }, [isGamePage, isGameOverlayMinimized]);

    useEffect(() => {
        return () => {
            dispatch(clearGameOverlays(null));
        };
    }, []);

    const componentProps = {
        game,
        provider,
        iframeSrc,
        isErrorPage,
        gameLaunchError,
        isGameUrlLoading,
        containerRef,
        playRef
    };

    return (
        <Fragment>
            {!isEvolution && isOverlayVisible && hasGameProps ? (
                <OverlayComponent game={game}>
                    <GameDesktop
                        {...componentProps}
                        gameLaunchContent={gameLaunchContent}
                        addGameIndent={cookieAccepted ? slotsAddGameIndent + slotsAddGameIndentCookie : slotsAddGameIndent}
                    />
                </OverlayComponent>
            ) : (
                isEvolution &&
                isOverlayVisible && (
                    <EvolutionGameDesktop
                        {...componentProps}
                        gameLaunchScripts={gameLaunchScripts}
                        addGameIndent={cookieAccepted ? evoAddGameIntent : evoAddGameIntentCookie - desktopHeaderHeight}
                    />
                )
            )}
            {children}
        </Fragment>
    );
};

export default Overlay;
