import { useSelector } from 'react-redux';
import FavoritePanelHeader from './fragments/FavoritePanelHeader';
import FavoritePanelBody from './fragments/FavoritePanelBody';
import cx from 'classnames';

const FavoritePanel = ({ game, dimensions }) => {
    const { isFavoriteGameModalOpened } = useSelector((state) => state.games);

    return (
        <div
            className={cx('favorite-panel', { visible: isFavoriteGameModalOpened })}
            style={{ height: `${dimensions.height}px` }}
        >
            <FavoritePanelHeader />
            <FavoritePanelBody game={game} />
        </div>
    );
};

export default FavoritePanel;
