import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { imageBaseUrl } from '../../../../services/http';
import { WOF_WHEEL_MODE_SPIN } from '../../../../variables';

// Animation consists of 7 consecutive images
const images = [...new Array(7)].map((_, index) => `${imageBaseUrl}/wheel-of-fortune/logo/00${index + 1}.png`);

// Delays config
const delays = {
    1: 500,
    2: 2000,
    3: 500,
    4: 500,
    5: 500,
    6: 500,
    7: 500
};

const AnimatedLogo = () => {
    const { wheelMode } = useSelector((state) => state.wheel_of_fortune);
    const [displayedImage, setDisplayedImage] = useState(1);
    const timerRef = useRef(null);

    useEffect(() => {
        const startTimer = (index) => {
            const timer = setTimeout(() => {
                setDisplayedImage(index);
                const nextIndex = index === 7 ? 1 : index + 1;
                removeTimer();
                startTimer(nextIndex); // Recursively call startTimer with the next index
            }, delays[index]);

            timerRef.current = timer;
        };

        if (wheelMode === WOF_WHEEL_MODE_SPIN) {
            startTimer(displayedImage + 1); // Start the initial timer
        } else {
            setDisplayedImage(1);
        }

        return () => removeTimer();
    }, [wheelMode]);

    const removeTimer = () => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
    };

    return (
        <div className="logo-wrapper">
            {images.map((src, index) => {
                const currentImg = index + 1;
                return (
                    <img
                        key={index}
                        src={src}
                        alt={`image_${currentImg}`}
                        className={cx({ displayed: currentImg === displayedImage })}
                    />
                );
            })}
        </div>
    );
};

export default AnimatedLogo;
