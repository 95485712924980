const ExpandScreen = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M11.99 15H10.24C9.82 15 9.54 14.72 9.54 14.3C9.54 13.88 9.82 13.6 10.24 13.6H11.99C12.9 13.6 13.6 12.9 13.6 11.99V10.24C13.6 9.82 13.88 9.54 14.3 9.54C14.72 9.54 15 9.82 15 10.24V11.99C15 13.67 13.67 15 11.99 15Z"
            fill="#301600"
        />
        <path
            d="M5.69 15H4.01C2.33 15 1 13.67 1 11.99V10.24C1 9.82 1.28 9.54 1.7 9.54C2.12 9.54 2.4 9.82 2.4 10.24V11.99C2.4 12.9 3.1 13.6 4.01 13.6H5.76C6.18 13.6 6.46 13.88 6.46 14.3C6.46 14.72 6.11 15 5.69 15Z"
            fill="#301600"
        />
        <path
            d="M1.7 6.39C1.28 6.39 1 6.11 1 5.69V4.01C1 2.33 2.33 1 4.01 1H5.76C6.18 1 6.46 1.28 6.46 1.7C6.46 2.12 6.18 2.4 5.76 2.4H4.01C3.1 2.4 2.4 3.1 2.4 4.01V5.76C2.4 6.11 2.12 6.39 1.7 6.39Z"
            fill="#301600"
        />
        <path
            d="M14.3 6.39C13.88 6.39 13.6 6.11 13.6 5.69V4.01C13.6 3.1 12.9 2.4 11.99 2.4H10.24C9.82 2.4 9.54 2.12 9.54 1.7C9.54 1.28 9.82 1 10.24 1H11.99C13.67 1 15 2.33 15 4.01V5.76C15 6.11 14.72 6.39 14.3 6.39Z"
            fill="#301600"
        />
        <path
            d="M11.99 15H10.24C9.82 15 9.54 14.72 9.54 14.3C9.54 13.88 9.82 13.6 10.24 13.6H11.99C12.9 13.6 13.6 12.9 13.6 11.99V10.24C13.6 9.82 13.88 9.54 14.3 9.54C14.72 9.54 15 9.82 15 10.24V11.99C15 13.67 13.67 15 11.99 15Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M5.69 15H4.01C2.33 15 1 13.67 1 11.99V10.24C1 9.82 1.28 9.54 1.7 9.54C2.12 9.54 2.4 9.82 2.4 10.24V11.99C2.4 12.9 3.1 13.6 4.01 13.6H5.76C6.18 13.6 6.46 13.88 6.46 14.3C6.46 14.72 6.11 15 5.69 15Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M1.7 6.39C1.28 6.39 1 6.11 1 5.69V4.01C1 2.33 2.33 1 4.01 1H5.76C6.18 1 6.46 1.28 6.46 1.7C6.46 2.12 6.18 2.4 5.76 2.4H4.01C3.1 2.4 2.4 3.1 2.4 4.01V5.76C2.4 6.11 2.12 6.39 1.7 6.39Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M14.3 6.39C13.88 6.39 13.6 6.11 13.6 5.69V4.01C13.6 3.1 12.9 2.4 11.99 2.4H10.24C9.82 2.4 9.54 2.12 9.54 1.7C9.54 1.28 9.82 1 10.24 1H11.99C13.67 1 15 2.33 15 4.01V5.76C15 6.11 14.72 6.39 14.3 6.39Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
    </svg>
);

export default ExpandScreen;
