const Blackjack = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="24" viewBox="0 0 31 24" fill="none">
        <path
            d="M28.1 3.10908C25.6717 1.82223 23.1053 0.815262 20.45 0.107501C19.683 -0.0892392 18.8715 -0.0143909 18.1534 0.319345C17.4352 0.653081 16.8546 1.22515 16.51 1.93846C16.15 2.66884 15.72 3.65936 15.27 4.77995C15.6648 5.2089 15.9497 5.72735 16.1 6.29074C16.66 4.80997 17.22 3.46926 17.67 2.56879C17.8853 2.1237 18.248 1.76689 18.6964 1.55898C19.1449 1.35106 19.6514 1.30485 20.13 1.42819C22.6877 2.11561 25.1599 3.08892 27.5 4.32972C27.9409 4.57066 28.2903 4.94996 28.4945 5.40919C28.6987 5.86842 28.7463 6.38209 28.63 6.87105C27.5923 10.6406 26.2547 14.3209 24.63 17.8768C24.4125 18.3121 24.0533 18.6603 23.6116 18.8639C23.1699 19.0676 22.672 19.1147 22.2 18.9974C20.1911 18.4607 18.2335 17.7476 16.35 16.8663C16.35 17.3866 16.29 17.8668 16.25 18.307C18.0662 19.1334 19.9498 19.8029 21.88 20.3081C22.6471 20.5088 23.4601 20.4358 24.1791 20.1016C24.8982 19.7674 25.4784 19.193 25.82 18.4771C27.4826 14.8316 28.847 11.0573 29.9 7.19122C30.0896 6.40745 30.0161 5.58303 29.6908 4.84524C29.3654 4.10746 28.8064 3.49733 28.1 3.10908Z"
            fill="#301600"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.31001 5.37026C6.02394 5.05014 8.76607 5.05014 11.48 5.37026C12.2735 5.4726 13.009 5.84087 13.5665 6.41502C14.124 6.98918 14.4707 7.73536 14.55 8.53192C14.87 12.5477 14.87 16.5825 14.55 20.5982C14.4707 21.3948 14.124 22.141 13.5665 22.7151C13.009 23.2893 12.2735 23.6576 11.48 23.7599C8.76607 24.08 6.02394 24.08 3.31001 23.7599C2.51718 23.6559 1.78268 23.287 1.22549 22.7132C0.668305 22.1394 0.321059 21.3942 0.240006 20.5982C-0.080002 16.5825 -0.080002 12.5477 0.240006 8.53192C0.321059 7.736 0.668305 6.99077 1.22549 6.41695C1.78268 5.84312 2.51718 5.47432 3.31001 5.37026ZM11.31 6.72097C8.69191 6.42082 6.04811 6.42082 3.43001 6.72097C2.93122 6.78875 2.47003 7.02337 2.12143 7.38669C1.77283 7.75 1.55734 8.22062 1.51001 8.72202C1.20002 12.6313 1.20002 16.5589 1.51001 20.4682C1.55734 20.9696 1.77283 21.4402 2.12143 21.8035C2.47003 22.1668 2.93122 22.4015 3.43001 22.4692C6.04811 22.7694 8.69191 22.7694 11.31 22.4692C11.8084 22.4028 12.2692 22.1684 12.6165 21.8046C12.9637 21.4408 13.1766 20.9694 13.22 20.4682C13.53 16.5589 13.53 12.6313 13.22 8.72202C13.178 8.22527 12.9694 7.75731 12.6281 7.39407C12.2869 7.03083 11.8329 6.79363 11.34 6.72097H11.31Z"
            fill="#301600"
        />
        <path
            d="M8.75001 16.216C8.33328 16.2069 7.93311 16.051 7.62001 15.7757C7.83155 16.3428 8.08908 16.8915 8.39001 17.4166H6.39001C6.69552 16.8939 6.95324 16.3448 7.16001 15.7757C6.83909 16.0577 6.42709 16.214 6.00001 16.216C5.4818 16.1978 4.99089 15.979 4.63083 15.6057C4.27077 15.2323 4.06969 14.7337 4.07001 14.2149C4.08055 13.4793 4.34961 12.7708 4.83001 12.2139C5.27277 11.7009 5.7765 11.2439 6.33001 10.8531L7.20001 10.2028C7.24483 10.1695 7.29918 10.1515 7.35501 10.1515C7.41084 10.1515 7.46518 10.1695 7.51001 10.2028L8.39001 10.8531C8.93825 11.2467 9.43835 11.7034 9.88001 12.2139C10.3642 12.7686 10.6338 13.4784 10.64 14.2149C10.6408 14.7271 10.4452 15.2201 10.0936 15.5923C9.74203 15.9646 9.26114 16.1878 8.75001 16.216Z"
            fill="#301600"
        />
        <path
            d="M21.49 12.7541C21.119 12.5683 20.8227 12.2611 20.65 11.8837C20.5953 12.4861 20.5953 13.0922 20.65 13.6946L18.84 12.8542C19.3393 12.5085 19.8078 12.1202 20.24 11.6936C19.8296 11.8164 19.3884 11.7844 19 11.6035C18.5486 11.3623 18.2068 10.9571 18.0449 10.4713C17.8831 9.98552 17.9135 9.45616 18.13 8.99216C18.4552 8.34832 18.9919 7.83605 19.65 7.5414C20.2679 7.27814 20.9162 7.09328 21.58 6.99111L22.65 6.771C22.7045 6.75819 22.7617 6.76404 22.8125 6.78763C22.8632 6.81121 22.9046 6.85115 22.93 6.90106L23.45 7.90159C23.7825 8.49125 24.0411 9.11964 24.22 9.77257C24.426 10.4638 24.3763 11.2061 24.08 11.8637C23.8539 12.3247 23.4543 12.6773 22.9689 12.8442C22.4834 13.0111 21.9516 12.9787 21.49 12.7541Z"
            fill="#301600"
        />
    </svg>
);

export default Blackjack;
