import { Fragment, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { dataLayerPush, getAffiliateDataValue, countries } from '@tlf-e/brand-utils';
import { useRouter } from 'next/router';
import { withRegisterHelper } from '../../../hoc/withRegisterHelper';
import {
    cleanupSubmitRegistration,
    combineRegistrationForm,
    initStartPhoneVerification,
    registerUser,
    setActiveStep
} from '../../../../store/reducers/register';
import RegistrationFromTitle from '../../../common/register-new/fragments/RegistrationFromTitle';
import CodeVerification from '../../../common/register-new/fragments/CodeVerification';
import dayjs from 'dayjs';
import PhoneNumber from '../../../common/register-new/fragments/PhoneNumber';
import { CURRENT_DEVICE_MOBILE } from '../../../../variables';
import { getRouterLocale, includeRouterContext } from '../../../../utils/locationConfig';

const RegisterStep4New = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const tr = useSelector((state) => state.global.data.translations);
    const device = useSelector((state) => state.global.currentDevice);
    const currencies = useSelector((state) => state.global.data.currencies);
    const predefinedCommunicationChannels = useSelector((state) => state.user.predefinedCommunicationChannels);
    const { submitRegistration, submitStartPhoneVerification, form, totalSteps } = useSelector((state) => state.register);
    const { isError, error, isLoading } = submitRegistration;
    const userCurrency = countries.find((item) => item.code === form?.country)?.currency;
    const isUserCurrencyEnabled = currencies.find((item) => item === userCurrency?.code);
    const defaultCurrency = isUserCurrencyEnabled ? userCurrency.code : 'EUR';
    const locationData = useSelector((state) => state.global.clientIp.loc);
    const isMobile = device === CURRENT_DEVICE_MOBILE;
    const defaultCountry = useMemo(() => countries.find((country) => country.code === locationData), [locationData]);
    const isFrance = locationData === 'FR';

    useEffect(() => {
        dataLayerPush({ event: 'signup_step4' });
        return () => {
            dispatch(cleanupSubmitRegistration());
        };
    }, []);

    const initialValues = {
        countryPhoneCodePrefix: form.countryPhoneCodePrefix || (defaultCountry?.dial_code ?? ''),
        phone: form.phone
    };

    const setCommunicationChannels = (value) => {
        return predefinedCommunicationChannels.reduce((acc, channel) => {
            acc[channel] = value;
            return acc;
        }, {});
    };

    const onSubmit = () => {
        const { capitalizedLocale } = getRouterLocale(router.locale);
        const { birthDay, birthMonth, birthYear, zipCode, marketingAllowed, ...data } = form;
        const month = String(birthMonth).padStart(2, '0');
        const birthDate = dayjs(`${birthYear}-${month}-${birthDay}`).format('YYYY-MM-DD');
        const communicationChannels = setCommunicationChannels(marketingAllowed);
        dispatch(
            registerUser(
                {
                    ...data,
                    birthDate,
                    communicationChannels: communicationChannels,
                    zipCode: zipCode,
                    currency: defaultCurrency,
                    language: capitalizedLocale,
                    affiliateId: includeRouterContext(getAffiliateDataValue, 'aid'),
                    referralToken: includeRouterContext(getAffiliateDataValue, 'referralToken'),
                    tracker: includeRouterContext(getAffiliateDataValue, 'at')
                },
                tr
            )
        );
    };

    return (
        <div className="register-step">
            <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit}>
                {({ values }) => (
                    <Fragment>
                        <RegistrationFromTitle currentStep={4} totalSteps={totalSteps} />
                        <Form id="register_form_new">
                            {isError && <div className="page-message page-message--error">{error}</div>}
                            {submitStartPhoneVerification.error && (
                                <div className="page-message page-message--error">{submitStartPhoneVerification.error}</div>
                            )}
                            <PhoneNumber isFrance={isFrance} />
                            <CodeVerification size={isMobile ? 68 : 86} onSubmit={onSubmit} />
                            <div className="form--btns">
                                <button
                                    type="button"
                                    className="btn btn-cancel btn-modern"
                                    data-testid="register_step_back"
                                    onClick={() => {
                                        dispatch(setActiveStep(3));
                                        dispatch(combineRegistrationForm({ ...values }));
                                        submitStartPhoneVerification.error && dispatch(initStartPhoneVerification());
                                    }}
                                >
                                    {tr['go.back']}
                                </button>
                            </div>
                        </Form>
                    </Fragment>
                )}
            </Formik>
            {isLoading && (
                <div className="register-step-container">
                    <div className="register-step-loading">{tr['register_loading']}</div>
                </div>
            )}
        </div>
    );
};

export default withRegisterHelper(RegisterStep4New);
