import StatusModalContainer from './StatusModalContainer';
import AnimatedStatus from './AnimatedStatus';

const StatusModal = ({ isOpen, onClose, children, status, title, content, modalClass = '' }) => {
    const handleClose = (event, reason = undefined) => {
        if (reason === 'backdropClick') {
            event.preventDefault();
        } else onClose();
    };

    return (
        <StatusModalContainer isOpen={isOpen} onClose={handleClose} modalClass={modalClass}>
            <div>
                {status && <AnimatedStatus status={status} />}
                <div>
                    <h2>{title}</h2>
                    {content}
                </div>
            </div>
            {children}
        </StatusModalContainer>
    );
};

export default StatusModal;
