import { registerWorkers } from '../../services/http';
import { getUserInformation } from './user';
import { setDomainCookie } from '../../utils/cookieConfig';
import { BAD_REQUEST, COOKIE_NAME_TOKEN, MENU_REGISTER } from '../../variables';
import router from 'next/router';
import routes from '../../utils/routes.json';
import { setGameUrlAfterLogin } from './games';
import {
    countries,
    handlePhoneVerificationError,
    handleRegistrationError,
    dataLayerPush,
    getSeonBase64Session
} from '@tlf-e/brand-utils';

const {
    csrfRegRequest,
    registrationRequest,
    startPhoneVerificationRequest,
    finishPhoneVerificationRequest,
    verifyEmailRequest,
    verifyPhoneNumberRequest,
    phoneCsrfRequest,
    isPlayerCanRegisterRequest
} = registerWorkers;

const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
const REGISTER_USER = 'REGISTER_USER';
const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
const REGISTER_USER_INIT = 'REGISTER_USER_INIT';
const CLEANUP_SUBMIT_REGISTRATION = 'CLEANUP_SUBMIT_REGISTRATION';
const START_PHONE_VERIFICATION = 'START_PHONE_VERIFICATION';
const START_PHONE_VERIFICATION_SUCCESS = 'START_PHONE_VERIFICATION_SUCCESS';
const START_PHONE_VERIFICATION_VERIFIED = 'START_PHONE_VERIFICATION_VERIFIED';
const START_PHONE_VERIFICATION_FAILED = 'START_PHONE_VERIFICATION_FAILED';
const START_PHONE_VERIFICATION_INIT = 'START_PHONE_VERIFICATION_INIT';
const FINISH_PHONE_VERIFICATION = 'FINISH_PHONE_VERIFICATION';
const FINISH_PHONE_VERIFICATION_SUCCESS = 'FINISH_PHONE_VERIFICATION_SUCCESS';
const FINISH_PHONE_VERIFICATION_FAILED = 'FINISH_PHONE_VERIFICATION_FAILED';
const FINISH_PHONE_VERIFICATION_INIT = 'FINISH_PHONE_VERIFICATION_INIT';
const COMBINE_REGISTRATION_FORM = 'COMBINE_REGISTRATION_FORM';
const VERIFY_EMAIL_FIELD = 'VERIFY_EMAIL_FIELD';
const VERIFY_EMAIL_FIELD_PROCESSED = 'VERIFY_EMAIL_FIELD_PROCESSED';
const VERIFY_PHONE_NUMBER_FIELD = 'VERIFY_PHONE_NUMBER_FIELD';
const VERIFY_PHONE_NUMBER_FIELD_PROCESSED = 'VERIFY_PHONE_NUMBER_FIELD_PROCESSED';
const REMOVE_REGISTRATION_IS_ALLOWED_LOADED = 'REMOVE_REGISTRATION_IS_ALLOWED_LOADED';
const CHECK_REGISTRATION_IS_ALLOWED_FAILED = 'CHECK_REGISTRATION_IS_ALLOWED_FAILED';
const CHECK_REGISTRATION_IS_ALLOWED_SUCCESS = 'CHECK_REGISTRATION_IS_ALLOWED_SUCCESS';
const SET_IS_SUSPENDED = 'SET_IS_SUSPENDED';
const CLOSE_ERROR_REGISTER_MODAL = 'CLOSE_ERROR_REGISTER_MODAL';
const SET_REGISTER_FROM_INTERACTED = 'SET_REGISTER_FROM_INTERACTED';
const SET_IS_WELCOME_MODAL_OPENED = 'SET_IS_WELCOME_MODAL_OPENED';
const PHONE_VERIFICATION_INIT_NEW = 'PHONE_VERIFICATION_INIT_NEW';
const INIT_FORM = 'INIT_FORM';
const SET_GENERAL_REGISTRATION_ERROR = 'SET_GENERAL_REGISTRATION_ERROR';
const SET_OTP_RETRY_TIMEOUT = 'SET_OTP_RETRY_TIMEOUT';

const initialState = {
    activeStep: 1,
    isInteracted: false,
    submitRegistration: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
    },
    form: {
        country: '',
        email: '',
        password: '',
        username: '',
        countryPhoneCodePrefix: '',
        phone: '',
        firstname: '',
        lastname: '',
        birthDate: '',
        currency: '',
        gender: '',
        address: '',
        city: '',
        zipCode: '',
        marketingAllowed: false,
        affiliateId: null,
        tracker: null,
        language: '',
        termsConditions: false
    },
    submitStartPhoneVerification: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        isVerified: false,
        error: ''
    },
    submitFinishPhoneVerification: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        isStale: false
    },
    phoneVerification: {
        requestId: null,
        phoneNumber: ''
    },
    emailFieldVerification: {
        isLoading: false,
        isSuccess: false,
        error: '',
        value: ''
    },
    phoneNumberFieldVerification: {
        isLoading: false,
        isSuccess: false,
        error: '',
        value: ''
    },
    isRegistrationAllowed: true,
    isRegistrationAllowedLoaded: false,
    isSuspended: false,
    totalSteps: 4,
    isWelcomeModalOpened: false,
    generalRegistrationError: false,
    otpRetryTimeout: ''
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_ACTIVE_STEP:
        return {
            ...state,
            activeStep: action.payload
        };
    case SET_REGISTER_FROM_INTERACTED:
        return {
            ...state,
            isInteracted: action.payload
        };
    case COMBINE_REGISTRATION_FORM:
        return {
            ...state,
            form: {
                ...state.form,
                ...action.payload
            }
        };
    case REGISTER_USER:
        return {
            ...state,
            submitRegistration: {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        };
    case REGISTER_USER_SUCCESS:
        return {
            ...state,
            form: action.form,
            submitRegistration: {
                isLoading: false,
                isSuccess: true,
                isError: false
            }
        };
    case REGISTER_USER_FAILED:
        return {
            ...state,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        };
    case REGISTER_USER_INIT:
        return {
            ...state,
            activeStep: 1,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: false
            },
            submitStartPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                isVerified: false,
                error: ''
            },
            submitFinishPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                isStale: false
            },
            phoneVerification: {
                requestId: null,
                phoneNumber: ''
            }
        };
    case CLEANUP_SUBMIT_REGISTRATION:
        return {
            ...state,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case START_PHONE_VERIFICATION:
        return {
            ...state,
            submitStartPhoneVerification: {
                isLoading: true,
                isSuccess: false,
                isError: false,
                isVerified: false,
                error: ''
            }
        };
    case START_PHONE_VERIFICATION_SUCCESS:
        return {
            ...state,
            submitStartPhoneVerification: {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isVerified: false,
                error: ''
            },
            phoneVerification: action.payload
        };
    case START_PHONE_VERIFICATION_VERIFIED:
        return {
            ...state,
            submitStartPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                isVerified: true,
                error: ''
            },
            submitFinishPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                isStale: false
            }
        };
    case START_PHONE_VERIFICATION_FAILED:
        return {
            ...state,
            submitStartPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                isVerified: false,
                error: action.payload
            },
            phoneVerification: {
                requestId: null,
                phoneNumber: ''
            }
        };
    case START_PHONE_VERIFICATION_INIT:
        return {
            ...state,
            submitStartPhoneVerification: {
                ...state.submitStartPhoneVerification,
                isLoading: false,
                isSuccess: false,
                isVerified: false,
                isError: false
            },
            phoneVerification: {
                requestId: null,
                phoneNumber: ''
            }
        };
    case FINISH_PHONE_VERIFICATION:
        return {
            ...state,
            submitFinishPhoneVerification: {
                isLoading: true,
                isSuccess: false,
                isError: false,
                isStale: false
            }
        };
    case FINISH_PHONE_VERIFICATION_SUCCESS:
        return {
            ...state,
            submitFinishPhoneVerification: {
                isLoading: false,
                isSuccess: true,
                isError: false,
                isStale: false
            }
        };
    case FINISH_PHONE_VERIFICATION_FAILED:
        return {
            ...state,
            submitFinishPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                isStale: action.payload
            }
        };
    case FINISH_PHONE_VERIFICATION_INIT:
        return {
            ...state,
            submitFinishPhoneVerification: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                isStale: false
            }
        };
    case VERIFY_PHONE_NUMBER_FIELD:
        return {
            ...state,
            phoneNumberFieldVerification: {
                isLoading: true,
                isSuccess: false,
                error: '',
                value: ''
            }
        };
    case VERIFY_PHONE_NUMBER_FIELD_PROCESSED:
        return {
            ...state,
            phoneNumberFieldVerification: action.payload
        };
    case VERIFY_EMAIL_FIELD:
        return {
            ...state,
            emailFieldVerification: {
                isLoading: true,
                isSuccess: false,
                error: '',
                value: ''
            }
        };
    case VERIFY_EMAIL_FIELD_PROCESSED:
        return {
            ...state,
            emailFieldVerification: action.payload
        };
    case CHECK_REGISTRATION_IS_ALLOWED_SUCCESS:
        return {
            ...state,
            isRegistrationAllowed: action.payload,
            isRegistrationAllowedLoaded: true
        };
    case CHECK_REGISTRATION_IS_ALLOWED_FAILED:
        return {
            ...state,
            isRegistrationAllowed: false,
            isRegistrationAllowedLoaded: true
        };
    case REMOVE_REGISTRATION_IS_ALLOWED_LOADED:
        return {
            ...state,
            isRegistrationAllowedLoaded: false
        };
    case SET_IS_SUSPENDED:
        return {
            ...state,
            isSuspended: action.payload
        };
    case CLOSE_ERROR_REGISTER_MODAL:
        return {
            ...state,
            submitRegistration: {
                ...state.submitRegistration,
                isError: false
            }
        };
    case SET_IS_WELCOME_MODAL_OPENED:
        return {
            ...state,
            isWelcomeModalOpened: action.payload
        };
    case PHONE_VERIFICATION_INIT_NEW:
        return {
            ...state,
            submitStartPhoneVerification: {
                ...state.submitStartPhoneVerification,
                isLoading: false,
                isSuccess: false,
                isVerified: false,
                isError: false
            }
        };
    case INIT_FORM:
        return {
            ...state,
            form: {
                country: '',
                email: '',
                password: '',
                username: '',
                countryPhoneCodePrefix: '',
                phone: '',
                firstname: '',
                lastname: '',
                birthDate: '',
                currency: '',
                gender: '',
                address: '',
                city: '',
                zipCode: '',
                marketingAllowed: false,
                affiliateId: null,
                tracker: null,
                language: '',
                termsConditions: false
            }
        };
    case SET_GENERAL_REGISTRATION_ERROR:
        return {
            ...state,
            generalRegistrationError: action.payload
        };
    case SET_OTP_RETRY_TIMEOUT:
        return {
            ...state,
            otpRetryTimeout: action.payload
        };
    default:
        return state;
    }
};

export const setActiveStep = (payload) => ({
    type: SET_ACTIVE_STEP,
    payload
});

export const combineRegistrationForm = (payload) => ({
    type: COMBINE_REGISTRATION_FORM,
    payload
});

export const registerUser = (data) => {
    const { email, username, address, country, phone, countryPhoneCodePrefix, zipCode, city, password } = data;
    const phoneNumber = countryPhoneCodePrefix + phone;
    const loading = () => ({
        type: REGISTER_USER
    });
    const success = (payload, form) => ({
        type: REGISTER_USER_SUCCESS,
        payload,
        form
    });
    const fail = (payload) => ({
        type: REGISTER_USER_FAILED,
        payload
    });

    dataLayerPush({
        event: 'Signup_Submission',
        sub_email: email,
        sub_username: username,
        sub_address: address,
        sub_country: country,
        sub_Phone_number: phoneNumber,
        sub_zipCode: zipCode,
        sub_city: city
    });
    const csrfData = {
        email: email,
        password: password
    };

    const regRequestFunc = (session = {}) => {
        return (dispatch, getState) => {
            csrfRegRequest(csrfData)
                .then((token) =>
                    registrationRequest({ ...data, ...session, csrf: token.data.data.token })
                        .then(async (res) => {
                            await setDomainCookie(COOKIE_NAME_TOKEN, res.data.data.token);
                            dispatch(success(res.data, data));
                            dataLayerPush({ event: 'signup_success' });
                            dispatch(getUserInformation(res.data.data.token, data.language, 'firstLogin'));
                            router.push(routes.home).finally(() => {
                                dispatch(initForm());
                                dispatch(initFinishPhoneVerification());
                                dispatch(setIsWelcomeModalOpened(true));
                                dispatch(initRegisterUser());
                            });
                        })
                        .catch((err) => {
                            if (err.status === 401) {
                                router.push(routes.home);
                                return dispatch(setIsSuspended(true));
                            }

                            dataLayerPush({
                                event: 'signup_failure',
                                sub_address: address,
                                sub_country: country,
                                sub_Phone_number: phoneNumber,
                                sub_zipCode: zipCode,
                                sub_city: city
                            });

                            const tr = getState().global.data.translations;

                            if (err.status === 500) {
                                dispatch(fail(''));
                                return dispatch(
                                    setGeneralRegistrationError({
                                        title: tr['registration.general.error.title'],
                                        details: tr['registration.general.error.details']
                                    })
                                );
                            }

                            const error = handleRegistrationError(err, tr);
                            dispatch(fail(error));
                            window.scrollTo(0, 0);
                        })
                )
                .catch(() => dispatch(fail()));
        };
    };

    return (dispatch) => {
        dispatch(loading());
        getSeonBase64Session(MENU_REGISTER)
            .then(({ seonBase64Session }) => {
                dispatch(regRequestFunc({ seonSession: seonBase64Session }));
            })
            .catch(() => {
                dataLayerPush({ event: 'getSeonBase64Session_error', error: 'on_registration' });
                dispatch(regRequestFunc());
            });
    };
};

export const setOtpRetryTimeout = (timeout) => ({
    type: SET_OTP_RETRY_TIMEOUT,
    payload: timeout
});

export const successVerified = () => ({
    type: START_PHONE_VERIFICATION_VERIFIED
});

// TODO This function is for new Registration Form/Flow
export const startPhoneVerificationNew = (data) => {
    const loading = () => ({
        type: START_PHONE_VERIFICATION
    });
    const successStart = (payload) => ({
        type: START_PHONE_VERIFICATION_SUCCESS,
        payload
    });
    const successFinish = () => ({
        type: FINISH_PHONE_VERIFICATION_SUCCESS
    });
    const fail = (payload) => ({
        type: START_PHONE_VERIFICATION_FAILED,
        payload
    });
    return (dispatch, getState) => {
        const tr = getState().global.data.translations;
        const defaultError = tr['register.error'];
        const phoneNumber = `${data.prefix}${data.number}`;
        const countryCode = countries.find((item) => item.dial_code === data.prefix)?.code;
        const dataLayerPushEventData = {
            event: 'signup_request_OTP',
            country_code: countryCode,
            PhoneNumber: phoneNumber
        };
        const csrfData = {
            prefix: data.prefix,
            number: data.number
        };

        dispatch(loading());
        phoneCsrfRequest(csrfData).then((token) =>
            startPhoneVerificationRequest({ ...data, csrf: token.data.data.token })
                .then((res) => {
                    if (res.data.data.verified) {
                        dispatch(successFinish());
                        dispatch(successVerified());
                        dataLayerPush({
                            ...dataLayerPushEventData,
                            valid: true
                        });
                    } else if (res.data.data.requestId) {
                        dispatch(
                            successStart({
                                requestId: res.data.data.requestId,
                                phoneNumber
                            })
                        );
                        dataLayerPush({
                            ...dataLayerPushEventData,
                            valid: true
                        });
                    } else {
                        const error = defaultError;
                        dispatch(fail(error));
                        window.scrollTo(0, 0);
                        dataLayerPush({
                            ...dataLayerPushEventData,
                            valid: false,
                            error: error
                        });
                    }
                })
                .catch((err) => {
                    const error = handlePhoneVerificationError(err, tr, defaultError);
                    dispatch(fail(error));
                    window.scrollTo(0, 0);
                    dataLayerPush({
                        ...dataLayerPushEventData,
                        valid: false,
                        error: error
                    });
                })
        );
    };
};

// TODO This function is for new Registration Form/Flow
export const finishPhoneVerificationNew = (data, { cleanError, setError, onSuccess }) => {
    const loading = () => ({
        type: FINISH_PHONE_VERIFICATION
    });
    const success = () => ({
        type: FINISH_PHONE_VERIFICATION_SUCCESS
    });
    const fail = (payload) => ({
        type: FINISH_PHONE_VERIFICATION_FAILED,
        payload
    });
    return (dispatch) => {
        const dataLayerPushEventData = {
            event: 'signup_request_OTP'
        };
        dispatch(loading());
        finishPhoneVerificationRequest(data)
            .then((res) => {
                if (res.data.data.verified) {
                    onSuccess();
                    dispatch(success());
                    cleanError();
                    dataLayerPush({
                        ...dataLayerPushEventData,
                        valid: true
                    });
                } else {
                    dispatch(fail(true));
                    setError();
                    dataLayerPush({
                        ...dataLayerPushEventData,
                        valid: false
                    });
                }
            })
            .catch((err) => {
                const isStale =
                    Array.isArray(err.data?.errors) && err.data.errors[0]?.description === 'Verification is stale.';
                dispatch(fail(isStale));
                setError();
                dataLayerPush({
                    ...dataLayerPushEventData,
                    valid: false
                });
            });
    };
};

export const initRegisterUser = () => ({
    type: REGISTER_USER_INIT
});

export const cleanupSubmitRegistration = () => ({
    type: CLEANUP_SUBMIT_REGISTRATION
});

export const initStartPhoneVerification = () => ({
    type: START_PHONE_VERIFICATION_INIT
});

export const initPhoneVerification = () => ({
    type: PHONE_VERIFICATION_INIT_NEW
});

export const initFinishPhoneVerification = () => ({
    type: FINISH_PHONE_VERIFICATION_INIT
});

export const processEmailVerification = (payload) => ({
    type: VERIFY_EMAIL_FIELD_PROCESSED,
    payload
});

export const loadingEmailFieldVerification = () => ({
    type: VERIFY_EMAIL_FIELD
});

export const verifyEmailField = (data) => {
    const payload = (isSuccess, error) => ({
        isLoading: false,
        isSuccess,
        error,
        value: data.email
    });

    return (dispatch, getState) => {
        const tr = getState().global.data.translations;
        dispatch(loadingEmailFieldVerification());
        verifyEmailRequest(data)
            .then((res) => {
                const { valid, messages } = res.data.data;
                const error = valid ? '' : tr[`${messages[0] || BAD_REQUEST}`];
                dispatch(processEmailVerification(payload(valid, error)));
            })
            .catch(() => {
                const error = tr['register.form.email.error.invalid'];
                dispatch(processEmailVerification(payload(false, error)));
            });
    };
};

export const processPhoneNumberVerification = (payload) => ({
    type: VERIFY_PHONE_NUMBER_FIELD_PROCESSED,
    payload
});

export const verifyPhoneNumberField = (number, prefix, error) => {
    const loading = () => ({
        type: VERIFY_PHONE_NUMBER_FIELD
    });
    return (dispatch) => {
        const phoneNumber = `${prefix}${number}`;
        const data = {
            phoneNumber: phoneNumber,
            type: 'string'
        };
        dispatch(loading());
        verifyPhoneNumberRequest(data)
            .then((res) => {
                const errorData = res.data.data.valid ? '' : error;
                dispatch(
                    processPhoneNumberVerification({
                        isLoading: false,
                        isSuccess: res.data.data.valid,
                        error: errorData,
                        value: phoneNumber
                    })
                );
            })
            .catch(() => {
                const errorData = '';
                dispatch(
                    processPhoneNumberVerification({
                        isLoading: false,
                        isSuccess: false,
                        error: errorData,
                        value: phoneNumber
                    })
                );
            });
    };
};

export const checkRegistrationIsAllowed = (aid, loc, referralToken) => {
    const success = (payload) => ({
        type: CHECK_REGISTRATION_IS_ALLOWED_SUCCESS,
        payload
    });
    return (dispatch) => {
        const data = {
            affiliateId: aid,
            referralToken: referralToken,
            country: loc
        };

        // block registration in case of broken country data
        if (!loc) {
            return dispatch(checkRegistrationIsAllowedFailed());
        }

        isPlayerCanRegisterRequest(data)
            .then((res) => {
                const isRegistrationAllowed = res.data.allow;
                !isRegistrationAllowed && dataLayerPush({ event: 'signup_blocked' });
                dispatch(success(isRegistrationAllowed));
                dispatch(setGameUrlAfterLogin(''));
            })
            .catch(() => dispatch(checkRegistrationIsAllowedFailed()));
    };
};

export const checkRegistrationIsAllowedFailed = () => {
    const fail = () => ({
        type: CHECK_REGISTRATION_IS_ALLOWED_FAILED
    });
    return (dispatch) => {
        dataLayerPush({ event: 'signup_blocked' });
        dispatch(fail());
        dispatch(setGameUrlAfterLogin(''));
    };
};

export const removeRegistrationAllowedLoaded = () => ({
    type: REMOVE_REGISTRATION_IS_ALLOWED_LOADED
});

export const setIsSuspended = (payload) => ({
    type: SET_IS_SUSPENDED,
    payload
});

export const closeErrorRegisterModal = () => ({
    type: CLOSE_ERROR_REGISTER_MODAL
});

export const setIsInteracted = (payload) => ({
    type: SET_REGISTER_FROM_INTERACTED,
    payload
});

export const setIsWelcomeModalOpened = (payload) => ({
    type: SET_IS_WELCOME_MODAL_OPENED,
    payload
});

export const initForm = () => ({
    type: INIT_FORM
});

export const setGeneralRegistrationError = (payload) => ({
    type: SET_GENERAL_REGISTRATION_ERROR,
    payload
});
