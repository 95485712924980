const EyeOpen = () => (
    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.7143 5C14.9967 7.29167 12.2243 8.88889 9 8.88889C5.77567 8.88889 3.00335 7.29167 1.28571 5C2.23996 3.72396 3.54576 2.63021 5.11272 1.93576C4.71094 2.52604 4.5 3.20313 4.5 3.88889C4.5 6.03299 6.51897 7.77778 9 7.77778C11.481 7.77778 13.5 6.03299 13.5 3.88889C13.5 3.20313 13.2891 2.52604 12.8873 1.93576C14.4542 2.63021 15.76 3.72396 16.7143 5ZM9.48214 1.66667C9.48214 1.89236 9.26116 2.08333 9 2.08333C7.85491 2.08333 6.91071 2.89931 6.91071 3.88889C6.91071 4.11458 6.68973 4.30556 6.42857 4.30556C6.16741 4.30556 5.94643 4.11458 5.94643 3.88889C5.94643 2.43924 7.32255 1.25 9 1.25C9.26116 1.25 9.48214 1.44097 9.48214 1.66667ZM18 5C18 4.78299 17.9196 4.58333 17.7991 4.40104C15.9509 1.77951 12.5658 0 9 0C5.43415 0 2.04911 1.77951 0.200893 4.40104C0.0803571 4.58333 0 4.78299 0 5C0 5.21701 0.0803571 5.41667 0.200893 5.59896C2.04911 8.22049 5.43415 10 9 10C12.5658 10 15.9509 8.22917 17.7991 5.59896C17.9196 5.41667 18 5.21701 18 5Z"
            fill="#301600"
            fillOpacity="0.5"
        />
    </svg>
);

export default EyeOpen;
