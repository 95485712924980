const Promotions = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.3613 16.8698C20.9052 11.9419 20.9052 6.97414 20.3613 2.04627C20.1673 0.289014 18.0177 -0.601125 16.5134 0.452871L11.4967 3.96774C9.96805 5.03878 8.11399 5.61715 6.20925 5.61715H1.52811C0.684158 5.61715 0 6.26201 0 7.05748V11.8586C0 12.654 0.684156 13.2989 1.52811 13.2989H2.29128L1.25784 16.9342C1.14268 17.3393 1.35272 17.7638 1.75597 17.9411L6.25613 19.919C6.50175 20.027 6.78547 20.027 7.0311 19.919C7.27673 19.8111 7.4591 19.6062 7.52924 19.3595L9.07287 13.9295C9.08926 13.8719 9.09907 13.814 9.10271 13.7566C9.95537 14.0342 10.7635 14.4346 11.4967 14.9483L16.5134 18.4632C18.0177 19.5172 20.1673 18.627 20.3613 16.8698ZM18.5374 2.22514C19.0682 7.03408 19.0682 11.882 18.5374 16.6909C18.4905 17.1157 17.9709 17.3308 17.6073 17.076L12.5907 13.5612C10.7457 12.2685 8.50807 11.5705 6.20925 11.5705L1.83373 11.5705L1.83373 7.34554H6.20925C8.50807 7.34554 10.7457 6.64751 12.5907 5.35487L17.6073 1.84C17.9709 1.58525 18.4905 1.8004 18.5374 2.22514ZM7.34342 13.3676C6.96885 13.322 6.59006 13.2989 6.20925 13.2989H4.1897L3.22821 16.6811L6.0408 17.9173L7.30162 13.4822C7.31289 13.4425 7.3269 13.4043 7.34342 13.3676Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M27.5345 0.059122C28.0609 -0.142508 28.6288 0.188248 28.8029 0.797886C29.5799 3.51845 30 6.4258 30 9.44445C30 12.4631 29.5799 15.3704 28.8029 18.091C28.6288 18.7006 28.0609 19.0314 27.5345 18.8298C27.0081 18.6281 26.7225 17.9705 26.8966 17.3608C27.6071 14.8729 27.9921 12.2119 27.9921 9.44445C27.9921 6.677 27.6071 4.01602 26.8966 1.52805C26.7225 0.918416 27.0081 0.260752 27.5345 0.059122Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M25.0235 3.16252C24.8323 2.5598 24.2553 2.25072 23.7349 2.47217C23.2145 2.69362 22.9476 3.36175 23.1388 3.96447C23.6803 5.67115 23.9763 7.51645 23.9763 9.44444C23.9763 11.0436 23.7726 12.5857 23.3943 14.0387C23.3733 14.1197 23.3516 14.2004 23.3295 14.2808C23.2698 14.4974 23.2062 14.712 23.1388 14.9244C22.9476 15.5271 23.2145 16.1953 23.7349 16.4167C24.2553 16.6382 24.8323 16.3291 25.0235 15.7264C25.1008 15.4826 25.1738 15.2363 25.2423 14.9877C25.2677 14.8955 25.2926 14.8029 25.3167 14.71C25.7511 13.0416 25.9842 11.2735 25.9842 9.44444C25.9842 7.23881 25.6452 5.12225 25.0235 3.16252Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default Promotions;
