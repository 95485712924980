const AllLiveGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4ZM2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8C0.895431 8 0 8.89543 0 10C0 11.1046 0.895431 12 2 12ZM4 18C4 19.1046 3.10457 20 2 20C0.895431 20 0 19.1046 0 18C0 16.8954 0.895431 16 2 16C3.10457 16 4 16.8954 4 18ZM10 4C11.1046 4 12 3.10457 12 2C12 0.895431 11.1046 0 10 0C8.89543 0 8 0.895431 8 2C8 3.10457 8.89543 4 10 4ZM12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10ZM10 20C11.1046 20 12 19.1046 12 18C12 16.8954 11.1046 16 10 16C8.89543 16 8 16.8954 8 18C8 19.1046 8.89543 20 10 20ZM20 2C20 3.10457 19.1046 4 18 4C16.8954 4 16 3.10457 16 2C16 0.895431 16.8954 0 18 0C19.1046 0 20 0.895431 20 2ZM18 12C19.1046 12 20 11.1046 20 10C20 8.89543 19.1046 8 18 8C16.8954 8 16 8.89543 16 10C16 11.1046 16.8954 12 18 12ZM20 18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18C16 16.8954 16.8954 16 18 16C19.1046 16 20 16.8954 20 18ZM26 4C27.1046 4 28 3.10457 28 2C28 0.895431 27.1046 0 26 0C24.8954 0 24 0.895431 24 2C24 3.10457 24.8954 4 26 4ZM28 10C28 11.1046 27.1046 12 26 12C24.8954 12 24 11.1046 24 10C24 8.89543 24.8954 8 26 8C27.1046 8 28 8.89543 28 10ZM26 20C27.1046 20 28 19.1046 28 18C28 16.8954 27.1046 16 26 16C24.8954 16 24 16.8954 24 18C24 19.1046 24.8954 20 26 20Z"
            fill="#301600"
        />
    </svg>
);

export default AllLiveGames;
