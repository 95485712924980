import { useDispatch, useSelector } from 'react-redux';
import { MENU_LOGIN, MODAL_STATUS_SUCCESS } from '../../../../variables';
import StatusModal from '../../StatusModal';
import Link from 'next/link';
import { getMenuUrl } from '../../../../utils/locationConfig';
import { useRouter } from 'next/router';
import { initSubmitNewPassword } from '../../../../store/reducers/user';

const NewPasswordSuccessModal = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { submitNewPassword } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const router = useRouter();

    const onClose = () => {
        dispatch(initSubmitNewPassword());
    };

    return (
        <StatusModal
            isOpen={submitNewPassword.isSuccess}
            status={MODAL_STATUS_SUCCESS}
            title={tr['modal.well_done']}
            onClose={onClose}
            content={
                <div className="wdr-success-content">
                    <div>
                        <div>{tr['new_password_request_success_context']}</div>
                    </div>
                </div>
            }
        >
            <div className="container-btns">
                <Link {...getMenuUrl(MENU_LOGIN, router)}>
                    <a className="btn btn-submit btn-large btn-modern" data-testid="continue_playing" onClick={onClose}>
                        {tr['header.login']}
                    </a>
                </Link>
            </div>
        </StatusModal>
    );
};

export default NewPasswordSuccessModal;
