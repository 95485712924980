const scroll_thumb = 'rgba(60, 11, 108, 0.30)';
const scroll_track = 'rgba(255, 255, 255, 0.40)';
const white = 'rgb(255, 255, 255)';

export const customScrollbarStyles = `
    /* total width */
    ::-webkit-scrollbar {
      background-color: ${white};
      width:6px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: ${scroll_track};
      border-radius: 3px;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: ${scroll_thumb};
      border-radius: 3px;
      border: none;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }

    /* Customizing for Firefox */
    :root {
      scrollbar-color: ${scroll_thumb} ${scroll_track};
      scrollbar-width: thin;
    }
`;
