import { imageBaseUrl } from '../../services/http';

const AnimatedStatus = ({ status }) => {
    return (
        <picture>
            <source srcSet={`${imageBaseUrl}/${status}_animation.webp`} type="image/webp" />
            <source srcSet={`${imageBaseUrl}/${status}_animation.png`} type="image/apng" />
            <img src={`${imageBaseUrl}/${status}_animation.png`} alt={status} />
        </picture>
    );
};

export default AnimatedStatus;
