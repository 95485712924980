const Profile = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.2035 5.83779C11.2035 3.84097 12.9032 2.22222 15 2.22222C17.0968 2.22222 18.7965 3.84097 18.7965 5.83779C18.7965 7.83461 17.0968 9.45335 15 9.45335C12.9032 9.45335 11.2035 7.83461 11.2035 5.83779ZM15 3.4983C13.6433 3.4983 12.5434 4.54573 12.5434 5.83779C12.5434 7.12985 13.6433 8.17727 15 8.17727C16.3567 8.17727 17.4566 7.12985 17.4566 5.83779C17.4566 4.54573 16.3567 3.4983 15 3.4983Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4268 12.0055C10.3167 12.0055 9.41687 12.8625 9.41687 13.9196V14.9305C9.41687 14.9459 9.42859 14.9591 9.44454 14.9615C13.1238 15.5336 16.8762 15.5336 20.5555 14.9615C20.5714 14.9591 20.5831 14.9459 20.5831 14.9305V13.9196C20.5831 12.8625 19.6833 12.0055 18.5732 12.0055H18.2687C18.2452 12.0055 18.2218 12.0091 18.1994 12.016L17.4262 12.2565C15.8497 12.7467 14.1503 12.7467 12.5738 12.2565L11.8006 12.016C11.7782 12.0091 11.7548 12.0055 11.7313 12.0055H11.4268ZM8.07692 13.9196C8.07692 12.1577 9.57671 10.7294 11.4268 10.7294H11.7313C11.8961 10.7294 12.0599 10.7543 12.2165 10.803L12.9897 11.0434C14.296 11.4496 15.704 11.4496 17.0103 11.0434L17.7835 10.803C17.9401 10.7543 18.1039 10.7294 18.2687 10.7294H18.5732C20.4233 10.7294 21.9231 12.1577 21.9231 13.9196V14.9305C21.9231 15.5713 21.4354 16.1177 20.7714 16.2209C16.9491 16.8152 13.0509 16.8152 9.22863 16.2209C8.56456 16.1177 8.07692 15.5713 8.07692 14.9305V13.9196Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.29814 0.337601C6.46772 -0.112534 23.5323 -0.112534 26.7019 0.337601C28.2733 0.560779 29.4841 1.82128 29.6279 3.38388L29.7641 4.86306C30.0786 8.28063 30.0786 11.7194 29.7641 15.1369L29.6279 16.6161C29.4841 18.1787 28.2733 19.4392 26.7019 19.6624C23.5323 20.1125 6.46772 20.1125 3.29814 19.6624C1.72666 19.4392 0.515905 18.1787 0.372076 16.6161L0.235926 15.1369C-0.0786419 11.7194 -0.0786421 8.28063 0.235926 4.86306L0.372076 3.38388C0.515905 1.82128 1.72666 0.560779 3.29814 0.337601ZM26.4731 1.91112C23.4552 1.48253 6.54476 1.48253 3.52692 1.91112C2.69288 2.02957 2.05028 2.69856 1.97395 3.5279L1.8378 5.00708C1.53205 8.32883 1.53205 11.6712 1.8378 14.9929L1.97395 16.4721C2.05028 17.3014 2.69288 17.9704 3.52692 18.0889C6.54476 18.5175 23.4552 18.5175 26.4731 18.0889C27.3071 17.9704 27.9497 17.3014 28.0261 16.4721L28.1622 14.9929C28.468 11.6712 28.468 8.32883 28.1622 5.00707L28.0261 3.5279C27.9497 2.69856 27.3071 2.02957 26.4731 1.91112Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default Profile;
