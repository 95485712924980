import { MENU_FORGOT_PASSWORD, MENU_GENERAL, MENU_REGISTER } from '../../../../variables';
import { initSubmitLoginUser, loginUser } from '../../../../store/reducers/user';
import { setGameUrlAfterLogin } from '../../../../store/reducers/games';
import { useSSRContext } from '../../../../hooks/useSSRContext';
import { getMenuUrl } from '../../../../utils/locationConfig';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import FormErrorMessage from '../../FormErrorMessage';
import routes from '../../../../utils/routes.json';
import EyeOpen from '../../../svg-icons/EyeOpen';
import Link from 'next/link';
import cx from 'classnames';
import * as yup from 'yup';

const LoginForm = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const { gameUrlAfterLogin } = useSelector((state) => state.games);
    const { currentDevice } = useSelector((state) => state.global);
    const { apiError, isLoading, isError } = useSelector((state) => state.user.submitLogin);
    const [showPassword, setShowPassword] = useState(true);
    const { isGamePage } = useSSRContext();
    const dispatch = useDispatch();
    const router = useRouter();

    const validationSchema = yup.object({
        email: yup.string().required(tr['login_form.error.fill_error']).min(5, tr['login_form.error.fill_error']),
        password: yup.string().required(tr['login_form.error.fill_error'])
    });

    useEffect(() => {
        return () => dispatch(initSubmitLoginUser());
    }, []);

    const successCallback = (condition) => {
        if (!condition && gameUrlAfterLogin) {
            router.push(gameUrlAfterLogin);
        } else {
            router.push({
                pathname: routes.home,
                query: {
                    ...router.query,
                    menu: MENU_GENERAL
                }
            });
        }
        dispatch(setGameUrlAfterLogin(''));
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={(values) => {
                dispatch(loginUser(values, currentDevice, successCallback, isGamePage));
            }}
        >
            {({ touched, errors }) => (
                <div className="form-container">
                    <div className="form--title">{tr['header.login_form.login']}</div>
                    <Form className="login-form">
                        {isError && <div className="page-message page-message--error">{apiError}</div>}
                        <div
                            className={cx('form-group', {
                                'form-group--error-marked': touched.email && errors.email
                            })}
                        >
                            <label className="section-title" htmlFor="email">
                                {tr['header.login_form.email']}
                            </label>
                            <Field name="email" autocomplete="off">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="email"
                                        id="email"
                                        className="text-field"
                                        data-testid="user_email"
                                    />
                                )}
                            </Field>
                            <FormErrorMessage name="email" />
                        </div>
                        <div
                            className={cx('form-group password', {
                                'form-group--error-marked': touched.password && errors.password
                            })}
                        >
                            <label className="section-title" htmlFor="password">
                                {tr['header.login_form.password']}
                            </label>
                            <Field name="password">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type={!showPassword ? 'text' : 'password'}
                                        className="text-field"
                                        data-testid="user_password"
                                        autoComplete="off"
                                        id="password"
                                    />
                                )}
                            </Field>
                            <FormErrorMessage name="password" />
                            <div
                                className={cx('reveal-password', {
                                    hide: showPassword
                                })}
                                data-testid="show_password"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <EyeOpen />
                                <span />
                            </div>
                        </div>
                        <div className="form-group forgot">
                            <Link {...getMenuUrl(MENU_FORGOT_PASSWORD, router)}>
                                <a data-testid="forgot_password">{tr['header.login_form.forgot_password']}</a>
                            </Link>
                        </div>
                        <div className="form--btns">
                            <Link {...getMenuUrl(MENU_REGISTER, router)}>
                                <a className="btn btn-cancel btn-modern">{tr['header.register']}</a>
                            </Link>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className="btn btn-submit btn-modern"
                                data-testid="submit_login"
                            >
                                {isLoading ? tr['global.loading'] : tr['header.login_form.login']}
                            </button>
                        </div>
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default LoginForm;
