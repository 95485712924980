const ReduceScreen = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M10.31 15C9.89 15 9.61 14.72 9.61 14.3V12.55C9.61 10.87 10.94 9.54 12.62 9.54H14.3C14.72 9.54 15 9.82 15 10.24C15 10.66 14.72 10.94 14.3 10.94H12.55C11.64 10.94 10.94 11.64 10.94 12.55V14.3C11.01 14.72 10.66 15 10.31 15Z"
            fill="#301600"
        />
        <path
            d="M5.69 15C5.27 15 4.99 14.72 4.99 14.3V12.55C4.99 11.64 4.29 10.94 3.38 10.94H1.7C1.28 10.94 1 10.66 1 10.24C1 9.82 1.28 9.54 1.7 9.54H3.45C5.13 9.54 6.46 10.87 6.46 12.55V14.3C6.39 14.72 6.11 15 5.69 15Z"
            fill="#301600"
        />
        <path
            d="M3.45 6.39H1.7C1.28 6.39 1 6.11 1 5.69C1 5.27 1.28 4.99 1.7 4.99H3.45C4.36 4.99 5.06 4.29 5.06 3.38V1.7C5.06 1.28 5.34 1 5.76 1C6.18 1 6.46 1.28 6.46 1.7V3.45C6.39 5.06 5.06 6.39 3.45 6.39Z"
            fill="#301600"
        />
        <path
            d="M14.3 6.39H12.55C10.87 6.39 9.54 5.06 9.54 3.38V1.7C9.54 1.28 9.82 1 10.24 1C10.66 1 10.94 1.28 10.94 1.7V3.45C10.94 4.36 11.64 5.06 12.55 5.06H14.3C14.72 5.06 15 5.34 15 5.76C15 6.18 14.72 6.39 14.3 6.39Z"
            fill="#301600"
        />
        <path
            d="M10.31 15C9.89 15 9.61 14.72 9.61 14.3V12.55C9.61 10.87 10.94 9.54 12.62 9.54H14.3C14.72 9.54 15 9.82 15 10.24C15 10.66 14.72 10.94 14.3 10.94H12.55C11.64 10.94 10.94 11.64 10.94 12.55V14.3C11.01 14.72 10.66 15 10.31 15Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M5.69 15C5.27 15 4.99 14.72 4.99 14.3V12.55C4.99 11.64 4.29 10.94 3.38 10.94H1.7C1.28 10.94 1 10.66 1 10.24C1 9.82 1.28 9.54 1.7 9.54H3.45C5.13 9.54 6.46 10.87 6.46 12.55V14.3C6.39 14.72 6.11 15 5.69 15Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M3.45 6.39H1.7C1.28 6.39 1 6.11 1 5.69C1 5.27 1.28 4.99 1.7 4.99H3.45C4.36 4.99 5.06 4.29 5.06 3.38V1.7C5.06 1.28 5.34 1 5.76 1C6.18 1 6.46 1.28 6.46 1.7V3.45C6.39 5.06 5.06 6.39 3.45 6.39Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
        <path
            d="M14.3 6.39H12.55C10.87 6.39 9.54 5.06 9.54 3.38V1.7C9.54 1.28 9.82 1 10.24 1C10.66 1 10.94 1.28 10.94 1.7V3.45C10.94 4.36 11.64 5.06 12.55 5.06H14.3C14.72 5.06 15 5.34 15 5.76C15 6.18 14.72 6.39 14.3 6.39Z"
            stroke="#301600"
            strokeWidth="0.5"
        />
    </svg>
);

export default ReduceScreen;
