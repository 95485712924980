import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { isMobile } from 'react-device-detect';

const Notification = ({
    children,
    isOpen,
    onClose,
    status,
    showTimer,
    autoHideDuration = 5000,
    rootClass = '',
    ...props
}) => {
    return (
        <Snackbar
            open={isOpen}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
            resumeHideDuration={autoHideDuration} //Use resumeHideDuration to override default behavior on closing due to simulated interactions
            TransitionComponent={Slide}
            TransitionProps={{ direction: isMobile ? 'down' : 'up' }}
            classes={{ root: rootClass }}
            {...props}
        >
            <div className={`toaster-notification toaster-notification--${status}`}>
                {children({ autoHideCountdown: showTimer ? autoHideDuration : 0, onClose })}
            </div>
        </Snackbar>
    );
};

export default Notification;
