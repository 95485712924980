import { LOCAL_STORAGE_REALITY_CHECK_DATE, LOCAL_STORAGE_REALITY_CHECK_SESSION_LENGTH } from '../variables';

export const isLocalStorageAvailable = () => {
    let test = 'test';
    try {
        window.localStorage.setItem(test, test);
        window.localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
};

export const setRealityCheckData = (date, sessionLength) => {
    localStorage.setItem(LOCAL_STORAGE_REALITY_CHECK_DATE, date);
    localStorage.setItem(LOCAL_STORAGE_REALITY_CHECK_SESSION_LENGTH, sessionLength);
};
