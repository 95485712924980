const LiveCasino = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="20" viewBox="0 0 38 20" fill="none">
        <path
            d="M35.6119 0.0625998C36.1218 -0.150891 36.6718 0.199321 36.8405 0.844821C37.5931 3.72542 38 6.80379 38 10C38 13.1962 37.5931 16.2746 36.8405 19.1552C36.6718 19.8007 36.1218 20.1509 35.6119 19.9374C35.102 19.7239 34.8253 19.0276 34.994 18.3821C35.6822 15.7477 36.0551 12.9302 36.0551 10C36.0551 7.06977 35.6822 4.25226 34.994 1.61794C34.8253 0.97244 35.102 0.276091 35.6119 0.0625998Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M33.1796 3.34855C32.9944 2.71038 32.4356 2.38312 31.9315 2.61759C31.4273 2.85207 31.1688 3.5595 31.354 4.19767C31.8785 6.00474 32.1653 7.95859 32.1653 9.99999C32.1653 11.6933 31.968 13.326 31.6016 14.8645C31.5812 14.9503 31.5602 15.0357 31.5387 15.1208C31.4809 15.3502 31.4193 15.5774 31.354 15.8023C31.1688 16.4405 31.4273 17.1479 31.9315 17.3824C32.4356 17.6169 32.9944 17.2896 33.1796 16.6514C33.2545 16.3933 33.3252 16.1326 33.3916 15.8694C33.4162 15.7717 33.4402 15.6736 33.4637 15.5752C33.8844 13.8087 34.1102 11.9366 34.1102 9.99999C34.1102 7.66462 33.7819 5.42356 33.1796 3.34855Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M2.38813 19.9374C1.87823 20.1509 1.32815 19.8007 1.15951 19.1552C0.406922 16.2746 -2.81066e-07 13.1962 0 10C2.81066e-07 6.80378 0.406923 3.72541 1.15951 0.844822C1.32816 0.199321 1.87823 -0.15089 2.38813 0.0626009C2.89803 0.276091 3.17468 0.972442 3.00604 1.61794C2.31779 4.25226 1.94489 7.06977 1.94489 10C1.94489 12.9302 2.31779 15.7477 3.00603 18.3821C3.17468 19.0276 2.89803 19.7239 2.38813 19.9374Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M4.82039 16.6514C5.00561 17.2896 5.56443 17.6169 6.06855 17.3824C6.57266 17.1479 6.83118 16.4405 6.64596 15.8023C6.12148 13.9953 5.83471 12.0414 5.83471 10C5.83471 8.30673 6.03201 6.67399 6.39843 5.13548C6.41885 5.04975 6.43979 4.96431 6.46125 4.87918C6.51907 4.64984 6.58067 4.42264 6.64596 4.19769C6.83118 3.55951 6.57267 2.85208 6.06855 2.61761C5.56443 2.38313 5.00561 2.71039 4.82039 3.34856C4.74546 3.6067 4.67478 3.86743 4.60843 4.13062C4.58379 4.22834 4.55976 4.32638 4.53633 4.42476C4.1156 6.19127 3.88981 8.06336 3.88981 10C3.88981 12.3354 4.21814 14.5764 4.82039 16.6514Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.0175 8.14951C28.2491 9.0862 28.2491 10.9138 27.0175 11.8505C23.2957 14.6813 19.1398 16.9125 14.7054 18.4606L13.8953 18.7434C12.3454 19.2845 10.7083 18.2538 10.4985 16.6811C9.91226 12.2872 9.91226 7.71284 10.4985 3.31888C10.7083 1.74624 12.3454 0.715475 13.8953 1.25656L14.7054 1.53939C19.1398 3.08753 23.2957 5.31875 27.0175 8.14951ZM25.8778 10.4026C26.1458 10.1988 26.1458 9.80119 25.8778 9.5974C22.3121 6.88536 18.3305 4.74773 14.0821 3.26452L13.2719 2.9817C12.8685 2.84084 12.4048 3.10929 12.3451 3.55693C11.78 7.79287 11.7799 12.2071 12.3451 16.4431C12.4048 16.8907 12.8685 17.1592 13.2719 17.0183L14.0821 16.7355C18.3305 15.2523 22.3121 13.1146 25.8778 10.4026Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default LiveCasino;
