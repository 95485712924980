import { COOKIE_DOMAIN_LOCAL, COOKIE_DOMAIN_STAGING, COOKIE_DOMAIN_PRODUCTION } from '../variables';
import { setCookie } from '@tlf-e/brand-utils';

export const setDomainCookie = (name, value, options = {}) => {
    const domain = getCookieDomain();

    options = {
        domain,
        ...options
    };

    setCookie(name, value, options);
};

export const clearDomainCookie = (name) => {
    setDomainCookie(name, '', {
        'max-age': -1
    });
};

const getCookieDomain = () => {
    const hostname = window.location.hostname;

    if (hostname.includes(COOKIE_DOMAIN_STAGING)) {
        return COOKIE_DOMAIN_STAGING;
    } else if (hostname.includes(COOKIE_DOMAIN_PRODUCTION)) {
        return COOKIE_DOMAIN_PRODUCTION;
    } else {
        return COOKIE_DOMAIN_LOCAL;
    }
};
