import { useDispatch, useSelector } from 'react-redux';
import { PLAYNGO_PROVIDER_SLUG, TOMHORNGAMING_PROVIDER_SLUG } from '../../../../../variables';
import PlayandgoGame from '../../../../common/games/game/PlayandgoGame';
import TomHornGame from '../../../../common/games/game/TomHornGame';
import FormLoader from '../../../../common/FormLoader';
import FavoritePanel from './favoritePanel';
import Actions from './Actions';
import cx from 'classnames';
import { withGameLogic } from '../../../../hoc/withGameLogic';
import ErrorGame from './fragment/ErrorGame';
import { useEffect } from 'react';
import { clearGameOverlays } from '../../../../../store/reducers/user';

const GameDesktop = ({
    game,
    provider,
    iframeSrc,
    gameLaunchContent,
    isErrorPage,
    gameLaunchError,
    isGameUrlLoading,
    containerRef,
    playRef,
    dimensions,
    side,
    actionsIndent
}) => {
    const dispatch = useDispatch();
    const { isGameOverlayMinimized } = useSelector((state) => state.global);
    const { gameUrl, isFullscreen } = useSelector((state) => state.games);
    const playRightPadding = `${side / 2}px`;
    const gameName = game?.name;

    useEffect(() => {
        return () => {
            dispatch(clearGameOverlays(null));
        };
    }, []);

    if (isErrorPage || gameLaunchError) {
        return <ErrorGame isErrorPage={isErrorPage} gameName={gameName} />;
    }

    return (
        <div
            id={isGameOverlayMinimized ? 'miniPlay' : 'play'}
            ref={playRef}
            style={{
                padding: isFullscreen || isGameOverlayMinimized ? 0 : `0px ${playRightPadding}`,
                margin: isGameOverlayMinimized && '0px'
            }}
        >
            <div
                className={cx('game-page', { minimized: isGameOverlayMinimized })}
                style={isGameOverlayMinimized ? { width: '100%', height: '100%' } : dimensions}
                ref={containerRef}
            >
                <div className="game-page-bg" />
                {isGameUrlLoading ? (
                    <FormLoader />
                ) : iframeSrc && provider?.slug === PLAYNGO_PROVIDER_SLUG ? (
                    <PlayandgoGame gameLaunchContent={gameLaunchContent} isMobile />
                ) : provider?.slug === TOMHORNGAMING_PROVIDER_SLUG ? (
                    <TomHornGame gameLaunchContent={gameLaunchContent} dimensions={dimensions} />
                ) : (
                    <iframe id={isGameOverlayMinimized ? 'miniGame' : 'game'} className="no-game-infos" src={gameUrl} />
                )}
                {game && !isGameOverlayMinimized && !isGameUrlLoading && (
                    <Actions rightIndent={`-${actionsIndent}px`} game={game} playRef={playRef} />
                )}
                {!isGameOverlayMinimized && <FavoritePanel game={game} dimensions={dimensions} />}
            </div>
        </div>
    );
};

export default withGameLogic(GameDesktop);
