const AlertTriangle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_627_1856)">
            <path
                d="M10.036 3.10575L0.307831 19.3462C0.107259 19.6935 0.00113207 20.0873 9.00702e-06 20.4884C-0.00111406 20.8895 0.102806 21.2839 0.30143 21.6324C0.500054 21.9808 0.786459 22.2712 1.13215 22.4746C1.47784 22.678 1.87077 22.7874 2.27184 22.7918H21.7282C22.1292 22.7874 22.5222 22.678 22.8679 22.4746C23.2135 22.2712 23.4999 21.9808 23.6986 21.6324C23.8972 21.2839 24.0011 20.8895 24 20.4884C23.9989 20.0873 23.8927 19.6935 23.6922 19.3462L13.964 3.10575C13.7593 2.7682 13.471 2.48912 13.1269 2.29544C12.7829 2.10175 12.3948 2 12 2C11.6052 2 11.2171 2.10175 10.8731 2.29544C10.529 2.48912 10.2407 2.7682 10.036 3.10575Z"
                fill="#EB5757"
            />
            <path d="M12 9.00879V13.603" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 18.1973H12.0115" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_627_1856">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default AlertTriangle;
