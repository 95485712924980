import XRegExp from 'xregexp';
import dayjs from 'dayjs';
import { PROD_HOSTNAME } from '../variables';
import router from 'next/router';

export const allowSpecialRegex = new XRegExp(
    '^[a-zA-Z àâäèéêëîïôöœùûüÿçÀÂÄÈÉÊËÎÏÔÖŒÙÛÜŸÇ\']+(?:[\\s-][a-zA-Z àâäèéêëîïôöœùûüÿçÀÂÄÈÉÊËÎÏÔÖŒÙÛÜŸÇ\']+)*$'
);

export const formatSeconds = (seconds) => {
    const duration = dayjs.duration(seconds, 'seconds');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const secondsFormatted = duration.seconds().toString().padStart(2, '0');
    return `${minutes}:${secondsFormatted}`;
};

export const getHostName = (headers) => {
    const ssrHostName = `https://${headers['x-forwarded-host']}`;
    const hostName = headers['x-forwarded-host'] ? ssrHostName : PROD_HOSTNAME;
    return hostName;
};

export const dateIsBetween = (item) => {
    // '[]' means that we include start and end date in period
    return dayjs().isBetween(item?.dateFrom, item?.dateTo, 'day', '[]');
};

export const isCurrentRoute = (route) => {
    return router.pathname === route.slice(0, -1);
};

export const closeModal = (router, modalName) => {
    const { modal, ...restQuery } = router.query || {};
    if (modal === modalName) {
        router.replace(
            {
                pathname: router.pathname,
                query: restQuery
            },
            undefined,
            { shallow: true }
        );
    }
};
