const ReferAFriend = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2540_16572)">
            <path
                d="M8.57034 8.35341C6.37213 8.35341 4.58057 6.47791 4.58057 4.17671C4.58057 1.8755 6.37213 0 8.57034 0C10.7685 0 12.5601 1.8755 12.5601 4.17671C12.5601 6.47791 10.7685 8.35341 8.57034 8.35341ZM8.57034 1.92771C7.36573 1.92771 6.422 2.91566 6.422 4.17671C6.422 5.43775 7.36573 6.4257 8.57034 6.4257C9.77494 6.4257 10.7187 5.43775 10.7187 4.17671C10.7187 2.91566 9.77494 1.92771 8.57034 1.92771Z"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                d="M1.62667 20C1.16631 20 0.725135 19.7952 0.418229 19.4378C0.107488 19.0723 -0.0498012 18.5823 0.0154162 18.1486C0.502628 13.5663 4.16247 10.1205 8.53588 10.1205H8.68933C13.0627 10.1205 16.7264 13.5663 17.206 18.1406C17.2405 18.6426 17.1024 19.0924 16.8031 19.4378C16.4195 19.7992 15.9707 20 15.518 20H1.62667ZM2.3479 17.9639C2.39777 18.0361 2.4745 18.0683 2.58575 18.0683H14.5551C14.8927 18.0683 15.1689 17.7791 15.1689 17.4257V17.2731L15.1612 17.241C14.3824 14.1807 11.7239 12.0402 8.69316 12.0402H8.52437C5.50519 12.0402 2.84662 14.1767 2.05634 17.241C1.99879 17.5341 2.12923 17.8273 2.3479 17.9598V17.9639Z"
                fill="white"
                fillOpacity="0.5"
            />
            <path
                d="M17.3939 12.3695C16.8683 12.3695 16.4731 11.9558 16.4731 11.4056V9.55823H14.7084C14.1829 9.55823 13.7877 9.14458 13.7877 8.59437C13.7877 8.04417 14.1829 7.63052 14.7084 7.63052H16.4731V5.78313C16.4731 5.23293 16.8683 4.81927 17.3939 4.81927C17.9194 4.81927 18.3146 5.23293 18.3146 5.78313V7.63052H20.0793C20.6049 7.63052 21 8.04417 21 8.59437C21 9.14458 20.6049 9.55823 20.0793 9.55823H18.3146V11.4056C18.3146 11.9558 17.9194 12.3695 17.3939 12.3695Z"
                fill="white"
                fillOpacity="0.5"
            />
        </g>
        <defs>
            <clipPath id="clip0_2540_16572">
                <rect width="21" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ReferAFriend;
