const Shrink = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <circle cx="16" cy="16" r="15.5" stroke="white" />
        <path
            d="M9.22121 21.7532L12.4163 18.5021H9.9423C9.50386 18.5021 9.15937 18.1717 9.15937 17.7511C9.15937 17.3305 9.50386 17 9.9423 17H14.2327C14.2327 17 14.311 17.015 14.358 17.03C14.4206 17.03 14.4676 17.03 14.5146 17.0601C14.5772 17.0901 14.6399 17.1202 14.6868 17.1652C14.7181 17.1803 14.7495 17.1953 14.7651 17.2103C14.8121 17.2554 14.8591 17.3305 14.8904 17.3906C14.8904 17.4056 14.9217 17.4356 14.9374 17.4506C14.9374 17.4807 14.9374 17.5258 14.9687 17.5558C14.9843 17.6159 15 17.676 15 17.7361V21.8519C15 22.2725 14.6555 22.603 14.2171 22.603C13.7786 22.603 13.4341 22.2725 13.4341 21.8519V19.6137L10.333 22.7597C10.1764 22.9099 9.97282 23 9.76926 23C9.58136 23 9.3778 22.9249 9.23687 22.7897C8.9237 22.5043 8.9237 22.0536 9.22121 21.7532Z"
            fill="white"
        />
        <path
            d="M22.7788 10.2468L19.5837 13.4979H22.0577C22.4961 13.4979 22.8406 13.8283 22.8406 14.2489C22.8406 14.6695 22.4961 15 22.0577 15H17.7673C17.7673 15 17.689 14.985 17.642 14.97C17.5794 14.97 17.5324 14.97 17.4854 14.9399C17.4228 14.9099 17.3601 14.8798 17.3132 14.8348C17.2819 14.8197 17.2505 14.8047 17.2349 14.7897C17.1879 14.7446 17.1409 14.6695 17.1096 14.6094C17.1096 14.5944 17.0783 14.5644 17.0626 14.5494C17.0626 14.5193 17.0626 14.4742 17.0313 14.4442C17.0157 14.3841 17 14.324 17 14.2639V10.1481C17 9.72747 17.3445 9.397 17.7829 9.397C18.2214 9.397 18.5659 9.72747 18.5659 10.1481V12.3863L21.667 9.24034C21.8236 9.09013 22.0272 9 22.2307 9C22.4186 9 22.6222 9.07511 22.7631 9.2103C23.0763 9.49571 23.0763 9.94635 22.7788 10.2468Z"
            fill="white"
        />
    </svg>
);

export default Shrink;
