import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { allowSpecialRegex } from '../../../../utils/commonConfig';
import { withRegisterHelper } from '../../../hoc/withRegisterHelper';
import {
    cleanupSubmitRegistration,
    combineRegistrationForm,
    initFinishPhoneVerification,
    setActiveStep
} from '../../../../store/reducers/register';
import RegistrationFromTitle from '../../../common/register-new/fragments/RegistrationFromTitle';
import FormErrorMessage from '../../../common/FormErrorMessage';
import Autocomplete from 'react-google-autocomplete';
import cx from 'classnames';
import * as yup from 'yup';
import { dataLayerPush, dataLayerPushField } from '@tlf-e/brand-utils';

const RegisterStep3New = ({ onChangeAddress, googlePlacesApiKey, validateZipCode, defaultCountry }) => {
    const dispatch = useDispatch();
    const tr = useSelector((state) => state.global.data.translations);
    const { form, totalSteps } = useSelector((state) => state.register);

    useEffect(() => {
        dataLayerPush({ event: 'signup_step3' });
        dispatch(initFinishPhoneVerification());
        return () => {
            dispatch(cleanupSubmitRegistration());
            // Force removing Google locations menu from the body
            const pacContainer = document.querySelector('.pac-container.pac-logo');
            pacContainer && pacContainer.remove();
        };
    }, []);

    const validationSchema = yup.object({
        address: yup
            .string()
            .required(tr['register.form.address.error.required'])
            .min(5, tr['register.form.address.error.wrong']),
        city: yup
            .string()
            .matches(allowSpecialRegex, tr['register.form.city.error.wrong'])
            .required(tr['register.form.city.error.required'])
            .min(2, tr['register.form.city.error.wrong']),
        termsConditions: yup.boolean().oneOf([true], tr['register.terms_accept.error.required'])
    });

    const onSubmit = (values) => {
        dispatch(combineRegistrationForm({ ...values }));
        dispatch(setActiveStep(4));
    };

    return (
        <Formik
            initialValues={{
                address: form.address,
                zipCode: form.zipCode,
                city: form.city,
                country: form.country || defaultCountry?.code
            }}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
        >
            {({ values, touched, errors, setFieldValue }) => (
                <Fragment>
                    <RegistrationFromTitle currentStep={3} totalSteps={totalSteps} />
                    <Form id="register_form_new">
                        <div className="form-group form-group--row">
                            <div className="form-group--cell">
                                <label className="form-group--title form-group-title">{tr['register.address']}</label>
                                <div
                                    className={cx({
                                        'form-group--error-marked': touched.address && errors.address
                                    })}
                                >
                                    <Field name="address">
                                        {({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                apiKey={googlePlacesApiKey}
                                                className="text-field"
                                                type="text"
                                                name="address"
                                                data-testid="register_address"
                                                placeholder=""
                                                options={{
                                                    types: ['address'],
                                                    componentRestrictions: {
                                                        country: values.country
                                                    }
                                                }}
                                                id="register_address"
                                                onPlaceSelected={(place) => {
                                                    onChangeAddress(place, setFieldValue);
                                                }}
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'address'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="address" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-group--row">
                            <div className="form-group--cell">
                                <label className="form-group--title form-group-title">{tr['register.form.zip_code']}</label>
                                <div
                                    className={cx({
                                        'form-group--cell-error-marked': touched.zipCode && errors.zipCode
                                    })}
                                >
                                    <Field name="zipCode" validate={(value) => validateZipCode(value, values.country)}>
                                        {({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                id="register_zip_code"
                                                data-testid="register_zip_code"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onBlur={(e) => {
                                                    const input = e.target.value;
                                                    input &&
                                                        dataLayerPushField({
                                                            event: 'signup_filled_field',
                                                            field: 'postcode',
                                                            input: input,
                                                            valid: !errors.zipCode,
                                                            error: errors.zipCode
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="zipCode" />
                                </div>
                            </div>
                            <div className="form-group--cell">
                                <label className="form-group--title form-group-title" htmlFor="register_city">
                                    {tr['register.form.city']}
                                </label>
                                <div
                                    className={cx({
                                        'form-group--cell-error-marked': touched.city && errors.city
                                    })}
                                >
                                    <Field name="city">
                                        {({ field }) => (
                                            <input
                                                {...field}
                                                type="text"
                                                id="register_city"
                                                data-testid="register_city"
                                                autoComplete="off"
                                                autoCapitalize="off"
                                                className="text-field"
                                                onBlur={(e) => {
                                                    e.target.value &&
                                                        dataLayerPush({
                                                            event: 'signup_filled_field',
                                                            field: 'city'
                                                        });
                                                }}
                                            />
                                        )}
                                    </Field>
                                    <FormErrorMessage name="city" />
                                </div>
                            </div>
                        </div>
                        <div className="form--btns">
                            <button
                                type="button"
                                className="btn btn-cancel btn-modern"
                                data-testid="register_step_3_back"
                                onClick={() => {
                                    dispatch(setActiveStep(2));
                                    dispatch(combineRegistrationForm({ ...values }));
                                }}
                            >
                                {tr['go.back']}
                            </button>
                            <button type="submit" data-testid="submit_form" className="btn btn-submit btn-modern">
                                {tr['register.continue']}
                            </button>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};

export default withRegisterHelper(RegisterStep3New);
