import dayjs from 'dayjs';
import { PROMO_STATUS_EXPIRED, PROMO_STATUS_UPCOMING, PROMO_STATUS_ONGOING, PROMO_KEY_ALL } from '../variables';

export const setPromoStatus = (start, end) => {
    const now = dayjs.utc();
    let isPromoNotStarted = true;
    let isPromoFinished = true;

    if (start) {
        const startDate = dayjs.utc(start);
        const secondsUntilStart = Math.floor(startDate.diff(now) / 1000);
        isPromoNotStarted = secondsUntilStart > 0;

        if (isPromoNotStarted) {
            return {
                timerTimestamp: secondsUntilStart * 1000,
                status: PROMO_STATUS_UPCOMING
            };
        }
    }

    if (end) {
        const endDate = dayjs.utc(end);
        const secondsUntilEnd = Math.floor(endDate.diff(now) / 1000);
        isPromoFinished = secondsUntilEnd <= 0;
        const isPromoOngoing = !isPromoNotStarted && !isPromoFinished;

        if (isPromoOngoing) {
            return {
                timerTimestamp: secondsUntilEnd * 1000,
                status: PROMO_STATUS_ONGOING
            };
        }

        if (isPromoFinished) {
            return {
                timerTimestamp: null,
                status: PROMO_STATUS_EXPIRED
            };
        }
    }

    return {
        timerTimestamp: null,
        status: ''
    };
};

export const sanitizePromoData = (data) => (data !== 'null' ? data : '');

export const getPromotionData = (promotions) => ({
    [PROMO_KEY_ALL]: promotions,
    ...promotions
        .filter((promo) => [PROMO_STATUS_ONGOING, PROMO_STATUS_UPCOMING].includes(promo.status))
        .reduce(
            (acc, promo) => ({
                ...acc,
                [promo.status]: [...acc[promo.status], promo]
            }),
            {
                [PROMO_STATUS_ONGOING]: [],
                [PROMO_STATUS_UPCOMING]: []
            }
        )
});
