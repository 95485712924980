import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useFavoriteGamesList = () => {
    const { favoriteGames, favoriteGamesLoading, data } = useSelector((state) => state.games);
    const setGame = (key, game, acc) => ({
        [key]: game ? [...acc[key], game] : acc[key]
    });

    const favoriteLists = useMemo(
        () =>
            favoriteGames.reduce(
                (acc, gameId) => {
                    const gameLive = data.games.find((game) => game.uniqueTableId === gameId);
                    if (gameLive) {
                        return {
                            ...acc,
                            ...setGame('liveFavorites', gameLive, acc)
                        };
                    }
                    const regularGame = data.games.find((game) => game.id === gameId);
                    if (regularGame) {
                        return {
                            ...acc,
                            ...setGame('favorites', regularGame, acc)
                        };
                    }

                    return acc;
                },
                {
                    liveFavorites: [],
                    favorites: []
                }
            ),
        [favoriteGames, data]
    );

    return {
        ...favoriteLists,
        liveFavoritesIds: favoriteLists['liveFavorites'].map((game) => game.uniqueTableId),
        favoritesIds: favoriteLists['favorites'].map((game) => game.id),
        favoriteGamesLoading
    };
};
