import { Fragment, useEffect } from 'react';
import RegistrationFromTitle from './fragments/RegistrationFromTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/router';
import { getMenuUrl } from '../../../utils/locationConfig';
import { MENU_LOGIN } from '../../../variables';
import Link from 'next/link';
import * as yup from 'yup';
import {
    cleanupSubmitRegistration,
    combineRegistrationForm,
    setActiveStep,
    setIsInteracted
} from '../../../store/reducers/register';
import { withRegisterStep1 } from '../../hoc/withRegisterStep1';
import EmailField from './fragments/EmailField';
import FormErrorMessage from '../FormErrorMessage';
import { useSSRContext } from '../../../hooks/useSSRContext';
import PasswordField from '../password';
import { dataLayerPush } from '@tlf-e/brand-utils';

const RegisterStep1New = ({ onChangeEmail, validateEmail }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { emailFieldVerification, isInteracted, form, totalSteps } = useSelector((state) => state.register);
    const { hostName } = useSSRContext();
    const router = useRouter();
    const dispatch = useDispatch();

    useEffect(() => {
        dataLayerPush({ event: 'signup_step1' });
        return () => {
            dispatch(cleanupSubmitRegistration());
        };
    }, []);

    const initialValues = {
        email: form.email,
        password: form.password,
        termsConditions: form.termsConditions
    };

    const validationSchema = yup.object({
        password: yup
            .string()
            .required(tr['register.form.password.error.required'])
            .min(8, tr['register.form.password.error.hint'])
            .max(255, tr['register.form.password.error.hint'])
            .matches(
                /[a-zA-ZÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]/g,
                tr['register.form.password.error.hint']
            )
            .matches(/\d/g, tr['register.form.password.error.hint'])
            .matches(
                /[!"#$%&()*+,-./:;<=>?@[\]^_`{|}~¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿]/g,
                tr['register.form.password.error.hint']
            )
            .matches(/^((?!\s).)*$/, tr['register.form.password.error.hint'])
            .notOneOf([yup.ref('email')], tr['register.password.sameemail']),
        termsConditions: yup.boolean().oneOf([true], tr['register.terms_accept.error.required'])
    });

    const handleSuccess = (values) => {
        dispatch(combineRegistrationForm({ ...values }));
        dispatch(setActiveStep(2));
    };

    const onSubmit = (values) => {
        !emailFieldVerification.isSuccess && validateEmail(values.email);
        emailFieldVerification.isSuccess && handleSuccess(values);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {() => (
                <Fragment>
                    <RegistrationFromTitle currentStep={1} totalSteps={totalSteps} />
                    <Form
                        id="register_form_new"
                        onChange={() => {
                            if (!isInteracted) {
                                dispatch(setIsInteracted(true));
                                dataLayerPush({ event: 'signup_start' });
                            }
                        }}
                    >
                        <EmailField onChangeEmail={onChangeEmail} validateEmail={validateEmail} />
                        <PasswordField
                            fieldName="password"
                            title={tr['register.form.password']}
                            testId="register_password"
                            dataLayerEvent="signup_filled_field"
                        />
                        <div className="form-group">
                            <Field name="termsConditions">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="checkbox"
                                        data-testid="register_terms"
                                        id="register_terms"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            e.target.checked && dataLayerPush({ event: 'signup_terms_age_privacy' });
                                        }}
                                    />
                                )}
                            </Field>
                            <label
                                id="register_terms"
                                htmlFor="register_terms"
                                dangerouslySetInnerHTML={{
                                    __html: tr['register.terms']
                                        .replace(
                                            '##TERMS_LINK##',
                                            // eslint-disable-next-line max-len
                                            `<a href='${hostName}/${router.locale}/terms' target='_blank'>${tr['footer.nav.terms']}</a>`
                                        )
                                        .replace(
                                            '##PRIVACY_LINK##',
                                            // eslint-disable-next-line max-len
                                            `<a href='${hostName}/${router.locale}/privacy-policy' target='_blank'>${tr['footer.nav.privacy_policy']}</a>`
                                        )
                                }}
                            />
                            <FormErrorMessage name="termsConditions" />
                        </div>
                        <div className="form-group">
                            <Field name="marketingAllowed">
                                {({ field }) => (
                                    <input
                                        {...field}
                                        type="checkbox"
                                        id="marketing_allowed"
                                        data-testid="marketing_allowed"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            e.target.checked && dataLayerPush({ event: 'signup_markertingsub' });
                                        }}
                                    />
                                )}
                            </Field>
                            <label
                                htmlFor="marketing_allowed"
                                dangerouslySetInnerHTML={{ __html: tr['register.commercial'] }}
                            />
                        </div>
                        <div className="form--btns">
                            <Link {...getMenuUrl(MENU_LOGIN, router)}>
                                <button type="button" className="btn btn-cancel btn-modern">
                                    {tr['header.login']}
                                </button>
                            </Link>
                            <button type="submit" className="btn btn-submit btn-modern" data-testid="register_step_1">
                                {tr['register.continue']}
                            </button>
                        </div>
                    </Form>
                </Fragment>
            )}
        </Formik>
    );
};

export default withRegisterStep1(RegisterStep1New);
