const Minimized = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
            d="M9.29 0H2.71C1.21 0 0 1.21 0 2.71V9.29C0 10.79 1.21 12 2.71 12H4.42C4.92 12 5.32 11.6 5.32 11.1C5.32 10.6 4.92 10.2 4.42 10.2H2.71C2.21 10.2 1.81 9.8 1.81 9.3V2.71C1.81 2.21 2.21 1.81 2.71 1.81H9.29C9.79 1.81 10.19 2.21 10.19 2.71V5.55C10.19 6.05 10.59 6.45 11.09 6.45C11.59 6.45 11.99 6.05 11.99 5.55V2.71C11.99 1.21 10.78 0 9.28 0H9.29Z"
            fill="#301600"
        />
        <path
            d="M14 9.71C14 8.46 12.99 7.45 11.74 7.45H8.58C7.33 7.45 6.32 8.46 6.32 9.71V11.74C6.32 12.99 7.33 14 8.58 14H11.74C12.99 14 14 12.99 14 11.74V9.71ZM12.19 11.74C12.19 11.99 11.99 12.19 11.74 12.19H8.58C8.33 12.19 8.13 11.99 8.13 11.74V9.71C8.13 9.46 8.33 9.26 8.58 9.26H11.74C11.99 9.26 12.19 9.46 12.19 9.71V11.74Z"
            fill="#301600"
        />
    </svg>
);

export default Minimized;
