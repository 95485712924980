import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAffiliateDataValue } from '@tlf-e/brand-utils';
import {
    checkRegistrationIsAllowed,
    initForm,
    initRegisterUser,
    processEmailVerification,
    removeRegistrationAllowedLoaded,
    setIsInteracted,
    setOtpRetryTimeout
} from '../../../store/reducers/register';
import RegisterStep1New from './RegisterStep1New';
import RegisterStep2New from './steps/RegisterStep2New';
import RegisterStep3New from './steps/RegisterStep3New';
import RegisterStep4New from './steps/RegisterStep4New';
import RegistrationErrorView from './fragments/RegistrationErrorView';
import PageLoader from '../PageLoader';
import { includeRouterContext } from '../../../utils/locationConfig';

const forms = {
    1: <RegisterStep1New />,
    2: <RegisterStep2New />,
    3: <RegisterStep3New />,
    4: <RegisterStep4New />
};

const RegisterNew = () => {
    const tr = useSelector((state) => state.global.data.translations);
    const loc = useSelector((state) => state.global.clientIp.loc);
    const { generalRegistrationError } = useSelector((state) => state.register);
    const { activeStep } = useSelector((state) => state.register);
    const { isRegistrationAllowedLoaded, isRegistrationAllowed } = useSelector((state) => state.register);
    const dispatch = useDispatch();

    useEffect(() => {
        const affiliateId = includeRouterContext(getAffiliateDataValue, 'aid');
        const referralToken = includeRouterContext(getAffiliateDataValue, 'referralToken');
        dispatch(checkRegistrationIsAllowed(affiliateId, loc, referralToken));

        return () => {
            dispatch(initForm());
            dispatch(initRegisterUser());
            dispatch(setOtpRetryTimeout(''));
            dispatch(setIsInteracted(false));
            dispatch(removeRegistrationAllowedLoaded());
            dispatch(
                processEmailVerification({
                    isLoading: false,
                    isSuccess: false,
                    error: '',
                    value: ''
                })
            );
        };
    }, []);

    return (
        <div className="form-container">
            {isRegistrationAllowedLoaded ? (
                generalRegistrationError ? (
                    <RegistrationErrorView
                        title={generalRegistrationError.title}
                        details={generalRegistrationError.details}
                    />
                ) : isRegistrationAllowed ? (
                    forms[activeStep]
                ) : (
                    <RegistrationErrorView
                        title={tr['not_logged.title']}
                        details={tr['blocked_registration_attempted_wrong_geo_loc']}
                        queries={tr['blocked_registration_queries']}
                    />
                )
            ) : (
                <div className="page-loader-wrapper">
                    <PageLoader />
                </div>
            )}
        </div>
    );
};

export default RegisterNew;
