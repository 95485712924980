import cx from 'classnames';

const FormLoader = ({ extraClass = '' }) => {
    return (
        <div
            data-testid="form_loader"
            className={cx('form-loader', {
                [extraClass]: extraClass
            })}
        >
            <div className="top">
                <div className="animation" />
            </div>
        </div>
    );
};

export default FormLoader;
