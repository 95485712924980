export const formatCurrency = (amount = 0, currency, limit = 2) => {
    const formattedAmount = parseFloat(String(amount).replace(/,/g, '')).toFixed(limit).toLocaleString();

    switch (currency) {
    case 'EUR':
        return `${formattedAmount} €`;
    case 'CAD':
    case 'USD':
        return `$${formattedAmount}`;
    case 'INR':
        return `₹${formattedAmount}`;
    case null:
        return formattedAmount;
    default:
        return `${currency} ${formattedAmount}`;
    }
};

export const formatCurrencySymbol = (currency) => {
    switch (currency) {
    case 'EUR':
        return '€';
    case 'CAD':
    case 'USD':
        return '$';
    case 'INR':
        return '₹';
    default:
        return currency;
    }
};

export const formatBannerCurrency = (amount = 0, currency, limit = 0) => {
    const formattedAmount = parseFloat(String(amount).replace(/,/g, '')).toFixed(limit).toLocaleString();

    switch (currency) {
    case 'CAD':
    case 'USD':
        return `$${formattedAmount}`;
    case 'CHF':
        return `${currency} ${formattedAmount}`;
    default:
        return `${formattedAmount}€`;
    }
};
