import { resetFavoriteGameMethod, resetNewFavoriteGame } from '../../../../../../../store/reducers/games';
import { useDispatch, useSelector } from 'react-redux';
import { ADD, DELETE, REMOVE } from '../../../../../../../variables';
import { useEffect } from 'react';
import NotificationTimer from '../../../../../../common/NotificationTimer';
import Notification from '../../../../../../ui/Notification';

const FavoritePanelNotification = ({ status, isOpen, onClose, picto }) => {
    const tr = useSelector((state) => state.global.data.translations);
    const { newFavoriteGame, favoriteGameMethod } = useSelector((state) => state.games);
    const dispatch = useDispatch();
    const actionType = favoriteGameMethod === DELETE ? REMOVE : ADD;
    const message = `${newFavoriteGame.name} ${tr[`favorite.modal.${actionType}.favorite.notification`]}`;

    useEffect(() => {
        return () => {
            dispatch(resetFavoriteGameMethod());
            dispatch(resetNewFavoriteGame());
        };
    }, []);

    return (
        <Notification isOpen={isOpen} onClose={onClose} status={status} rootClass="transaction-snackbar" showTimer>
            {({ autoHideCountdown, onClose }) => (
                <NotificationTimer autoHideCountdown={autoHideCountdown} onClose={onClose}>
                    {() => (
                        <div className="notification-content notification-content--timer">
                            <div>
                                {picto}
                                <span>
                                    <strong>{message}</strong>
                                </span>
                            </div>
                        </div>
                    )}
                </NotificationTimer>
            )}
        </Notification>
    );
};

export default FavoritePanelNotification;
