import { getGameDimensions, isDefaultScreenMode } from '@tlf-e/brand-utils';
import { gameImpressions } from '../../utils/gameImpressions';
import { setIsFullscreen } from '../../store/reducers/games';
import { useDispatch, useSelector } from 'react-redux';
import { GAME_PLAY } from '../../variables';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const side = 150;
const actionsIndent = 44;

export const withGameLogic = (PageComponent) => {
    const WithGameLogic = ({ game, containerRef, playRef, addGameIndent, ...props }) => {
        const router = useRouter();
        const dispatch = useDispatch();
        const { data: gamesData, isFullscreen } = useSelector((state) => state.games);
        const { desktopHeaderHeight } = useSelector((state) => state.global);
        const isAuth = useSelector((state) => state.user.isAuth);
        const [offsetLeft, setOffsetLeft] = useState(0);
        const [topHeight, setTopHeight] = useState(0);
        const [windowDimensions, setWindowDimensions] = useState({
            windowWidth: 0,
            windowHeight: 0
        });
        const sidePaddings = side;
        const { softwares, categories } = gamesData;
        const tableHash = game?.tableHash || null;
        const dimensions = getGameDimensions(
            {
                ...windowDimensions,
                topHeight,
                sidePaddings
            },
            game?.ratio,
            isFullscreen
        );
        const displayedGame = [{ ...game, tableHash }];
        const calcAddGameIndent = game?.provider === 'evolution' ? desktopHeaderHeight + addGameIndent : addGameIndent;

        useEffect(() => {
            setWindowDimensions(getWindowDimensions());
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, [desktopHeaderHeight]);

        useEffect(() => {
            setTopHeight(playRef?.current?.offsetTop + calcAddGameIndent);
        }, [desktopHeaderHeight]);

        useEffect(() => {
            setLeft(containerRef?.current?.clientWidth, containerRef?.current?.offsetLeft);
        }, [containerRef?.current?.clientWidth, containerRef?.current?.offsetLeft, topHeight, dimensions]);

        useEffect(() => {
            dispatch(setIsFullscreen(!isDefaultScreenMode()));
        }, [windowDimensions]);

        useEffect(() => {
            displayedGame.length > 0 &&
                gameImpressions({
                    eventName: GAME_PLAY,
                    displayedGames: displayedGame,
                    gamesData,
                    categories,
                    softwares,
                    isAuth,
                    router
                });
        }, [game]);

        const handleResize = () => setWindowDimensions(getWindowDimensions());

        const getWindowDimensions = () => {
            const { innerHeight: height } = window;
            const windowWidth = document.getElementById('content')?.offsetWidth;
            setTopHeight(playRef?.current?.offsetTop + calcAddGameIndent);
            const windowHeight = height - desktopHeaderHeight;
            return {
                windowWidth,
                windowHeight
            };
        };

        const setLeft = (gameWidth, leftIndent) => {
            gameWidth > 0 && setOffsetLeft(leftIndent);
        };

        return (
            <PageComponent
                {...props}
                topHeight={topHeight}
                offsetLeft={offsetLeft}
                containerRef={containerRef}
                playRef={playRef}
                addGameIndent={calcAddGameIndent}
                dimensions={dimensions}
                game={game}
                sidePaddings={sidePaddings}
                windowDimensions={windowDimensions}
                side={side}
                actionsIndent={actionsIndent}
            />
        );
    };

    return WithGameLogic;
};
